import react from 'react';
import { Button, HStack, VStack, Box, Text, Pressable } from "native-base";
import Menu from "./components/menu";
import { useTranslation } from "react-i18next";

const TrackYourOrder=()=>
{
  const { t } = useTranslation();

    return(
        <Box width="100%">
      <HStack width="100%" mt="5">
        <VStack width="20%" m="6" space="3">
         <Menu title="profile"/>   
        </VStack> 
          <VStack mt="20" width="90%" alignItems={"left"} space="3">
            <Text fontSize="lg" fontWeight={"bold"}>
                {t("track_your_order")}
            </Text>
            <Text>Hello User!...</Text>
          </VStack>
      </HStack>
    </Box>
    );
};

export default TrackYourOrder;