import {HStack, VStack, Box } from "native-base";
import react, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { TbAddressBook } from "react-icons/tb";
import { VscAccount } from "react-icons/vsc";
import MenuItem from "./menu_item";
const Menu = (props) => {
  const { t } = useTranslation();
  const {title}=props;
  const [selected_menu,setSelectedMenu] = useState(title);
  const handleMenuSelect=(value)=>{
    setSelectedMenu(value);
  }
  return (
    <Box width="100%">
      <HStack width="100%" mt="5" >
        <VStack  m="6" space="3">
          <MenuItem index={"orders"} title={t("orders")} image={<AiOutlineShoppingCart size={20}/>} selected_menu={selected_menu} handleMenuSelect={handleMenuSelect}/>
          <MenuItem index={"addresses"} title={t("my_address")} image={<TbAddressBook size={20}/>}selected_menu={selected_menu} handleMenuSelect={handleMenuSelect} />
          <MenuItem index={"account-details"} title={t("account_details")} image={<VscAccount size={20}/>} selected_menu={selected_menu} handleMenuSelect={handleMenuSelect}/>
        </VStack>  
      </HStack>
    </Box>
  );
};
export default Menu;
