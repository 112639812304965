import { gql } from "@apollo/client";
export const get_all_customer_orders_query = gql`
  query getAllCustomerOrders(
    $page_number: Float
    $page_limit: Float
  ) {
    get_all_customer_orders: getAllCustomerOrders(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        customer_id
        address_id
        t_amount
        order_status
        customer_order_number
        order_code
        created_at
        updated_at
        ordered_date_time
        is_deleted
        customer_order_line_items {
          id
          customer_order_id
          vendor_id
          vendor_product_variant_id
          vendor_product_variant {
            name
            icon
            base_price
            original_price
            selling_price
            id
          }
          quantity
          total_amount
          amount
          order_code
          created_at
          updated_at
          ordered_date_time
          delivered_date_time
          is_deleted
          customer_order_line_item_number
          status
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const get_customer_query = gql`
  query getCustomer($id: String!) {
    get_customer: getCustomer(id: $id) {
      id
      name
      system_user {
        email
        mobile
        user_name
      }
    }
  }
`;

export const update_change_password_mutation = gql`
mutation updateChangePassword($current_password: String!, $new_password: String!){
  update_change_password: updateChangePassword(current_password: $current_password, new_password: $new_password){
    status
    user_id
    name
    token
    error{
      status_code
      message
    }
  }
}
`;
