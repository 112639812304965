import { navigate, navigateBack } from "@helpers/navigator";
import { storeItem } from "@helpers/storage";
import {
  dynamicClear,
  dynamicRequest,
  updateChangePassword,
  useDynamicSelector,
} from "@services/redux";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import { ROUTES } from "@views/routes/my_routes";
import { Input, Button } from "antd";
import { Box, Card, IconButton, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { AiFillEye, AiOutlineArrowLeft, AiOutlineEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
const UserChangePassword = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [new_password, setNewPassword] = useState("");
  const [confirm_new_password, setConfirmNewPassword] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [showPass, setShowPass] = React.useState(false);

  const { user_id, name, token, profile_id, gender } = useDynamicSelector(
    "update_change_password"
  );
  const updateChangePass = () => {
    let update_change_password_otp_key = [
      { key: "update_change_password", loading: true },
    ];
    let update_change_password_otp_query = updateChangePassword;
    let update_change_password_otp_variables = {
      current_password: old_password,
      new_password: new_password,
    };
    dispatch(
      dynamicRequest(
        update_change_password_otp_key,
        update_change_password_otp_query,
        update_change_password_otp_variables,
        "M"
      )
    );
  };

  useEffect(() => {
    if (token) storeItem("token", token);
    if (name) storeItem("name", name);
    if (profile_id) storeItem("profile_id", profile_id);
    if (user_id) storeItem("user_id", user_id);
    if (gender) storeItem("gender", gender);

    if (token) {
      navigate(ROUTES.USER_HOME);
    }
  }, [token, name, profile_id, user_id, gender]);

  useEffect(() => {
    if (user_id) {
      toast.success(t("password_changed_successfully"));
      dispatch(dynamicClear("update_change_password"));
    }
  }, [user_id]);
  return (
    <Box bgImage={"linear-gradient(234deg , #F0ECFC, #B6739F)"} flex={1}>
      <Box
        shadow={1}
        height={30}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100vw"}
      >
        <Pressable
          onPress={() => {
            navigateBack();
          }}
          cursor={"pointer"}
          position={"absolute"}
          left={5}
        >
          <AiOutlineArrowLeft size={20} color={"#111"} />
        </Pressable>
        <Text color={"#712e5a"} fontSize={16} bold>
          {t("change_password")}
        </Text>
      </Box>
      <Box justifyContent={"center"} flex="1" alignItems={"center"}>
        <Card backgroundColor={"#fff"} width={"50%"}>
          <VStack space="3">
            <Box mx={5}>
              <VStack>
                <Text pt={2} color={"#64748B"} fontWeight={"600"} fontSize={15}>
                  Old Password
                </Text>

                <Input.Password
                  w={{
                    base: "100%",
                  }}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  placeholder="Old Password"
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                  value={old_password}
                />
                <Text pt={2} color={"#64748B"} fontWeight={"600"} fontSize={15}>
                  New Password
                </Text>

                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  placeholder="New Password"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  value={new_password}
                />
                <Text pt={2} color={"#64748B"} fontWeight={"600"} fontSize={15}>
                  Confirm New Password
                </Text>

                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  placeholder="Confirm New Password"
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                  }}
                  value={confirm_new_password}
                />
              </VStack>
            </Box>

            <Button
              type="primary"
              loading={false}
              onClick={() => {
                if (new_password === confirm_new_password) {
                  updateChangePass();
                } else {
                  toast.error("Password doesn't match");
                }
              }}
            >
              {t("change_password")}
            </Button>
          </VStack>
        </Card>
      </Box>
    </Box>
  );
};
export default UserChangePassword;
