import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  ScrollView,
} from "native-base";
import logo from "@assets/images/governmentlogo.png";

import SM from "@assets/icons/hands.png";
import Entypo from "react-native-vector-icons/dist/Entypo";
import { Input, Button } from "antd";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  customerLogin,
} from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { clearLocalStorage, retrieveItem, storeItem } from "@helpers/storage";

const LoginForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { showForgotPasswordForm, showLoginForm } = props;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = React.useState(false);

  const {
    status,
    error,
    name,
    token,
    user_id,
    loading: loginLoading,
  } = useDynamicSelector("user_login");

  useEffect(() => {
    if (status === "success") {
      if (error) {
        toast.error(error.message);
        dispatch(dynamicClear("user_login"));
      } else {
        storeItem("token", token);
        storeItem("user_id", user_id);
        if (token) {
          // history?.push({pathname:`${ROUTES.USER_HOME}/${user_id}`,state:{user_id:user_id}});
          history.push(ROUTES.USER_HOME);
          toast.success(`Welcome ${name}`);
        }
      }
      dispatch(dynamicClear("user_login"));
    } else {
      if (error) {
        toast.error(error.message);
        dispatch(dynamicClear("user_login"));
      }
    }
  }, [status]);

  const Login = () => {
    let user_login_key = [{ key: "user_login", loading: true }];
    let user_login_query = customerLogin;
    let user_login_variables = { username: userName, password: password };
    dispatch(
      dynamicRequest(user_login_key, user_login_query, user_login_variables)
    );
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      Login();
    }
  };
  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <Center>
        <Stack
          my={2}
          // alignItems={"center"}
          // justifyContent={"center"}
          flexDirection={{
            base: "column",
            md: "row",
          }}
          // p="50px"
          w={{
            md: "100%",
            sm: "50%",
          }}
          maxW={{
            md: "100%",
            sm: "50%",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <VStack
            flex="1"
            px="1"
            py="6"
            _light={{
              bg: "white",
            }}
            _dark={{
              bg: "coolGray.800",
            }}
            position={"relative"}
            space="1"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            width="250px"
            borderRadius={{
              base: "2xl",
              md: "xl",
            }}
          >
            <Image
              cursor={"pointer"}
              height={20}
              alignContent={"center"}
              alt="logo"
              resizeMode={"contain"}
              source={logo}
            />
            <Text
              variant="LogoGap"
              color={"#712e5a"}
              bold
              fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
              textAlign={"center"}
              fontWeight="normal"
            >
              {t("user_login")}
            </Text>

            <VStack>
              <VStack space="3">
                <Box mx={5}>
                  {/* <Box alignItems={"center"}> */}
                  <VStack>
                    <Text
                      pt={2}
                      color={"#464459"}
                      fontWeight={"600"}
                      fontSize={15}
                    >
                      Mobile Number/User Name
                    </Text>

                    <Input
                      // variant="rounded"
                      placeholder="Mobile Number/User Name"
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      value={userName}
                    />
                    {/* </VStack>
                  </Box>
                  <Box alignItems={"center"}>
                    <VStack width={"50%"}> */}
                    <Text
                      pt={2}
                      color={"#464459"}
                      fontWeight={"600"}
                      fontSize={15}
                    >
                      Password
                    </Text>

                    <Input.Password
                      w={{
                        base: "100%",
                        // xs: "100%",
                        // sm: "100%",
                        // md: "100%",
                        // lg: "100%",
                        // xl: "50%",
                      }}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      // type={showPass ? "" : "password"}
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                      onPressEnter={handleKeypress}
                      // InputRightElement={
                      //   <IconButton
                      //     variant="unstyled"
                      //     icon={showPass ? <AiOutlineEye /> : <AiFillEye />}
                      //     onPress={() => {
                      //       {
                      //         !showPass ? setShowPass(true) : setShowPass(false);
                      //       }
                      //     }}
                      //   />
                      // }
                    />
                  </VStack>
                  {/* </Box> */}
                </Box>
                <Link
                  ml="auto"
                  _text={{
                    fontSize: "xs",
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                  _light={{
                    _text: {
                      color: "#712e5a",
                    },
                  }}
                  _dark={{
                    _text: {
                      color: "#712e5a",
                    },
                  }}
                  onPress={() => {
                    showForgotPasswordForm(true);
                    showLoginForm(false);
                    dispatch(dynamicClear("request_forgot_password_otp"));

                    // navigate(ROUTES.FORGOT_PASSWORD);
                  }}
                >
                  Forgot password?
                </Link>
                <Box alignItems={"center"}>
                  <Button
                    style={{ width: "100px" }}
                    //width={10}
                    type="primary"
                    loading={loginLoading}
                    onClick={() => {
                      if (userName.length >= 10 && password.length) {
                        Login();
                      } else if (!userName.length) {
                        toast.error("Please enter valid username");
                      } else if (!password.length) {
                        toast.error("Please enter valid password");
                      }
                    }}
                  >
                    SIGN IN
                  </Button>
                </Box>
                <HStack
                  mb="4"
                  space="1"
                  safeAreaBottom
                  alignItems="center"
                  justifyContent="center"
                  mt={{
                    base: "auto",
                    md: "8",
                  }}
                >
                  <Text
                    _light={{
                      color: "#464459",
                    }}
                    _dark={{
                      color: "#464459",
                    }}
                  >
                    {t("dont_have_acc")}
                  </Text>
                  <Link
                    _text={{
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                    _light={{
                      _text: {
                        color: "#712e5a",
                      },
                    }}
                    _dark={{
                      _text: {
                        color: "#712e5a",
                      },
                    }}
                    onPress={() => {
                      dispatch(dynamicClear("user_login"));
                      clearLocalStorage();
                      navigate(ROUTES.REGISTER);
                      // showForgotPasswordForm(true);
                      // showLoginForm(false);
                    }}
                    /* onPress function naviagteTo:"SignUp" */
                  >
                    {t("sign_up")}
                  </Link>
                </HStack>
              </VStack>
            </VStack>
          </VStack>
        </Stack>
      </Center>
    </ScrollView>
  );
};

export default LoginForm;
