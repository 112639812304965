import { Box, HStack, Pressable, Text } from "native-base";
import React from "react";
import { useHistory } from "react-router-dom";

const MenuItem = (props) => {
  const { index, title, handleMenuSelect, selected_menu, image } = props;
  const history = useHistory();
  const handleRoute = (status) => {
    history?.push({
      pathname: `${index}`,
    });
  };
  return (
    <Pressable
      onPress={() => {
        handleRoute(index);
        handleMenuSelect(index);
      }}
    >
      <Box
        variant="profile_item"
        bgImage={
          selected_menu === index
            ? "linear-gradient(135deg, #5dc56a, #67C373)"
            : ""
        }
      >
        <HStack space="10%">
          <Text color={selected_menu === index ? "white" : "black"}>
            {image}
          </Text>
          <Text
            color={selected_menu === index ? "white" : "black"}
            fontSize={"md"}
            fontWeight="bold"
          >
            {title}
          </Text>
        </HStack>
      </Box>
    </Pressable>
  );
};

export default MenuItem;
