import { Button, Form } from "antd";
import { Box, HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "../ui/antd_form/antd_form";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  get_all_district_query,
  get_all_state_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { get_all_filter_products_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_product_category";
import { get_vendor_categories } from "@services/redux/slices/dynamic_entity/graphql/graphql_vendor_category";
import {
  create_customer_details_mutation,
  create_customer_views_vendor_detail_mutation,
} from "@services/redux/slices/dynamic_entity/graphql/check_customer_detail_query";

const ViewContactDetails = (props) => {
  const { mobile,handleCustomerClose,setIsModalOpen } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [other_district, set_other_district] = useState(false);
  const { items: district_items, loading: district_loading } =
    useDynamicSelector("get_all_district");
  const { items: state_items, loading: state_loading } =
    useDynamicSelector("get_all_state");
    console.log("state_items",state_items);

  const { items: vendor_category, loading: category_loading } =
    useDynamicSelector("vendor_categories");

  const { loading: createLoading } = useDynamicSelector("createCustomerDetail");

  const {
    items: all_filter_products,
    loading: get_all_filter_products_loading,
  } = useDynamicSelector("get_all_filter_products");

  const get_all_districts = (id) => {
    let keys = [{ key: "get_all_district", loading: true }];
    let query = get_all_district_query;
    let variables = { state_id: id };
    dispatch(dynamicRequest(keys,query ,variables));
  };

  const get_all_state = () => {
    let key = [{ key: "get_all_state", loading: true }];
    let query = get_all_state_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_all_state();
  }, []);

  useEffect(() => {
    let keys = [{ key: "vendor_categories", loading: true }];
    dispatch(
      dynamicRequest(keys, get_vendor_categories, {
        vendor_id: "",
        // product_id: product_id,
      })
    );
  }, []);

  const create_customer_detail = (values) => {
    let keys = [{ key: "createCustomerDetail", loading: true }];
    let variables = {
      data: values,
    };
    dispatch(
      dynamicRequest(keys, create_customer_details_mutation, variables, "M")
    );
  };

  const handleSubmit = (values) => {
    create_customer_detail(values);
  };

  const onValueChange = (value, values) => {
    console.log("values",values);
    if (values?.state_id === "c1810e99-25ba-4a00-b0bb-be0c41707824") {
      set_other_district(false);
    } else {
      set_other_district(true);
    }
    get_all_districts(values?.state_id);
    if (value?.category_id) {
      let keys = [
        {
          key: "get_all_filter_products",
          get_all_filter_products_loading: true,
        },
      ];
      dispatch(
        dynamicRequest(keys, get_all_filter_products_query, {
          category_id: value.category_id,
          // vendor_id: filter_vendor_id,
        })
      );
    }
  };

  useEffect(() => {
    if (mobile) {
      form.setFieldsValue({ mobile });
    }
  }, [mobile]);

  const handleBack=()=>{
    handleCustomerClose();
    setIsModalOpen(true);
  }

  return (
    <Box>
      <Text fontSize={"20px"}>Please fill the below details</Text>
      <Form
        name={"view_contact_details"}
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        onValuesChange={onValueChange}
      >
        <VForm.Grid columns={2} spacingX={20}>
          <VForm.TextBox
            label={"Name"}
            field={"name"}
            rules={[
              {
                required: true,
                message: t("Name is required"),
              },
            ]}
          />
          <VForm.TextBox
            label={"Mobile Number"}
            field={"mobile"}
            disabled
            rules={[
              {
                required: true,
                message: t("Mobile is required"),
              },
              {
                pattern: new RegExp("^[6-9][0-9]{9}$"),
                message: t("error:mobile_not_valid"),
              },
            ]}
          />
          <VForm.Select
            label={"State"}
            field={"state_id"}
            options={state_items}
            allowClear
          />
          {other_district === false && (
            <VForm.Select
              label={t("form:district")}
              field={"district_id"}
              options={district_items}
              allowClear
            />
          )}
          {other_district === true && (
            <VForm.TextBox
              label={t("form:district")}
              field={"other_district_name"}
              // rules={[
              //   {
              //     required: false,
              //     message: t("form:enter_base_price"),
              //   },
              // ]}
            />
          )}
          <VForm.Number
            label={t("form:pincode")}
            field={"pincode"}
            // rules={[
            //   {
            //     required: false,
            //     message: t("form:enter_base_price"),
            //   },
            // ]}
          />
        </VForm.Grid>
        <HStack variant="address_close_hstack">
        <Button
          onClick={handleBack}
          type="default"
          danger
        >
          Back
        </Button>
          <VForm.Button
            buttonText={t("Submit")}
            isLoading={createLoading}
            disabled={false}
          ></VForm.Button>
        </HStack>
      </Form>
    </Box>
  );
};

export default ViewContactDetails;
