import React, { useEffect, useState } from "react";
import { VStack, Box, Text, Pressable, Center } from "native-base";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { get_customer_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_orders";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import ProfileLayout from ".";
const AccountDetails = () => {
  const { t } = useTranslation();
  const { name, system_user, loading } = useDynamicSelector("get_customer");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect((value) => {
    let keys = [{ key: "get_customer", loading: true }];
    dispatch(
      dynamicRequest(keys, get_customer_query, {
        id: "df8f42e1-6539-412c-8bd6-43561c1efa61",
      })
    );
  }, []);

  return (
    <ProfileLayout title="account-details">
      <VStack variant="order_list_contents">
        <Text variant="pageHeader">{t("account_details")}</Text>
        {loading ? (
          <Center mt="200px">
            <img
              src="https://v-agriosp-assets.blr1.vultrobjects.com/d839dfd1-0975-492a-b775-1d139002af32_loading.png"
              alt="Loading..."
              width="100px"
            />
          </Center>
        ) : (
          <Box>
            <VStack space={"10px"}>
              <Row justify="start" style={{ marginTop: "12px" }}>
                <Col span={4}>
                  <Text variant="account_details_content">{t("name")}</Text>
                </Col>
                <Col span={1}>
                  <Text variant="account_details_content">:</Text>
                </Col>
                <Col span={15}>
                  <Text variant="account_details_content">{name}</Text>
                </Col>
              </Row>
              <Row justify="start" style={{ marginTop: "10px" }}>
                <Col span={4}>
                  <Text variant="account_details_content">{t("username")}</Text>
                </Col>
                <Col span={1}>
                  <Text variant="account_details_content">:</Text>
                </Col>
                <Col span={15}>
                  <Text variant="account_details_content">
                    {system_user?.user_name}
                  </Text>
                </Col>
              </Row>

              <Row justify="start" style={{ marginTop: "10px" }}>
                <Col span={4}>
                  <Text variant="account_details_content">
                    {t("mobile_number")}
                  </Text>
                </Col>
                <Col span={1}>
                  <Text variant="account_details_content">:</Text>
                </Col>
                <Col span={15}>
                  <Text variant="account_details_content">
                    {system_user?.mobile}
                  </Text>
                </Col>
              </Row>

              <Row justify="start" style={{ marginTop: "10px" }}>
                <Col span={4}>
                  <Text variant="account_details_content">
                    {t("form:email")}
                  </Text>
                </Col>
                <Col span={1}>
                  <Text variant="account_details_content">:</Text>
                </Col>
                <Col span={15}>
                  <Text variant="account_details_content">
                    {system_user?.email}
                  </Text>
                </Col>
              </Row>
              <Row justify="start" style={{ marginTop: "10px" }}>
                <Pressable
                  onPress={() => {
                    history?.push({
                      pathname: ROUTES.CHANGE_PASSWORD,
                      state: {
                        item: { system_user, name },
                      },
                    });
                  }}
                >
                  <Box
                    variant="password_box"
                    bgImage={"linear-gradient(135deg, #5dc56a, #67C373)"}
                  >
                    <Text color="white" fontSize="md">
                      {t("change_password")}
                    </Text>
                  </Box>
                </Pressable>
              </Row>
            </VStack>
          </Box>
        )}
      </VStack>
    </ProfileLayout>
  );
};
export default AccountDetails;
