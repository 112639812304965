import React from "react";

import CartHead from "./cart_head";
import CartBody from "./cart_body";
import { Box } from "native-base";

const CartList = () => {
  return (
    <>
      <Box variant={"cartBoxSize"}>
        <CartHead />
        <CartBody />
      </Box>
    </>
  );
};

export default CartList;
