import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { NativeBaseProvider, extendTheme, theme as nbTheme } from "native-base";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import { store } from "@services/redux/store";
import App from "./App";
import "./i18n";
import { Toaster } from "react-hot-toast";
import { MdRestaurantMenu } from "react-icons/md";
import { marginTop } from "styled-system";
const theme = extendTheme({
  colors: {
    primary: nbTheme.colors.violet,
    primaryContent: nbTheme.colors.warmGray,
    inputBorder: nbTheme.colors.coolGray,
    error: nbTheme.colors.red,
  },
  breakpoints: {
    base: 0,
    xs: 320,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
    "2xl": 2560,
    "3xl": 3840,
  },
  fonts: {
    heading: "Quciksands",
    body: "Quciksands",
  },
  components: {
    Checkbox: {
      variants: {
        terms_checkbox: () => {
          return {
            px: "2",
            value: "one",
          };
        },
      },
    },
    Box: {
      variants: {
        input_header_box: () => {
          return {
            paddingTop: "4",
            paddingBottom: "2",
          };
        },
        terms_box: () => {
          return {
            pt: "10px",
            paddingBottom: "10px",
            flexDirection: "row",
          };
        },
        add_to_cart_input: () => {
          return {
            width: "170px",
          };
        },
        cart_page_image: () => {
          return {
            // borderWidth:1,
            borderRadius: 8,
            borderColor: "gray.200",
            margin: 2,
            padding: 1,
          };
        },
        about_us_image_container: () => {
          return {
            backgroundColor: "black",
          };
        },
        change_password: () => {
          return {
            backgroundColor: "black",
          };
        },
        vendor_card_container: () => {
          return {
            width: "30%",
            justifyContent: "space-between",
          };
        },
        vendor_card_address_box: () => {
          return {
            width: "60%",
            minHeight: "190px",
            paddingLeft: ["0px", "10px", "0px", "0px"],
          };
        },
        vendor_address_box: () => {
          return {
            width: ["30%", "100%", "30%", "30%"],
          };
        },
        header_content_vstack_image_box: () => {
          return {
            ml: "20px",
            p: "10px",
          };
        },
        menu_bar_title_two: () => {
          return {
            width: ["22%", "100%", "22%"],
          };
        },
        user_home_category_box: () => {
          return {
            width: ["100%", , , "70%", "80%", "80%", "80%"],
          };
        },
        user_home_filter_box: () => {
          return {
            width: ["90%", , , "30%", "20%", "20%", "20%"],
            marginLeft: { base: "5", md: "2" },
            marginRight: { base: "5", md: "0" },
          };
        },
        filter_box: () => {
          return {
            width: "100%",
          };
        },
        filter_product_card_box: () => {
          return {
            zIndex: "1",
            width: "80%",
            flex: "wrap",
          };
        },
        product_card_height: () => {
          return {
            color: "#adadad",
          };
        },
        change_password: () => {
          return {
            alignSelf: "center",
            width: "80%",
          };
        },
        order_modal: () => {
          return {
            width: "100%",
          };
        },
        product_details_out_of_stock_box: () => {
          return {
            alignItems: "center",
          };
        },
        order_page_total_box: () => {
          return {
            width: "100%",
            mb: "10%",
            overflow: "hidden",
          };
        },
        order_page_headline_box: () => {
          return {
            position: "sticky",
            top: "0px",
            zIndex: "2",
            bg: "white",
          };
        },
        password_box: () => {
          return {
            minH: "40px",
            shadow: "5",
            borderRadius: "5px",
            alignItems: "center",
            padding: "10px",
            width: "170px",
            color: "white",
          };
        },
        profile_item: () => {
          return {
            minH: "50px",
            shadow: "5",
            borderRadius: "5px",
            alignItems: "left",
            padding: "10px",
            width: "180px",
            color: "white",
          };
        },
        menu_bar_title: () => {
          return {
            _text: {
              width: "100%",
              fontSize: ["28px", "12px", "28px", "28px"],
              fontWeight: "bold",
              color: "white",
              // fontFamily: "Quciksands",
            },
            justifyContent: "center",
            alignItems: "center",
            flex: "1",
          };
        },
        headerTitlesOne: () => {
          return {
            textAlign: "start",
            ml: "20px",
            _text: {
              fontSize: ["14px", "10px", "14px", "14px"],
              color: "white",
            },
            display: "flex",
            flexDirection: "row",
            gap: ["3", "1", "3"],
            flex: "1",
          };
        },
        headerTitlesTwo: () => {
          return {
            textAlign: "end",
            _text: {
              fontSize: ["14px", "10px", "14px", "14px"],
              color: "white",
            },
            flex: "1",
            mr: "20px",
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "end",
            alignItems: "center",
          };
        },
        VendorBox: () => {
          return {
            width: "100%",
            textAlign: "center",
          };
        },
        cartBoxSize: () => {
          return {
            margin: 8,
            marginTop: 20,
            overflowX: "hidden",
          };
        },
        NotFound: () => {
          return {
            height: "450px",
          };
        },
        Vendor_product_variant_box: () => {
          return {
            alignItems: "center",
            justifyContent:"center",
            height: "170px",
          };
        },
        outofstack_box: () => {
          return {
            zIndex: "1",
            position: "absolute",
            width: "80%",
            height: "40%",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          };
        },
        out_of_stock_image_box: () => {
          return {
            alignItems: "center",
            height: "170px",
          };
        },
        product_details_main_box: () => {
          return {
            // marginTop: "50px",
            // marginBottom: "50px",
            width: "100%",
          };
        },
        product_details_text_box: () => {
          return {
            padding: "20px",
            width: { base: "100%", lg: "40%" },
            zIndex: -10,
          };
        },
        product_card_box: () => {
          return {
            padding: "10",
            width: "100%",
            height: "100%",
          };
        },
        category_box: () => {
          return {
            borderRadius: "10px",
            width: ["0px", "230px", "290px"],
            height: "170px",
            px: "4",
            pt: "4",
            marginTop: "2",
          };
        },
        card_div_box: () => {
          return {
            width: "50%",
          };
        },
        search_wishlist_box: () => {
          return {
            flexDirection: "row-reverse",
            marginTop: "-30px",
            marginRight: "10px",
            padding: "5px",
          };
        },
        search_image_box: () => {
          return {
            justifyItems: "center",
            flexWrap: "wrap",
            height: "170px",
          };
        },
        search_box: () => {
          return {
            alignContent: "center",
          };
        },
        search_box_vstack_box: () => {
          return {
            height: "130px",
          };
        },
        add_address_card_box: () => {
          return {
            marginTop: "80px",
            marginLeft: "70px",
          };
        },
        address_content_Box: () => {
          return {
            height: "180px",
          };
        },
        address_details_box: () => {
          return {
            alignSelf: "center",
            width: "100%",
          };
        },
        card_div_box: () => {
          return {
            width: "50%",
          };
        },
        search_wishlist_box: () => {
          return {
            flexDirection: "row-reverse",
            marginTop: "-30px",
            marginRight: "10px",
            padding: "5px",
          };
        },
        search_image_box: () => {
          return {
            justifyItems: "center",
            flexWrap: "wrap",
            height: "170px",
          };
        },
        search_box: () => {
          return {
            alignContent: "center",
          };
        },
        search_box_vstack_box: () => {
          return {
            height: "130px",
          };
        },
        add_address_card_box: () => {
          return {
            marginTop: "80px",
            marginLeft: "70px",
          };
        },
        address_content_Box: () => {
          return {
            height: "180px",
          };
        },
        address_details_box: () => {
          return {
            alignSelf: "center",
            width: "100%",
          };
        },
        checkout_top_box: () => {
          return {
            padding: "10px",
            marginLeft: "35px",
            marginTop: "20px",
          };
        },
      },
    },
    VStack: {
      variants: {
        vendor_content_container_vstack: () => {
          return {
            width: "20%",
            justifyContent: "space-between",
          };
        },
        footer_container: () => {
          return {
            space: "3",
            background: "#ebffe6",
            // pb: "4",
          };
        },
        user_home_category_box_vstack: () => {
          return {
            space: "5",
            overflowX: "hidden",
          };
        },
        order_list_top_vstack: () => {
          return {
            width: "100%",
            alignItems: "left",
            space: "15px",
          };
        },
        profile_title: () => {
          return {
            width: "20%",
            m: "6",
            space: "3",
          };
        },
        order_list_contents: () => {
          return {
            mt: "20",
            width: "70%",
            alignItems: "left",
            space: "3",
          };
        },
        product_card_details: () => {
          return {
            space: "4",
            mt: "2",
          };
        },
        user_home_vstack: () => {
          return {
            space: "5",
            overflow: "hidden",
            mb: "20px",
          };
        },
        not_found_vstack: () => {
          return {
            alignItems: "center",
          };
        },
        search_card_vstack: () => {
          return {
            marginTop: "30px",
            space: "10",
          };
        },
        search_box_vstack: () => {
          return {
            space: "1.5",
          };
        },
        Address_menu_vstack: () => {
          return {
            width: "20%",
            m: "6",
            space: "3",
          };
        },
        Address_top_vstack: () => {
          return {
            mt: "20",
            width: "80%",
            space: "3",
          };
        },
        add_address_card_box_vstack: () => {
          return {
            space: "5",
          };
        },
        address_details_vstack: () => {
          return {
            space: "10%",
          };
        },
      },
    },
    Text: {
      variants: {
        vendor_site_text: () => {
          return {
            fontSize: "12px",
          };
        },
        cart_total_amount: () => {
          return {
            color: "green.600",
            fontWeight: "900",
            fontSize: "23px",
          };
        },
        product_name: () => {
          return {
            color: "#3bb77e",
            fontSize: [17],
            fontWeight: 400,
          };
        },
        checkout_text: () => {
          return {
            color: "gray.400",
            fontSize: "15px",
          };
        },
        menu_bar_title_text_two: () => {
          return {
            fontSize: "15px",
            fontWeight: "bold",
            color: "white",
          };
        },
        count_variant: () => {
          return {
            color: "#008000",
            fontSize: "bold",
            marginLeft: "10px",
            marginRight: "10px",
          };
        },
        vendor_code_tag_hstack_text: () => {
          return {
            flex: "1",
            fontSize: ["20px", "15px", "20px", "20px"],
          };
        },
        LogoGap: () => {
          return {
            mt: "4",
          };
        },
        FooterTextVariant: () => {
          return {
            fontSize: "20px",
            fontWeight: "bold",
            color: "#253d4e",
          };
        },
        menu_bar_title_text: () => {
          return {
            width: "100%",
            fontSize: ["30px", "25px", "30px", "45px"],
            fontWeight: "bold",
            color: "white",
          };
        },
        cart: () => {
          return {
            color: "#adadad",
          };
        },
        pageHeader: () => {
          return {
            fontSize: ["22px", "10px", "22px", "22px"],
            fontWeight: "bold",
            color: "black",
          };
        },
        FooterTitleVariant: () => {
          return {
            fontSize: ["20px", "13px", "20px", "20px"],
            fontWeight: "extrabold",
            width: "100%",
          };
        },
        FooterTitleText: () => {
          return {
            width: "100%",
            fontSize: ["20px", "13px", "20px", "20px"],
            fontWeight: "extrabold",
          };
        },
        profile_sidebar_items: () => {
          return {
            // // fontSize: ["2xl", "xl", "xl", "sm", "md", "2xl", "2xl", "3xl"],
            // fontWeight: ["bold"],
            // padding: "2px",
            color: "white",
            fontSize: "md",
            // mt: "5",
          };
        },
        order_page_title: () => {
          return {
            fontSize: "lg",
            fontWeight: "bold",
          };
        },
        account_details_content: () => {
          return {
            fontSize: "lg",
            color: "black",
          };
        },
        VendorBoxText: () => {
          return {
            fontSize: "55px",
            fontWeight: "bold",
          };
        },
        totalCountOfProducts: () => {
          return {
            color: "#16A34A",
          };
        },
        outofstack_box_text: () => {
          return {
            width: "66%",
            style: {
              color: "white",
              backgroundColor: "#f75942",
              border: "1px solid red",
              borderRadius: "10px",
              padding: "10px",
            },
          };
        },
        header_titles_one_text: () => {
          return {
            color: "#111",
            fontSize: ["14px", "10px", "14px", "14px"],
          };
        },
        product_details: () => {
          return {
            fontWeight: "bold",
            fontSize: ["5px", "15px", "40px"],
            marginTop: "16px",
          };
        },
        category_box_text: () => {
          return {
            fontWeight: "600",
            fontSize: ["0px", "15px", "25px"],
            marginTop: "16px",
          };
        },
        category_header_box_text: () => {
          return {
            fontSize: "30px",
            color: "#3bb77e",
            marginLeft: "50px",
            mt: "25px",
          };
        },
        product_card_out_of_stock: () => {
          return {
            style: {
              fontWeight: "bold",
              padding: "10px",
              fontSize: "14",
              color: "white",
              backgroundColor: "#F75942",
              textAlign: "center",
              position: "absolute",
              zIndex: "1",
              marginTop: "50px",
              borderRadius: "10px",
              border: "1px solid red",
            },
          };
        },
        tag_text_home: () => {
          return {
            fontSize: "18px",
            display: "flex",
            flexDirection: "row",
            gap: "3px",
            alignItems: "center",
          };
        },
        tag_text_hide: () => {
          return {
            fontSize: "18px",
            color: "gray.500",
          };
        },
        tag_text_unhide: () => {
          return {
            fontSize: "18px",
          };
        },
        no_products_found: () => {
          return {
            fontWeight: "bold",
            color: "#adadad",
            fontSize: "25px",
            height: "50px",
            marginTop: "50px",
          };
        },
        product_details_box: () => {
          return {
            width: "40%",
          };
        },
        product_card_alignment: () => {
          return {
            alignContent: "center",
          };
        },
        product_quantity_input: () => {
          return {
            width: "80px",
          };
        },
        bold_text: () => {
          return {
            fontWeight: "bold",
          };
        },
        product_name: () => {
          return {
            color: "#adadad",
          };
        },
        vendor_name: () => {
          return {
            color: "#22c55e",
          };
        },
        category_name: () => {
          return {
            fontSize: "35px",
            color: "#3bb77e",
            marginTop: "10px",
            marginLeft: ["", , "70px"],
          };
        },
        product_details_out_of_stock_text: () => {
          return {
            width: "40%",
            style: {
              color: "white",
              backgroundColor: "#F75942",
              border: "1px solid red",
              borderRadius: "10px",
              padding: "10px",
              textAlign: "center",
              fontSize: "22px",
              position: "absolute",
              marginTop: "200px",
              zIndex: "1",
            },
          };
        },
        terms_text: () => {
          return {
            px: "2",
            color: "#596372",
          };
        },
        input_header: () => {
          return {
            color: "#64748B",
            fontWeight: "600",
            fontSize: "15",
          };
        },
        not_found_text: () => {
          return {
            color: "#adadad",
            fontSize: "md",
            height: "50px",
          };
        },
        search_breadcrumb_text: () => {
          return {
            color: "#3BB77E",
            fontSize: "18px",
            display: "flex",
            flexDirection: "row",
            gap: "3px",
            alignItems: "center",
          };
        },
        search_breadcrumb_state_text: () => {
          return {
            fontSize: "18px",
            color: "gray.500",
          };
        },
        card_div_text: () => {
          return {
            color: "white",
            alignItems: "center",
            marginLeft: "17px",
            marginTop: "7px",
          };
        },
        sub_total: () => {
          return {
            fontSize: ["20px", "10px", "11px", "20px", "20px", "25px", "28px"],
            fontFamily: "Sans-Serif",
            fontWeight: 700,
            color: "green.600",
          };
        },
        out_of_stock_text: () => {
          return {
            style: {
              padding: "10px",
              fontSize: 14,
              color: "white",
              backgroundColor: "#F75942",
              textAlign: "center",
              position: "absolute",
              zIndex: "1",
              marginTop: "50px",
              marginLeft: "40px",
              borderRadius: "10px",
              border: "1px solid red",
            },
          };
        },
        search_category_name_text: () => {
          return {
            color: "#adadad",
          };
        },
        search_amount_text: () => {
          return {
            color: "green.500",
          };
        },
        Address_top_vstack_text: () => {
          return {
            marginTop: "20px",
            fontSize: "2xl",
          };
        },
        add_address_card_box_text: () => {
          return {
            fontSize: "2xl",
            marginLeft: "-37px",
          };
        },
        checkout_text: () => {
          return {
            display: "flex",
            justifyContent: "center",
            fontSize: "20px",
          };
        },
      },
    },
    HStack: {
      variants: {
        order_list_hstack: () => {
          return {
            width: "100%",
            space: "60%",
          };
        },
        header_content_vstack_image: () => {
          return {
            backgroundColor: "#5dc56a",
            space: "3",
            height: "80%",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "16px",
          };
        },
        header_content_vstack_image1: () => {
          return {
            backgroundColor: "#5dc56a",
            space: "3",
            height: "80%",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px",
          };
        },
        header_content_vstack: () => {
          return {
            justifyContent: "space-around",
            backgroundColor: "#5dc56a",
            style: { borderBottom: "1px solid #ffffff8f" },
            alignItems: "center",
            height: "25px",
          };
        },
        menu_bar_icon_box: () => {
          return {
            space: { base: "10", xs: "3", xl: "10" },
            justifyContent: "center",
            width: ["", "13%", ""],
          };
        },
        vendor_code_tag_hstack: () => {
          return {
            textAlign: "center",
            marginY: "40px",
          };
        },
        address_tag_space: () => {
          return {
            space: "1",
          };
        },
        user_home_hstack: () => {
          return {
            flexDirection: {
              base: "column",
              md: "row",
            },
          };
        },
        MenuStackVariant: () => {
          return {
            height: "60px",
            alignItems: "center",
            // justifyContent: "space-around",
            justifyContent: "center",
            backgroundColor: "green",
            mt: "1",
          };
        },
        product_details_align: () => {
          return {
            justifyContent: "center",
          };
        },
        product_card: () => {
          return {
            marginLeft: "10",
            space: "5",
            flexWrap: "wrap",
          };
        },
        menu_bar_box: () => {
          return {
            justifyContent: "center",
            fontSize: ["20px", "10px", "11px", "20px"],
            alignItems: "center",
          };
        },
        category_header_box: () => {
          return {
            justifyContent: "space-between",
            marginBottom: "20px",
          };
        },
        product_add_to_cart_fields: () => {
          return {
            space: "2",
          };
        },
        product_quantity: () => {
          return {
            justifyContent: "start",
            padding: "3",
          };
        },
        tag_stack: () => {
          return {
            space: "4",
            alignItems: "center",
          };
        },
        category_body: () => {
          return {
            space: 2,
            marginTop: 10,
            marginLeft: 20,
            style: { marginBottom: "20px" },
            flexWrap: "wrap",
          };
        },
        tag_stack: () => {
          return {
            space: "4",
            alignItems: "center",
          };
        },
        orderItems: () => {
          return {
            width: "100%",
            mt: "5",
          };
        },
        add_address_hstack: () => {
          return {
            space: "5",
            flexWrap: "wrap",
          };
        },
        address_action_hstack: () => {
          return {
            justifyContent: "center",
            space: "1",
            marginTop: "5px",
          };
        },
        search_breadcrumb_hstack: () => {
          return {
            space: "4",
            alignItems: "center",
          };
        },
        search_card_hstack: () => {
          return {
            space: "20",
            flexWrap: "wrap",
          };
        },
        search_amount_hstack: () => {
          return {
            justifyContent: "space-between",
          };
        },
        search_breadcrumb_hstack_1: () => {
          return {
            space: "4",
            alignItems: "center",
            marginLeft: "50px",
            padding: "10px",
          };
        },
        address_close_hstack: () => {
          return {
            justifyContent: "flex-end",
            space: "3",
            marginRight: "20px",
          };
        },
        checkout_add_address: () => {
          return {
            space: "5",
          };
        },
        cart_main_box: () => {
          return {
            margin: "50px",
          };
        },
        continue_shopping: () => {
          return {
            justifyContent: "space-between",
          };
        },
        shipping_details: () => {
          return {
            justifyContent: "space-between",
            marginBottom: "5px",
            padding: "5px",
            alignItems: "center",
          };
        },
      },
    },
    Stack: {
      variants: {
        vendor_content_container: () => {
          return {
            display: "flex",
            flexDirection: ["row", "column", "row"],
          };
        },
        footer_stack_container: () => {
          return {
            justifyContent: [
              "space-around",
              "center",
              "space-around",
              "space-around",
            ],
            display: "flex",
            flexDirection: ["row", "column", "row", "row"],
            padding: "10px",

          };
        },
      },
    },
    Button: {
      variants: {
        changePassword: () => {
          return {
            width: "19%",
            bgColor: "#5DC56A",
          };
        },
        checkout_button: () => {
          return {
            marginTop: "10px",
            background: "gold",
            fontSize: "18px",
            height: "50px",
            color: "black",
            fontFamily: "QucikSands",
            borderRadius: "5px",
          };
        },
      },
    },
    Center: {
      variants: {
        NotFound: () => {
          return {
            paddingTop: "100px",
            alignItems: "center",
          };
        },
        address_loading_center: () => {
          return {
            ml: "450px",
            mt: "200px",
          };
        },
      },
    },
  },
});
ReactDOM.render(
  <Provider store={store}>
    <NativeBaseProvider theme={theme}>
      <IconContext.Provider value={{ className: "global-class-name" }}>
        <App /> <ToastContainer newestOnTop pauseOnFocusLoss />{" "}
        <Toaster position="top-center" reverseOrder={false} />{" "}
      </IconContext.Provider>
    </NativeBaseProvider>
  </Provider>,
  document.getElementById("root")
);
