import { Space, Table, Tooltip, Typography, Pagination } from "antd";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { HStack, VStack, Box, Text, Badge } from "native-base";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import React, { useEffect, useState } from "react";
import { get_all_customer_orders_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_orders";
import AntdModal from "@views/components/ui/antd_modal/antd_modal";
import OrderDetails from "./orders_details";
import moment from "moment";
import ProfileLayout from "..";
import { order_status } from "@helpers/constants";

const OrdersList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [actionItem, setActionItem] = useState({});
  const {
    items: customer_orders_list,
    pagination,
    loading,
  } = useDynamicSelector("get_all_customer_orders");
  const [is_modal_open, setModalVisible] = useState(false);
  const [current, setCurrent] = useState(1);

  useEffect((value) => {
    let keys = [{ key: "get_all_customer_orders", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_customer_orders_query, {
        page_number: current,
        page_limit: 10,
      })
    );
  }, []);

  useEffect(() => {
    if (!is_modal_open) {
      setActionItem({});
    }
  }, [is_modal_open]);

  const fetchData = (value, values, id) => {
    let keys = [{ key: "get_all_customer_orders", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_customer_orders_query, {
        page_number: value,
        page_limit: values,
      })
    );
    setCurrent(value);
  };

  const handleView = (item) => {
    setModalVisible(true);
    setActionItem(item);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  let columns = [
    {
      title: t("table:order_number"),
      dataIndex: ["order_code"],
      align: "left",
      key: "order_code",
      render: (text, record) => (
        <a onClick={() => handleView(record)}>{text}</a>
      ),
    },
    {
      title: t("table:date"),
      dataIndex: ["created_at"],
      align: "left",
      key: "created_at",
      render: (text, record) => {
        return moment(record.created_at).format("DD/MM/YYYY");
      },
    },
    {
      title: t("table:status"),
      dataIndex: ["order_status"],
      align: "left",
      key: "status",
      render: (text, record) => {
        return (
          <Badge
            colorScheme={
              record?.order_status === "returned" ||
              record?.order_status === "vendorCancelled" ||
              record?.order_status === "customerCancelled" ||
              record?.order_status === "return_to_vendor"
                ? "danger"
                : record?.order_status === "delivered"
                ? "success"
                : record?.order_status === "partially_delivered" ||
                  record?.order_status === "in_transit"
                ? "info"
                : record?.order_status === "order_initiated" ||
                  record?.order_status === "ordered"
                ? "blue"
                : "primary"
            }
            borderRadius={"xl"}
            width="fit-content"
          >
            {order_status[record?.order_status]}
          </Badge>
        );
      },
    },
    {
      title: t("table:total"),
      dataIndex: ["t_amount"],
      align: "right",
      key: "t_amount",
      flex: 1,
      render: (text, record) => (
        <Box>
          <Text>
            {Number(record?.t_amount).toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })}
          </Text>
        </Box>
      ),
    },
  ];
  return (
    <ProfileLayout title="orders">
      <VStack variant="order_list_contents">
        <HStack variant="order_list_hstack">
          <Text variant="pageHeader">{t("orders")}</Text>
        </HStack>
        <AntdModal
          header={t("orders")}
          isOpen={is_modal_open}
          onClose={handleClose}
          width="90%"
          label="view"
          component={<OrderDetails initialValues={actionItem} />}
        />
        <Wrapper>
          <Table
            size={"middle"}
            columns={columns}
            dataSource={customer_orders_list}
            pagination={false}
            loading={loading}
          />
        </Wrapper>
        <Pagination
          showSizeChanger={true}
          current={current}
          hideOnSinglePage={false}
          onChange={fetchData}
          pageSizeOptions={[1, 5, 10, 20]}
          total={pagination?.total_count}
        />
      </VStack>
    </ProfileLayout>
  );
};

export default OrdersList;
