import React, { useEffect, useState } from "react";
import { Button, Dropdown, Space } from "antd";
import {
  dynamicRequest,
  get_all_categories_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { HStack, Hidden } from "native-base";
import OutsideClickHandler from "react-outside-click-handler";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
const DropDownLists = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { items: get_all_categories } =
    useDynamicSelector("get_all_categories");
  const getAllCategories = () => {
    let keys = [{ key: "get_all_categories", loading: true }];
    dispatch(dynamicRequest(keys, get_all_categories_query, {}));
  };
  useEffect(() => {
    getAllCategories();
  }, []);
  const [open, setOpen] = useState(false);
  const handleClick = (key) => {
    let pathname;
    // this is masala items key masala items not have sub category
    if (key !== "73adaa6a-1600-41f5-92d8-d11c48978de2") {
      pathname = `${ROUTES.SUB_CATEGORY}/${key}`;
    } else {
      pathname = `${ROUTES.PRODUCT}/${key}`;
    }
    history.push({ pathname });
    setOpen(!open);
  };

  let items = get_all_categories?.map((category) => {
    return {
      label: category?.name,
      key: category?.id,
      icon: <img src={category?.icon} alt={category?.name} />,
    };
  });
  const handleMenuClick = (e) => {
    console.log("click", e);
  };
  const menuProps = {
    items,
    onClick: (item) => {
      handleClick(item);
    },
  };
  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false);
        }}
      >
        <Button
          style={{
            width: "80%",
            borderRadius: "20px",
            backgroundColor: "#5dc56a",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            height: "42px",
            fontSize: "20px",
            color: "white",
          }}
          className={"menu-bar-button"}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <HStack variant={"menu_bar_box"}>
            All Categories
            {open ? (
              <RiArrowDropUpLine size={30} color="white" />
            ) : (
              <RiArrowDropDownLine color="white" size={30} />
            )}
          </HStack>
        </Button>
      </OutsideClickHandler>
      <div class={`menu ${open ? `active` : `inactive`}`}>
        <ul>
          {get_all_categories?.map((e) => {
            return (
              <>
                <li
                  onClick={() => {
                    handleClick(e.id);
                    setOpen(false);
                  }}
                >
                  <img src={e.icon} style={{ width: "30px" }} />
                  <a
                    onClick={() => {
                      // handleClick(e.id);
                      setOpen(false);
                    }}
                  >
                    {e.name}
                  </a>
                </li>
              </>
            );
          })}
        </ul>
      </div>
      {/* <Dropdown menu={menuProps} className="category_lists">
        <Button>
          All Categories
          <DownOutlined />
        </Button>
      </Dropdown> */}
    </>
  );
};
export default DropDownLists;
