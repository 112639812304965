import { gql } from "@apollo/client";

export const get_all_customer_carts = gql`
  query getAllCustomerCarts {
    get_all_customer_carts: getAllCustomerCarts {
      items {
        id
        customer_id
        sub_total
        customer {
          name
        }
        product_count
        vendor_product_variant_id
        vendor_product_variant_customer_cart {
          base_price
          icon
          stock_count
          selling_price
          name
          id
          average_rating
          product_rating {
            id
            rating
          }
          vendor_product {
            id
            vendor_id
          }
        }
      }
    }
  }
`;

export const create_customer_order = gql`
  mutation createcutomerorder($data: customer_order_input) {
    create_customer_order: createCustomerOrder(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_customer_cart = gql`
  mutation deleteCustomerCart($id: String!) {
    delete_customer_cart: deleteCustomerCart(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
