import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  Card,
  Divider,
  Pressable,
  Image,
} from "native-base";
import { Slider, Button, Space, Col, Row, Spin } from "antd";
import {
  dynamicRequest,
  get_all_categories_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";

export const CategoryBox = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { t } = useTranslation();

  const { items: categories, loading } =
    useDynamicSelector("get_all_categories");

  const [category, setCategory] = useState();

  const getAllCategories = () => {
    let keys = [{ key: "get_all_categories", loading: true }];
    dispatch(dynamicRequest(keys, get_all_categories_query));
  };

  useEffect(() => {
    getAllCategories();
    let temp = categories?.map((x) => {
      return { name: x.name, id: x.id, description: x.description };
    });
    setCategory(temp);
  }, []);

  const handleClick = (category_id, category_name) => {
    history.push({
      pathname: `${ROUTES.PRODUCT}/${category_id}`,
      state: { name: category_name },
    });
  };

  const CategoryCard = (props) => {
    return (
      <>
        <Pressable onPress={() => handleClick(props.id, props.name)}>
          <Box variant={"category_box"} backgroundColor={props.bg}>
            <Text variant={"category_box_text"}>{props.name}</Text>
            <Box alignItems={"end"}>
              <Image alt="category_icon" src={props.icon} size="md" />
            </Box>
          </Box>
        </Pressable>
      </>
    );
  };

  const CategoryHead = () => {
    return (
      <>
        <HStack variant={"category_header_box"}>
          <Text bold variant={"category_header_box_text"}>
            {t("categories")}
          </Text>
        </HStack>
      </>
    );
  };
  const CategoryBody = () => {
    return (
      <>
        <Center>
          <HStack variant="category_body">
            {categories?.map((item, index) => (
              <CategoryCard
                name={item.name}
                bg="#D8F1E5"
                id={item.id}
                category_name={item.name}
                icon={item.icon}
              />
            ))}
          </HStack>
        </Center>
      </>
    );
  };
  return (
    <>
      <VStack>
        <CategoryHead />
        <CategoryBody />
      </VStack>
    </>
  );
};
