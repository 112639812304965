import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Image,
  InputNumber,
  Card,
  Spin,
  Breadcrumb,
  Typography,
  Rate,
  Slider,
  Tooltip,
  Modal,
} from "antd";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Box,
  Center,
  HStack,
  Hidden,
  Pressable,
  ScrollView,
  Stack,
  Text,
  VStack,
} from "native-base";
import { BsCart3, BsHeart } from "react-icons/bs";
import {
  dynamicClear,
  dynamicRequest,
  get_all_vendor_product_variants,
  get_one_vendor_product_variant,
  get_one_vendor_product_vendor_details,
  get_vendor_products,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { FilterBox } from "../home_page_category/filter_box";
import { showNotification } from "@helpers/notify";
import { ROUTES } from "@views/routes/my_routes";
import { create_customer_cart_mutation } from "@services/redux/slices/dynamic_entity/graphql/graphql_cart";
import { create_customer_wishlist_mutation } from "@services/redux/slices/dynamic_entity/graphql/graphql_wishlist";
import { valueFromAST } from "graphql";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { RxSlash } from "react-icons/rx";
import RelatedProductList from "./related_product_list";
import ProductImages from "./product_images";
import AmountFormat from "@helpers/constants";
import Like from "@assets/images/like.png";
import ProductDescriptionDetails from "./product_description_details";
import ImageMagnifier from "./imagev";
import { IoLocationSharp } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import VendorProductDetails from "../vendor_page/vendor_product_list/vendor_product_details";
import { GrFormView } from "react-icons/gr";
import { startCase } from "lodash";
import ViewContactDetails from "./view_contact_details";
import { IoIosContact } from "react-icons/io";
import ContactDetails from "./view_mobile_details";
import { retrieveItem, storeItem } from "@helpers/storage";
import {
  create_customer_details_mutation,
  create_customer_views_vendor_detail_mutation,
} from "@services/redux/slices/dynamic_entity/graphql/check_customer_detail_query";

const ProductDetails = () => {
  const {
    id,
    name,
    description,
    discount,
    base_price,
    icon,
    gallery,
    value: stock,
    unit,
    original_price,
    stock_count,
    selling_price,
    time_to_ship,
    stock_keeping_unit,
    product_rating,
    vendor_product,
    view_count,
    loading: loading,
  } = useDynamicSelector("get_one_variant");
  const fpo_details = useDynamicSelector("get_one_variant_vendor_details")
  console.log("fpo_details", fpo_details);
  const { vendor_products, loading: other_products_loading } =
    useDynamicSelector("get_vendor");
  // const {  } = useDynamicSelector("top_ref")
  const top_ref = useRef()
  const { items: all_products, loading: related_product_loading } =
    useDynamicSelector("get_all_variants");
  const dispatch = useDispatch();
  const history = useHistory();
  const [get_image, set_image] = useState(icon);
  const { state } = useLocation();
  const { t } = useTranslation();

  let images = [icon];
  for (let i = 0; i < gallery?.length; i++) {
    images.push(gallery[i]);
  }

  const { loading: create_loading, status: cart_status } = useDynamicSelector(
    "create_customer_cart"
  );
  const { status: wishlist_status } = useDynamicSelector(
    "create_customer_wishlist"
  );
  const {
    is_customer,
    id: customer_id,
    loading: customer_loading,
  } = useDynamicSelector("checkCustomerDetail");

  const {
    status: createStatus,
    id: user_id,
    error: createError,
  } = useDynamicSelector("createCustomerDetail");
  const { status: view_status } = useDynamicSelector(
    "createCustomerViewsVendorDetails"
  );

  const { product_id } = useParams();
  const [rating_details, setRatingDetails] = useState({ product_rating });
  const [value, setValue] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const customers_id = retrieveItem("customer_id");
  const users_id = retrieveItem("user_id");
  const [mobile, setMobile] = useState("");
  let category_id =
    vendor_product?.vendor_product_variants?.[0]?.vendor_product?.product
      ?.category?.id;
  useEffect(() => {
    const element = top_ref?.current;
    if (element) {
      element.scrollTo({
        top: 0,
      });
    }
    getProductDetails();
    dispatch(dynamicClear("createCustomerViewsVendorDetails"))
    dispatch(dynamicClear("get_one_variant_vendor_details"))
  }, [product_id]);

  useEffect(() => {
    set_image(icon);
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      handleCustomerClose();
      showNotification({
        type: "success",
        message: "Added customer detail successfully",
      });
      dispatch(dynamicClear("createCustomerDetail"));
    } else if (createError) {
      showNotification({
        type: "failed",
        message: createError.message,
      });
    }
  }, [createStatus, createError]);

  useEffect(() => {
    if (cart_status === "success") {
      showNotification({
        type: "success",
        message: `Product added to cart successfully`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    } else if (cart_status === "failure") {
      showNotification({
        type: "failure",
        message: `Failed to add an item to cart`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    }
  }, [cart_status]);

  useEffect(() => {
    if (wishlist_status === "success") {
      showNotification({
        type: "success",
        message: `Product added to wishlist successfully`,
      });
      history.push(ROUTES.WISHLIST);
      dispatch(dynamicClear("create_customer_wishlist"));
    } else if (wishlist_status === "failure") {
      showNotification({
        type: "failure",
        message: `Failed to add an item to wishlist`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    }
  }, [wishlist_status]);

  const getProductDetails = () => {
    let keys = [{ key: "get_one_variant", loading: true }];
    dispatch(
      dynamicRequest(keys, get_one_vendor_product_variant, {
        id: product_id,
      })
    );
    setRatingDetails({});
    let temp = product_rating?.map((x) => {
      setRatingDetails(x);
    });
  };

  const handleIncrement = () => {
    setValue(value + 1);
  };

  const handleDecrement = () => {
    setValue(value - 1);
  };

  const rating = product_rating?.map(({ rating }) => rating);
  const get_fpo_details = () => {
    let keys = [{ key: "get_one_variant_vendor_details", loading: true }];
    let variables = {
      id: product_id,
    };
    dispatch(
      dynamicRequest(keys, get_one_vendor_product_vendor_details, variables)
    );
  };
  const handleAddCart = (id, value) => {
    let keys = [{ key: "create_customer_cart", loading: true }];
    let variables = {
      data: { product_count: value, vendor_product_variant_id: id },
    };
    dispatch(
      dynamicRequest(keys, create_customer_cart_mutation, variables, "M")
    );
  };

  const handleWishList = (id) => {
    let keys = [{ key: "create_customer_wishlist", loading: true }];
    let variables = { data: { vendor_product_variant_id: id } };
    dispatch(
      dynamicRequest(keys, create_customer_wishlist_mutation, variables, "M")
    );
  };

  const handleView = () => {
    history.push(ROUTES.USER_HOME);
  };

  const handleBack = (id) => {
    history.push(`${ROUTES.PRODUCT}/${category_id}`);
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const feedback = [
    {
      Name: "Aravind",
      Rating: 5,
      Feedback: "Excellent product",
    },
    {
      Name: "Jagathees",
      Rating: 4,
      Feedback: "Good product",
    },
    {
      Name: "Ajith Kumar",
      Rating: 3,
      Feedback: "Average product",
    },
  ];
  // window.addEventListener('load', function() {
  //     window.scrollTo(0, 0);
  //   });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 50,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const getOneVendorProducts = () => {
    let keys = [{ key: "get_vendor", loading: true }];
    let variables = {
      id: vendor_product?.vendor?.id,
    };
    dispatch(dynamicRequest(keys, get_vendor_products, variables));
  };

  useEffect(() => {
    if (vendor_product) {
      getOneVendorProducts();
      dispatch(dynamicClear("createCustomerViewsVendorDetails"));
      getAllProducts();
    }
  }, [vendor_product]);
  useEffect(() => {
    window.scrollTo({ top: -50, behavior: "auto" });
  }, []);

  const getAllProducts = () => {
    let keys = [{ key: "get_all_variants", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_vendor_product_variants, {
        category_id:
          vendor_product?.vendor_product_variants?.[0]?.vendor_product?.product
            ?.category?.id,
        page_number: 1,
        page_limit: 1000000000000,
      })
    );
  };

  const handleDetails = () => {
    if (customers_id || users_id) {
      get_fpo_details()
      create_customer_vendor_detail();
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCustomerClose = () => {
    setIsCustomerModalOpen(false);
  };

  const create_customer_detail = (values) => {
    let keys = [{ key: "createCustomerDetail", loading: true }];
    let variables = {
      data: values,
    };
    dispatch(
      dynamicRequest(keys, create_customer_details_mutation, variables, "M")
    );
  };

  const clearLocalStorageEndOfDay = () => {
    const now = new Date();
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0);
    const timeUntilMidnight = midnight.getTime() - now.getTime();
    setTimeout(() => {
      localStorage.clear();
      clearLocalStorageEndOfDay(); // Set up the next clearing
    }, timeUntilMidnight);
  };

  useEffect(() => {
    clearLocalStorageEndOfDay();
  }, []);

  const create_customer_vendor_detail = () => {
    let keys = [{ key: "createCustomerViewsVendorDetails", loading: true }];
    let variables = {
      data: {
        category_id:
          vendor_product?.vendor_product_variants?.[0]?.vendor_product?.product
            ?.category?.id,
        product_id:
          vendor_product?.vendor_product_variants?.[0]?.vendor_product?.product
            ?.id,
        vendor_id: vendor_product?.vendor_id,
        customer_id: customers_id || users_id,
      },
    };
    dispatch(
      dynamicRequest(
        keys,
        create_customer_views_vendor_detail_mutation,
        variables,
        "M"
      )
    );
  };

  useEffect(() => {
    if (is_customer === false) {
      handleCancel();
      setIsCustomerModalOpen(true);
    } else {
      handleCancel();
    }
  }, [is_customer]);

  useEffect(() => {
    if (customer_id) {
      storeItem("customer_id", customer_id);
      dispatch(dynamicClear("createCustomerDetail"));
    }
    if (user_id) {
      storeItem("user_id", user_id);
      dispatch(dynamicClear("createCustomerDetail"));
    }
  }, [customer_id, user_id]);

  // const clearLocalStorageEveryFourMinutes = () => {
  //   const fourMinutes = 4 * 60 * 1000; // 4 minutes in milliseconds

  //   setTimeout(() => {
  //     localStorage.clear();
  //     clearLocalStorageEveryFourMinutes(); // Set up the next clearing
  //   }, fourMinutes);
  // };

  // useEffect(() => {
  //   clearLocalStorageEveryFourMinutes();
  // }, []);

  return (
    <div ref={top_ref}>
      <Box variant="product_details_main_box">
        {loading || other_products_loading || related_product_loading ? (
          <Spin size="medium"></Spin>
        ) : (
          <>
            <Box justifyContent="center">
              {state?.name ? (
                <Hidden from="base" till="md">
                  <Box pl={"18px"} height={"70px"} justifyContent={"center"}>
                    <HStack space={3} alignItems="center">
                      <Pressable onPress={handleView}>
                        <Text variant={"tag_text_home"}>
                          <AiOutlineHome />
                          {t("Home")}
                        </Text>
                      </Pressable>
                      <RxSlash size={15} />
                      <Pressable onPress={handleBack}>
                        <Text variant={"tag_text_unhide"}>
                          {
                            vendor_product?.vendor_product_variants?.[0]
                              ?.vendor_product?.product?.category?.name
                          }
                        </Text>
                      </Pressable>
                      <RxSlash size={15} />
                      <Text variant={"tag_text_hide"}>{name}</Text>
                    </HStack>
                  </Box>
                </Hidden>
              ) : (
                ""
              )}
              <Stack
                justifyContent={"space-between"}
                display={"flex"}
                flexDirection={{ base: "column", xl: "row" }}
                paddingLeft={"30px"}
                paddingRight={"30px"}
              >
                <Box justifyContent={"start"} mt={"20px"}>
                  <ProductImages set_image={set_image} gallery={gallery} />
                </Box>
                <Box variant="product_details_box">
                  <Box variant="product_card_box" justifyContent={"center"}>
                    <Box variant={"product_details_out_of_stock_box"}>
                      {/* <div style={{ width: "400px", maxWidth: "500px" }}> */}
                      <ImageMagnifier src={get_image} key={1} />
                      {/* </div>e */}
                    </Box>
                  </Box>
                </Box>
                <Box variant="product_details_text_box">
                  <VStack variant="product_details">
                    {view_count > 50 && (
                      <HStack justifyContent={"flex-end"} width={"80%"}>
                        <Button
                          type="text"
                          style={{
                            width: "20px",
                            gap: "8px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <img src={Like} width={"20px"} /> {`  ${view_count}`}
                        </Button>
                      </HStack>
                    )}
                    <Text variant={"product_details"}>
                      {name}
                      {stock && unit && ` ,${stock} ${unit}`}
                    </Text>
                    <Text variant="product_name" fontSize={"20px"}>
                      {
                        vendor_product?.vendor_product_variants?.[0]
                          ?.vendor_product?.product?.category?.name
                      }{" "}
                      /{" "}
                      {
                        vendor_product?.vendor_product_variants?.[0]
                          ?.vendor_product?.product?.name
                      }
                    </Text>
                    {/* <Text>{t("rating")}:</Text> */}
                    {/* <Rate
                    style={{ fontSize: 30 }}
                    allowHalf
                    disabled
                    defaultValue={rating ? rating[0] : null}
                  /> */}

                    <VStack>
                      <HStack alignItems={"center"} height={"60px"} space={2}>
                        {discount != 0 ? (
                          <Text color={"red.400"} fontSize={"1.5rem"}>
                            {"-"}
                            {discount}%
                          </Text>
                        ) : (
                          ""
                        )}
                        {base_price && (
                          <Text variant="product_details" marginTop={"0px"}>
                            <AmountFormat amount={base_price} />
                          </Text>
                        )}
                      </HStack>
                      {selling_price && (
                        <HStack>
                          <Text fontSize={"15px"}>M.R.P : </Text>
                          <Text strikeThrough fontSize={"15px"}>
                            ₹{" "}
                            {selling_price?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </Text>
                        </HStack>
                      )}
                    </VStack>

                    <Text fontSize={"18px"} width={"100%"} mt={"20px"}>
                      {description}
                    </Text>
                    <HStack justifyContent={"center"} my={"20px"}>
                      <hr style={{ width: "90%" }} />
                    </HStack>
                    {description?.length !== 0 && (
                      <>
                        <ProductDescriptionDetails description={description} />
                        <hr style={{ width: "90%" }} />
                      </>
                    )}
                    <Text bold fontSize={"18px"} mb={"15px"}>
                      FPO details
                    </Text>
                    <Text bold fontSize={"md"}>
                      {vendor_product?.vendor?.name}
                    </Text>
                    <Box width={"80%"} mt={"20px"}>
                      {/* <Text>
                        <FiPhoneCall color="green" />{" "}
                        <b>{`${t("call_us")}   : `}</b>
                      </Text> */}
                      <VStack space={"5px"} alignItems={"start"}>
                        {fpo_details?.vendor_product?.vendor?.contact_person_name && (
                          <p>
                            <IoIosContact color="green" size={"16px"} />{" "}
                            <b>
                              <text>Contact Person: </text>
                            </b>
                            <text>
                              {fpo_details?.vendor_product?.vendor?.contact_person_name}
                            </text>
                          </p>
                        )}
                        {fpo_details?.vendor_product?.vendor?.owner?.mobile && (
                          <p>
                            <FiPhoneCall color="green" size={"15px"} />{" "}
                            <b>
                              <text>Mobile Number: </text>
                            </b>
                            <a
                              href={`tel:${fpo_details?.vendor_product?.vendor?.owner?.mobile}`}
                              className="a-link-tag"
                            >
                              {fpo_details?.vendor_product?.vendor?.owner?.mobile}
                            </a>
                          </p>
                        )}

                        {fpo_details?.vendor_product?.vendor?.vendor_address?.[0]
                          ?.address && (
                            <p>
                              <IoLocationSharp color="red" size={"15px"} />{" "}
                              <b>
                                <text>Address :</text>
                              </b>
                              <p>
                                {
                                  fpo_details?.vendor_product?.vendor?.vendor_address?.[0]
                                    ?.address
                                }
                              </p>
                            </p>
                          )}
                      </VStack>
                      {/* <Text>
                        <IoLocationSharp color="red" /> <b>Address : </b>
                      </Text> */}
                      {/* <div class="address-tag">
                        {vendor_product?.vendor?.vendor_address?.[0]
                          ?.address && (
                          <p>
                            {
                              vendor_product?.vendor?.vendor_address?.[0]
                                ?.address
                            }
                          </p>
                        )}
                      </div> */}
                    </Box>
                    {view_status === "success" ? (
                      " "
                    ) : (
                      <Box width={"200px"} py="10px">
                        <Button
                          onClick={handleDetails}
                          style={{ backgroundColor: "#3bb77e", color: "white" }}
                        >
                          View Contact Details
                        </Button>
                      </Box>
                    )}
                  </VStack>
                </Box>
              </Stack>
            </Box>

            <VStack padding={{ base: "10px", lg: "40px" }}>
              <RelatedProductList list={all_products} />
              <hr style={{ width: "80%", marginBottom: "30px" }} />
              <Text bold fontSize={"xl"}>
                Other products
              </Text>
              <VendorProductDetails product_list={vendor_products} />
            </VStack>
          </>
        )}

        <Modal
          width={600}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          title={"Contact details"}
        >
          <ContactDetails
            customer_loading={customer_loading}
            setMobile={setMobile}
          />
        </Modal>

        <Modal
          width={800}
          open={isCustomerModalOpen}
          onCancel={handleCustomerClose}
          footer={null}
        >
          <ViewContactDetails
            mobile={mobile}
            handleCustomerClose={handleCustomerClose}
            setIsModalOpen={setIsModalOpen}
          />
        </Modal>
      </Box>

      {/* <Card
        style={{
          borderRadius: "10px",
          width: "132vh",
          justifyContent: "center",
          marginBottom: "5px",
        }}
        padding={"10px"}
        hoverable
      >
        <Text bold>Feedback</Text>
        {feedback.map((list, index) => {
          let first_name = list.Name.charAt(0);
          return (
            <>
              <HStack
                width={"100%"}
                justifyContent={"start"}
                padding={"20px"}
                space={"10px"}
              >
                <Box>
                  <Box
                    borderRadius={"2xl"}
                    width={"20px"}
                    backgroundColor={"blue.200"}
                    alignItems={"center"}
                  >
                    {first_name}
                  </Box>
                </Box>
                <Box>
                  <Text>{list.Name}</Text>
                  <Rate defaultValue={list.Rating} disabled />
                  <Text>{list.Feedback}</Text>
                </Box>
              </HStack>
            </>
          );
        })}
      </Card> */}
    </div>
  );
};

export default ProductDetails;
