import { gql } from "@apollo/client";


export const create_customer_cart_mutation = gql`
  mutation createCustomerCart(
    $data: customer_cart_input
  ) {
    create_customer_cart: createCustomerCart(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;