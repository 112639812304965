import { ROUTES } from "@views/routes/my_routes";
import { Skeleton } from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineHome } from "react-icons/ai";
import { RxSlash } from "react-icons/rx";
import { useHistory } from "react-router-dom";
import Banner_image from "@assets/images/about-us.jpg";

const AboutUs = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleHome = () => {
    history.push({ pathname: ROUTES.USER_HOME });
  };
  const contents = {
    title: "Welcome to Department of Agriculture Marketing",
    content:
      "The Department of Agricultural Marketing, Tamil Nadu, has been functioning successfully since 1977, with prime objective of Regulating the Marketing of Agricultural produce thereby enriching the lives of the farmers through remunerative price realisations as well as supplying people with quality produce for a healthier life. It has meticulously transfigured since 2001 as Department of Agricultural Marketing and Agri. Business focussing on other activities like Agri Export, Post-Harvest Management, Food Processing, and Agricultural Marketing developmental activities in the wake of the liberalization of the economic policy of the country.",
  };
  return (
    <VStack>
      <Box variant={"about_us_image_container"}>
        <Box opacity={"0.7"}>
          <img src={Banner_image} />
        </Box>
      </Box>
      <VStack paddingX={"10%"} marginY={"3%"}>
        <HStack>
          <Box flex={1}>
            <Text fontSize={"23px"} fontWeight="bold">
              About Us
            </Text>
          </Box>
          <Box flex={1}>
            <HStack space={3} alignItems="center">
              <Pressable onPress={handleHome}>
                <Text variant={"tag_text_home"}>
                  <AiOutlineHome />
                  {t("Home")}
                </Text>
              </Pressable>
              <RxSlash size={10} />
              <Pressable disabled>
                <Text variant={"tag_text_hide"}>About us</Text>
              </Pressable>
            </HStack>
          </Box>
        </HStack>
        <Box mt={"28px"}>
          <Text fontSize={"30px"} fontWeight="bold">
            {contents.title}
          </Text>
        </Box>
        <HStack space={4} mt={"20px"}>
          <Box flex={1}>
            <Text textAlign={"justify"} lineHeight="30px" fontSize={"20px"}>
              {contents.content}...
              <a
                href="https://www.agrimark.tn.gov.in/home/about_us"
                target={"_blank"}
                className={"a-link-tag"}
              >
                view more
              </a>
            </Text>
          </Box>
          <Box flex={1}>
            {/* <Skeleton.Image style={{ width: "100%", height: "300px" }} /> */}
            <img
              src="https://v-agriosp-assets.blr1.vultrobjects.com/31ed090a-f6c6-43dd-935f-5561bb659966_about.png"
              alt="about_img"
              className="about-img"
            />
          </Box>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default AboutUs;
