import React from "react";
import { Box } from "native-base";
import ProductsCard from "./products_card";

const ProductList = () => {
  return (
    <Box flexWrap={"wrap"}>
      <ProductsCard />
    </Box>
  );
};
export default ProductList;
