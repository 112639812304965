import React from "react";
import { Carousel } from "antd";
import Posterbanner from "@assets/images/poster.png";
import Posterbanner2 from "@assets/images/bannerimages2.png";
import Posterbanner3 from "@assets/images/bannerimage3.png";
import Posterbanner4 from "@assets/images/bannerimage4.png";
import Posterbanner5 from "@assets/images/banner-image-6.jpg";

const contentStyle = {
  height: "300px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
const PosterBanner = () => (
  <Carousel autoplay>
    {/* <div>
      <img alt="example" src={Posterbanner2} width="100%" />
    </div> */}
    {/* <div>
      <img alt="example" src={Posterbanner} width="100%" />
    </div> */}
    <div>
      <img alt="example" src={Posterbanner3} width="100%" />
    </div>
    <div>
      <img alt="example" src={Posterbanner4} width="100%" />
    </div>
    <div>
      <img alt="example" src={Posterbanner5} width="100%" />
    </div>
  </Carousel>
);
export default PosterBanner;