import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import logo from "@assets/images/governmentlogo.png";
import vertace_logo from "@assets/images/vertace_logo.png";

import React, { useState } from "react";
import {
  Button,
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";
import SM from "@assets/icons/hands.png";
import HEART from "@assets/icons/SM.png";
import Entypo from "react-native-vector-icons/dist/Entypo";
//import image from "/Users/inukurthidileep/AgriOSP-User-Web-1/src/assets/icons/farm_image.jpg";

import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import RegisterForm from "./register_form";
import OtpForm from "./otp_form";
import { useTranslation } from "react-i18next";
import HeaderContents from "../layout/header_contents";

const Register = (props) => {
  const { t } = useTranslation();

  const [_showRegisterForm, setShowRegisterForm] = useState(false);
  const [_showOtpForm, setShowOtpForm] = useState(true);
  const [stateMobile, setStateMobile] = useState();
 // const [stateEmail, setStateEmail] = useState();

  const showRegisterForm = (value1) => {
    setShowRegisterForm(value1);
  };
  const showOTPForm = (value2) => {
    setShowOtpForm(value2);
  };
  const stateValues = (mob, email) => {
    setStateMobile(mob);
    //setStateEmail(email);
  };
  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <Box
        _dark={{
          bgImage: `url(https://agriosp-assets.s3.ap-south-1.amazonaws.com/fruits.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // opacity: "0.1"
        }}
        _light={{
          bgImage: `url(https://agriosp-assets.s3.ap-south-1.amazonaws.com/fruits.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // opacity: "0.1"
        }}
        my="auto"
        // px={4}

        overflowY="hidden"
        // mt={"0"}
        height={"100%"}
      >
        <HeaderContents/>
        <Center
          my="auto"
          _dark={{
            bg: "coolGray.900",
          }}
          _light={{
            opacity: "1",
          }}
          // marginTop="12%"
          // marginBottom="12%"
        >
          <Stack
            flexDirection={{
              base: "column",
              md: "row",
            }}
            w="95%"
            maxW={{
              md: "450ppx",
              // mt: "20"
            }}
            flex={{
              base: "1",
              md: "none",
            }}
          >
            <Box
              flex={1}
              px="6"
              py="2"
              // alignItems={"flex-start"}
              // flexDirection={"row"}
              // justifyContent={"center"}
              // display={"flex"}
              _light={{
                // bg: "box",
                opacity: "0.94",
              }}
              _dark={{
                bg: "coolGray.800",
                opacity: "1",
              }}
              space="3"
              justifyContent="center"
              borderTopRightRadius={{
                base: "xl",
                md: "xl",
              }}
              borderTopLeftRadius={{
                base: "xl",
                md: "xl",
              }}
              borderBottomRightRadius={{
                base: "xl",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "xl",
                md: "xl",
              }}
            >
              {_showRegisterForm &&  !_showOtpForm && (
              <RegisterForm
                showOTPForm={showOTPForm}
                showRegisterForm={showRegisterForm}
                _mob={stateMobile}
                //_email={stateEmail}
              />)}
            {!_showRegisterForm &&  _showOtpForm && (
              <OtpForm
                showOTPForm={showOTPForm}
                showRegisterForm={showRegisterForm}
                stateValues={stateValues}
              />
            )}
            </Box>
          </Stack>

        </Center>
        <HStack
          h={8}
          py={2}
          justifyContent={"center"}
          opacity={0.8}
          bgColor={"black"}
        >
          {/* <Text color="#fff" fontSize={12}>
            Powered By{" "}
          </Text>
          <a href="https://vertace.com/" target="blank">
            <img height="20" alt="vertace" src={vertace_logo} />
          </a> */}
          <Box position={"absolute"} right={0}>
            <Text color={"#ffff"}>
              {" "}
              © GOVERNMENT OF TAMIL NADU AGRICULTURAL DEPARTMENT{" "}
            </Text>
          </Box>
        </HStack>

        {/* <HStack justifyContent={"flex-end"}> © </HStack> */}
      </Box>
    </ScrollView>
  );
};

export default Register;
