import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  Link,
  Divider,
  Hidden,
} from "native-base";
import { Slider, Button, Space, Col, Row, Card, Spin } from "antd";
import {
  get_all_categories_query,
  dynamicRequest,
  dispatch,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "node_modules/swiper/swiper.scss";
import "node_modules/swiper/modules/navigation/navigation.scss";
import "node_modules/swiper/modules/pagination/pagination.scss";
import "node_modules/swiper/modules/scrollbar/scrollbar.scss";
import "../../../index.css";
// import "node_modules/swiper/swiper.min.css";
// import "swiper/.css";
import SwiperCore, {
  Autoplay,
  Keyboard,
  Pagination,
  Scrollbar,
  Zoom,
  A11y,
  Navigation,
} from "swiper";
import { FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import { RightCircleFilled, LeftCircleFilled } from "@ant-design/icons";
import "@src/style.css";
import SimpleGrid from "../ui/simple_grid/simple_grid";
import { SpaceContext } from "antd/lib/space";
import { CategoryCard } from "./category_card";
import { useTranslation } from "react-i18next";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export const CategoryBox = () => {
  const dispatch = useDispatch();
  const { items: getAllCategory, loading: loading } =
    useDynamicSelector("get_all_categories");
  let Category_name = [];
  let Category_icon = [];
  const { t } = useTranslation();

  const getAllCategories = () => {
    let keys = [{ key: "get_all_categories", loading: true }];
    dispatch(dynamicRequest(keys, get_all_categories_query, {}));
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    if (getAllCategory) {
      for (let i = 0; i < getAllCategory?.length; i++) {
        Category_name.push(getAllCategory[i].name);
        Category_icon.push(getAllCategory[i].icon);
      }
    }
  }, [getAllCategory]);

  const CategoryHead = () => {
    return (
      <>
        {/* <Hidden from="xs" till="md"> */}
        <HStack justifyContent={"space-between"} marginBottom={"40px"}>
          <Box>
            <Text
              bold
              fontSize="30px"
              color="#3bb77e"
              marginLeft={["", , "50px"]}
            >
              {t("categories")}
            </Text>
          </Box>
          <HStack paddingRight={{ base: 0, lg: 10 }} space={10}>
            <Button
              className="custom_prev_1"
              type="link"
              icon={
                <LeftCircleFilled
                  className="LeftCircleFilled"
                  // style={{ }}
                />
              }
              size={"large"}
            />

            <Button
              className="custom_next_1"
              type="link"
              icon={
                <RightCircleFilled
                  className="LeftCircleFilled"
                  // style={{ fontSize: "30px", color: "#3bb77e" }}
                />
              }
              size={"large"}
            />
          </HStack>
        </HStack>
        {/* </Hidden> */}
      </>
    );
  };
  const CategoryBody = () => {
    return (
      <Box alignItems={"center"} paddingX={10}>
        {loading && (
          <Spin size="medium">
            <div className="content" />
          </Spin>
        )}
        <Swiper
          modules={[Keyboard, Pagination, Scrollbar, Zoom]}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
            1440: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 4.5,
            },
            2560: {
              slidesPerView: 7,
            },
          }}
          autoplay={true}
          // keyboard={true}
          // navigation={true}
          navigation={{
            prevEl: ".custom_prev_1",
            nextEl: ".custom_next_1",
          }}
          loop={true}
          spaceBetween={0}
          slidesPerView={1}
          className="mySwiper"
        >
          {getAllCategory?.map((item) => (
            <SwiperSlide>
              <CategoryCard
                name={item.name}
                bg="#C5EAD9"
                id={item.id}
                icon={item.icon}
                has_sub={item?.sub_categories?.length > 0 ? true : false}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    );
  };
  return (
    <>
      <VStack>
        <CategoryHead />
        <CategoryBody />
      </VStack>
    </>
  );
};
