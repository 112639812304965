import {
  dynamicRequest,
  get_all_vendor_list,
  get_vendor_products,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button } from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineHome } from "react-icons/ai";
import { GrSearch } from "react-icons/gr";
import { RxSlash } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import VendorCard from "./vendor_card";

const VendorList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [key_values, setKeyvalues] = useState();
  const dispatch = useDispatch();
  const onSearch = () => {
    let Input = document.getElementById("VendorsearchBox");
    let searchValue = Input.value.toUpperCase();
    setKeyvalues(searchValue);
    let keys = [{ key: "get_all_vendors", loading: true }];
    let variable = { search_string: searchValue };
    dispatch(dynamicRequest(keys, get_all_vendor_list, variable));
  };
  const handleHome = () => {
    history.push({ pathname: ROUTES.USER_HOME });
  };

  const handleSearch = () => {
    let keys = [{ key: "get_all_vendors", loading: true }];
    let variable = { search_string: key_values };
    dispatch(dynamicRequest(keys, get_all_vendor_list, variable));
  };

  return (
    <VStack mb={"20px"}>
      <Box pl={"18px"} height={"70px"} justifyContent={"center"}>
        <HStack variant={"tag_stack"}>
          <Pressable onPress={handleHome}>
            <Text variant={"tag_text_home"}>
              <AiOutlineHome />
              {t("Home")}
            </Text>
          </Pressable>
          <RxSlash />
          <Text variant={"tag_text_hide"}>{t("Vendors_List")}</Text>
        </HStack>
      </Box>
      <Box variant="VendorBox">
        <Text variant={"VendorBoxText"}>{t("Vendors_List")}</Text>
      </Box>
      <Box alignItems={"center"}>
        <div class="search-box vendor-box">
          <input
            type="search"
            placeholder="Search for FPO..."
            class="menu-bar-input vender-search-input"
            id="VendorsearchBox"
            onKeyUp={onSearch}
          />
          <Button
            className="search_box_button vendor_box_botton"
            style={{
              border: "none",
              padding: "8px",
              height: "36px",
              borderRadius: "20px",
              opacity: "0.5",
            }}
            onClick={handleSearch}
          >
            <GrSearch size={"25px"} color="white" />
          </Button>
        </div>
      </Box>
      <VendorCard />
    </VStack>
  );
};
export default VendorList;
