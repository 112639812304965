import React, { useState } from "react";
import { Slider } from "antd";
import { Box, HStack, VStack, Text, Button, Image } from "native-base";
import {
  dynamicRequest,
  get_all_vendor_product_variants,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";

export const FilterBox = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formatter = (value) => `\u20B9${value}`;
  const [price_register, setPriceRegister] = useState([100, 1000]);
  const onChange = (value) => {
    // setPriceRegister(value);
  };
  const { loading } = useDynamicSelector("get_all_variants");
  const onAfterChange = (value) => {
    setPriceRegister(value);

  };
  const handlePress = () => {
    history.push({pathname:ROUTES.ALL_PRODUCTS,state:{price_list:price_register}})
  };
  return (
    <div class="filterBox">
      <Box
        padding="20px"
        borderWidth="1px"
        borderColor="lightgray"
        borderRadius="10px"
        marginTop={"10px"}
        // position="sticky"
      >
        <VStack space={5}>
          <Box>
            <Text
              fontSize="24px"
              marginBottom="10px"
              color="#3bb77e"
              fontWeight="bold"
            >
              Filter by price
            </Text>
          </Box>
          <Box style={{}}>
            <Slider
              range
              min={100}
              max={5000}
              tooltip={{
                formatter,
              }}
              trackStyle={{ backgroundColor: "#3bb77e" }}
              handleStyle={{
                backgroundColor: "#3bb77e",
                borderColor: "#3bb77e",
              }}
              step={100}
              defaultValue={[100, 1000]}
              onChange={onChange}
              onAfterChange={onAfterChange}
            />
            <HStack justifyContent={"space-between"}>
              <Box marginRight="20px">
                <HStack>
                  <Text>From:</Text>
                  <Text color={"green.600"}> &#8377;{price_register[0]}</Text>
                </HStack>
              </Box>
              <Box marginRight="20px">
                <HStack>
                  <Text>To:</Text>
                  <Text color={"green.600"}> &#8377;{price_register[1]}</Text>
                </HStack>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack alignItems={"end"} justifyContent={"space-between"}>
              <Box width={{ base: "35%", md: "50%" }} height="40px">
                <Button
                  background="#3bb77e"
                  borderRadius="5px"
                  onPress={handlePress}
                  isLoading={loading}
                  isLoadingText="FILTERING"
                >
                  <Text color="white">FILTER</Text>
                </Button>
              </Box>
              <Box alignContent={"end"}>
                {/* <Image
                  source={{
                    uri: "https://kiddiebank-assets.s3.ap-south-1.amazonaws.com/Cooking%20oil.png",
                  }}
                  alt="Alternate Text"
                  size="md"
                /> */}
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </div>
  );
};
