import { gql } from "@apollo/client";
export const get_search_records_query = gql`
  query getSearchRecords(
    $search_string: String
    $page_number: Float
    $page_limit: Float
  ) {
    get_search_records: getSearchRecords(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        icon
        name
        description
        vendor_product_id
        product_tax_id
        stock_keeping_unit
        base_price
        original_price
        stock_count
        value
        time_to_ship
        discount
        gallery
        selling_price
        average_rating
        unit
        product_rating {
          id
          vendor_product_variant_id
          rating
          review
        }
        vendor_product {
          id
          vendor_id
          product_id
          icon
          banner_image
          gallery
          vendor {
            id
            name
          }
          product {
            gallery
            id
            category {
              id
              name
            }
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
