import React from "react";

import ProductList from "@views/components/products_list/products_list";
import Header from "@views/components/layout/header";
import Footer from "@views/components/layout/footer";
import { Box, ScrollView } from "native-base";
import { useEffect } from "react";
import { useRef } from "react";
import { debounce } from "lodash";

const Product = () => {
  const top_ref = useRef();

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);

  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);
  return (
    <Box ref={top_ref} flexWrap={"wrap"}>
      <Box m="10">
        <ProductList />
      </Box>
    </Box>
  );
};

export default Product;
