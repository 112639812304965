import { gql } from "@apollo/client";


export const get_vendor_categories = gql`
  query getVendorCategories(
    $category_name: String
    $vendor_id: String
    $parent_category_id: String
    $page_number: Float
    $page_limit: Float
  ) {
    vendor_categories: getVendorCategories(
      category_name: $category_name
      vendor_id: $vendor_id
      parent_category_id: $parent_category_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        description
        icon
        banner_image
        sub_categories {
          id
          name
          description
          icon
          banner_image
          is_deleted
          parent_category_id
          sub_categories {
            id
            name
            description
            icon
            banner_image
            is_deleted
            parent_category_id
          }
        }
        products {
          id
          name
          description
          product_code
          stock_keeping_unit
          icon
          banner_image
          gallery
          is_deleted
          created_at
          updated_at
          category {
            id
            name
          }
          vendor_products {
            id
            vendor_id
            product_id
            icon
            banner_image
            gallery
            is_deleted
            vendor_product_variants {
              id
              name
              description
              icon
              vendor_product_id
              product_tax_id
              stock_keeping_unit
              base_price
              stock_count
              value
              time_to_ship
              original_price
              discount
              gallery
              selling_price
            }
            vendor {
              id
            }
            product {
              id
            }
            error {
              status_code
              message
            }
          }
        }
      }
      pagination {
        page_limit
        page_number
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;