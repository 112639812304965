import UserLayout from "@views/layouts/user_layout";
import Layout1 from "@views/layouts/user_layout1";
import ForgotPassword from "@views/pages/forgot_password";
import Login from "@views/pages/login";
import Register from "@views/pages/register";
import Search from "@views/pages/search/search";
import UserChangePassword from "@views/pages/user_change_password";
import UserHome from "@views/pages/user_home";
import OrdersList from "@views/components/profile/orders/orders_list";
import TrackYourOrder from "../components/profile/track_your_order";
import AccountDetails from "../components/profile/account_details";
import Address from "@views/pages/address/address";
import WishList from "@views/pages/wishlist";
import Cart from "@views/pages/cart";
import VendorList from "@views/components/vendor_page/vendor_page";
import Category from "@views/pages/category";
import Product from "@views/pages/product";
import ProductDetailsPage from "@views/pages/product_details";
import VendorProductList from "@views/components/vendor_page/vendor_product_list/vendor_product_list";
import FilterPage from "@views/components/filter_by_price_page/filter_price";
import AboutUs from "@views/components/about_us/about_us";
import ContactUs from "@views/components/contact_us/contact_us";
import Checkout from "@views/pages/checkout";
import ChangePassword from "@views/components/profile/change_password";
import SubCategory from "@views/pages/sub_category";

export const ROUTES = {
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  WISHLIST: "/wishlist",
  CART: "/cart",
  USER_HOME: "/",
  SEARCH: "/search",
  POLICY: "/privacy-policy",
  USER_CHANGE_PASSWORD: "/change-password",
  ORDERS: "/orders",
  TRACK_YOUR_ORDER: "/track-your-order",
  ACCOUNT_DETAILS: "/account-details",
  MY_ADDRESS: "/addresses",
  VENDOR_LIST: "/fbo",
  VENDOR_PRODUCT_LIST: "/vendor-products",
  CATEGORY: "/category",
  PRODUCT_DETAILS: "/product-details",
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  PRODUCT: "/product",
  CHANGE_PASSWORD: "/modify-password",
  ALL_PRODUCTS: "/all-products",
  CHECKOUT: "/checkout",
  SUB_CATEGORY: "/sub-category",
};

const myRoutes = [
  {
    path: ROUTES.REGISTER,
    page_key: "register",
    component: Register,
    authenticate: false,
  },
  // {
  //   path: ROUTES.USER_HOME,
  //   exact: true,
  //   key: "user_home",
  //   authenticate: false,
  //   component: UserHome,
  // },
  {
    path: ROUTES.FORGOT_PASSWORD,
    page_key: "forgot_password",
    component: ForgotPassword,
    authenticate: false,
  },
  {
    path: ROUTES.LOGIN,
    key: "user_login",
    authenticate: false,
    component: Login,
  },

  {
    key: "user",
    component: UserLayout,
    authenticate: false,
    children: [
      {
        path: ROUTES.USER_CHANGE_PASSWORD,
        key: "user_change_password",
        exact: true,
        authenticate: false,
        component: UserChangePassword,
      },
    ],
  },
  {
    key: "user_home",
    component: Layout1,
    authenticate: false,
    children: [
      {
        path: `${ROUTES.USER_HOME}/:user_id?`,
        exact: true,
        key: "user_home",
        authenticate: false,
        component: UserHome,
      },
      {
        path: `${ROUTES.SUB_CATEGORY}/:category_id?`,
        exact: true,
        key: "user_home",
        authenticate: false,
        component: SubCategory,
      },

      {
        path: ROUTES.WISHLIST,
        exact: true,
        key: "wishlist",
        authenticate: false,
        component: WishList,
      },
      {
        path: ROUTES.CART,
        exact: true,
        key: "cart",
        authenticate: false,
        component: Cart,
      },
      {
        path: ROUTES.CHECKOUT,
        exact: true,
        key: "checkout",
        authenticate: false,
        component: Checkout,
      },
      {
        path: ROUTES.SEARCH,
        key: "search-results",
        exact: true,
        authenticate: false,
        component: Search,
      },
      {
        path: ROUTES.ORDERS,
        key: "orders",
        authenticate: false,
        component: OrdersList,
      },
      {
        path: `${ROUTES.PRODUCT}/:category_id?`,
        exact: true,
        key: "products",
        authenticate: false,
        component: Product,
      },
      {
        path: ROUTES.TRACK_YOUR_ORDER,
        key: "track_your_order",
        authenticate: false,
        component: TrackYourOrder,
      },
      {
        path: ROUTES.MY_ADDRESS,
        key: "my_address",
        authenticate: false,
        component: Address,
      },
      {
        path: ROUTES.ACCOUNT_DETAILS,
        key: "account_details",
        authenticate: false,
        component: AccountDetails,
      },
      {
        path: ROUTES.VENDOR_LIST,
        key: "fpo-list",
        authenticate: false,
        component: VendorList,
      },
      {
        path: `${ROUTES.VENDOR_PRODUCT_LIST}/:id?`,
        key: "fpo-product-list",
        authenticate: false,
        component: VendorProductList,
      },
      {
        path: `${ROUTES.PRODUCT_DETAILS}/:product_id?`,
        exact: true,
        key: "product_details",
        authenticate: false,
        component: ProductDetailsPage,
      },
      {
        path: ROUTES.CATEGORY,
        exact: true,
        key: "category",
        authenticate: false,
        component: Category,
      },
      {
        path: ROUTES.ABOUT_US,
        exact: true,
        key: "about_us",
        authenticate: false,
        component: AboutUs,
      },
      {
        path: ROUTES.CONTACT_US,
        exact: true,
        key: "contact_us",
        authenticate: false,
        component: ContactUs,
      },
      {
        path: ROUTES.CHANGE_PASSWORD,
        exact: true,
        key: "change_password",
        authenticate: false,
        component: ChangePassword,
      },
      {
        path: ROUTES.ALL_PRODUCTS,
        exact: true,
        key: "all_products",
        authenticate: false,
        component: FilterPage,
      },
    ],
  },
];
// {
//   key: "user1",
//   component: Layout1,
//   authenticate: false,
//   children: [
//     {
//       path:
//       key: "user_change_password",
//       exact: true,
//       authenticate: false,
//       component: UserChangePassword,
//     },
//   ],
// },
export default myRoutes;
