import React, { useEffect, useState, ReactSelect } from "react";
import { Button, Card, Col, Image, Row, Space, Spin, Typography } from "antd";
import Sunoil from "@assets/images/sunoil.png";
import Rating from "./rating";
import {
  AspectRatio,
  Box,
  Center,
  Hidden,
  HStack,
  Pressable,
  Text,
  Tooltip,
  VStack,
} from "native-base";
import {
  get_all_products_query,
  dynamicRequest,
  dispatch,
  useDynamicSelector,
  get_all_vendor_product_variants,
  get_all_vendor_list,
  create_customer_cart_mutation,
  dynamicClear,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { BsCart3 } from "react-icons/bs";
import "../../../index.css";
import { AiOutlineHeart, AiOutlineHome } from "react-icons/ai";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { showNotification } from "@helpers/notify";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { RxSlash } from "react-icons/rx";
import ProductsCardDesign from "../home_page_product/product_card";

const ProductsCard = (props) => {
  const { t } = useTranslation();
  const { category_id } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { items: all_products, loading: loading } =
    useDynamicSelector("get_all_variants");
  const { items: vendors, loading: vendor_loading } =
    useDynamicSelector("get_all_vendors");
  const history = useHistory();

  const {
    loading: create_loading,
    status: cart_status,
    error: cart_error,
  } = useDynamicSelector("create_customer_cart");

  const { rating, review } = useDynamicSelector("get_product_rating");
  const [active_id, setActiveId] = useState("");
  const [products, setProducts] = useState();

  useEffect(() => {
    getAllProducts();
    // getAllVendors();
  }, [category_id]);

  useEffect(() => {
    if (cart_status == "success") {
      showNotification({
        type: "success",
        message: `Product added to cart successfully`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    }
    // else if (cart_status === "failure") {
    //   showNotification({
    //     type: "failure",
    //     message: `Failed to add an item to cart`,
    //   });
    //   dispatch(dynamicClear("create_customer_cart"));
    // }
    else if (cart_error === "Not authorized") {
      showNotification({
        type: "error",
        message: cart_error,
      });
      dispatch(dynamicClear("create_customer_cart"));
      history.push(ROUTES.LOGIN);
    }
  }, [cart_status, cart_error]);

  const getAllProducts = () => {
    let keys = [{ key: "get_all_variants", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_vendor_product_variants, {
        category_id: category_id,
        page_number: 1,
        page_limit: 1000000000000,
      })
    );
  };

  const getAllVendors = () => {
    let keys = [{ key: "get_all_vendors", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_vendor_list, {
        page_number: 1,
        page_limit: 10,
      })
    );
  };

  const handleClick = (product_id) => {
    history.push({
      pathname: `${ROUTES.PRODUCT_DETAILS}/${product_id}`,
      state: { name: state?.name },
    });
  };

  const handleVendorPage = (id) => {
    history.push({ pathname: `${ROUTES.VENDOR_PRODUCT_LIST}/${id}` });
  };

  const handleAddCart = (id) => {
    let keys = [{ key: "create_customer_cart", loading: true }];
    let variables = {
      data: {
        customer_id: "e01b667c-02b0-4de8-806c-2b8028c915ec",
        product_count: 1,
        vendor_product_variant_id: id,
      },
    };
    dispatch(
      dynamicRequest(keys, create_customer_cart_mutation, variables, "M")
    );
  };
  const handleHome = () => {
    history.push(ROUTES.USER_HOME);
  };
  const handleCategory = () => {
    history.push(`${ROUTES.SUB_CATEGORY}/${state?.category?.id}`);
  };
  console.log("all_products", state);
  return (
    <Box height={"100vh"} width={"100%"} flex={1}>
      <Box height={"70px"} justifyContent={"center"}>
        <HStack space={1} alignItems="center">
          <Pressable onPress={handleHome}>
            <Text variant={"tag_text_home"}>
              <AiOutlineHome />
              {t("Home")}
            </Text>
          </Pressable>
          {state?.category && (
            <>
              <RxSlash size={15} />
              <Pressable onPress={handleCategory}>
                <Text variant={"tag_text_home"}>{state?.category?.name}</Text>
              </Pressable>
            </>
          )}
          <RxSlash size={15} />
          {/* <Pressable onPress={handleBack}> */}
          <Text variant={"tag_text_hide"}>
            {all_products?.[0]?.vendor_product?.product?.category?.name ||
              state?.name}
          </Text>
          {/* </Pressable> */}
        </HStack>
      </Box>
      <Text
        bold
        fontSize="35px"
        color="#3bb77e"
        marginTop={"10px"}
        marginLeft={["", , "70px"]}
      >
        {state?.name}
      </Text>
      {loading && (
        <Spin size="medium">
          <div className="content" />
        </Spin>
      )}

      {!loading &&
        (all_products?.length == 0 || all_products == null ? (
          <Box variant="no_products_found">
            <Center variant="NotFound">
              <VStack alignItems={"center"}>
                <Text variant="no_products_found">{t("no_products")} ...</Text>
              </VStack>
            </Center>
          </Box>
        ) : (
          <HStack marginLeft={10} space={5} flexWrap={"wrap"}>
            {all_products?.map((item, index) => (
              <VStack margin={"20px"} space={20} flexWrap={"wrap"}>
                <ProductsCardDesign
                  id={item?.id}
                  icon={item?.icon}
                  stock_count={item?.stock_count}
                  discount={item?.discount}
                  category={item?.vendor_product?.product?.category?.name}
                  product_name={item?.vendor_product?.product?.name}
                  variant_name={item?.name}
                  base_price={item?.base_price}
                  selling_price={item?.selling_price}
                  rating={item?.average_rating}
                  vendor_name={item?.vendor_product?.vendor?.name}
                  vendor_id={item?.vendor_product?.vendor?.id}
                />
              </VStack>
            ))}
          </HStack>
        ))}
    </Box>
  );
};

export default ProductsCard;
