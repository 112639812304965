// import AiFillAppstore from "react-icons/ai"
// import Tomato from "@assets/icons/tomato-60.png"

export const languages = ["en"];
export const ProjectName = "Agri OSP";
export const defaultPageLimit = 999999999999;
export const defaultPageNumber = 1;
export const page_count = 12;
export const operator_matches_page_count = 1;

export const GOOGLE_API_KEY = "AIzaSyCVY8hRJ-X4VVYVuDECrRQynxwvuk6Kgow";
export const LOCATION_PROVIDER = "google";
export const order_status = {
  order_initiated: "Order Placed",
  returned: "Returned",
  vendorCancelled: "FPO Cancelled",
  customerCancelled: "Customer Cancelled",
  return_to_vendor: "Return to FPO",
  delivered: "Delivered",
  partially_delivered: "Partially Delivered",
  in_transit: "In Transit",
  ordered: "Ordered",
};
// export const IMAGE_URL = "https://cdn.standardmatrimony.com/";

// export const MENU_LIST = [
//   {
//     // icon:<AiFillAppstore/>,
//     icon:<MdArrowDropDown size={20} color="black"/>,
//     label: "All Categories",
//     key:"all_categories",
//     children:[
//       {
//         label:<Megamenu/>,
//         key:"megamenu",
//         style:{height:"fit-content",width:"300px",justifyContent:"space-between",fontSize:"20px"}
//       }
//     ]
//   },
// ];
// export const MegaMenu_list=[
//   {
//     icon: <img src={CookingOli} alt="Tomato" width={"30px"}/>,
//     label:"Cooking Oil",
//     key:"cooking_oil",
//     style:{ border:"1px solid lightgray", borderRadius:"5px"},
//   },
//   {
//     icon: <img src={Flour} alt="Tomato" width={"30px"}/>,
//     label:"Flour",
//     key:"flour",
//     style:{ border:"1px solid lightgray", borderRadius:"5px"}

//   },
//   {
//     icon: <img src={Millets} alt="Tomato" width={"30px"}/>,
//     label:"Millets",
//     key:"millets",
//     style:{ border:"1px solid lightgray", borderRadius:"5px"}
//   },
//   {
//     icon: <img src={Wheat} alt="Tomato" width={"30px"}/>,
//     label:"Rice",
//     key:"rice",
//     style:{ border:"1px solid lightgray", borderRadius:"5px"}

//   },
//   {
//     icon: <img src={Pickles} alt="Tomato" width={"30px"}/>,
//     label:"Pickles",
//     key:"pickels",
//     style:{ border:"1px solid lightgray", borderRadius:"5px"}
//   },
//   {
//     icon: <img src={Tomato} alt="Tomato" width={"30px"}/>,
//     label:"Tomato",
//     key:"tomato",
//     style:{ border:"1px solid lightgray", borderRadius:"5px"}

//   },
//   {
//     icon: <img src={Snackes} alt="Tomato" width={"30px"}/>,
//     label:"Snackes",
//     key:"snackes",
//     style:{ border:"1px solid lightgray", borderRadius:"5px"}
//   },
//   {
//     icon: <img src={Sweets} alt="Tomato" width={"30px"}/>,
//     label:"Sweets",
//     key:"sweets",
//     style:{ border:"1px solid lightgray", borderRadius:"5px"}
//   },
// ]
// function Megamenu(){
//   return(
//     <div style={{height:"fit-content"}}>
//       <Menu items={MegaMenu_list}/>
//     </div>
//   )
// }

export function replaceKeysDeep(obj, keysMap) {
  return _.transform(obj, function (result, value, key) {
    var currentKey = keysMap[key] || key;
    result[currentKey] = _.isObject(value)
      ? replaceKeysDeep(value, keysMap)
      : value;
  });
}


export const AmountFormat = ({ amount }) => {
  // Convert the amount to a string
  const formatAsCurrency = (amount, currencyCode = "INR") => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: currencyCode,
    }).format(amount);
  };
  const amountStr = formatAsCurrency(amount);

  // Get the last two digits
  const lastTwoDigits = amountStr?.slice(-2);

  // Get the digits except for the last two
  const restOfDigits = amountStr?.slice(0, -2);

  return (
    <div className="amount-container">
      <span className="normal-font">{restOfDigits}</span>
      <span className="small-font">{lastTwoDigits}</span>
    </div>
  );
};

export default AmountFormat;
