import { Box, HStack, Text } from "native-base";
import React, { useEffect } from "react";
import {
  get_all_customer_carts,
  dynamicRequest,
  dispatch,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const CartHead = () => {
  const dispatch = useDispatch();

  // const { items,status,error } = useDynamicSelector("get_all_customer_carts");
  // console.log();
  // useEffect(() => {
  //   let keys = [{ key: "get_all_customer_carts", loading: true }];
  //   dispatch(dynamicRequest(keys, get_all_customer_carts, {}));
  // }, [get_all_customer_carts]);
  return (
    <Box marginBottom={10}>
      <Text fontSize={"40px"} fontWeight={"bold"}>
        Your Cart
      </Text>
      <HStack>
        <Text>There are</Text>
        <Text variant={"totalCountOfProducts"}> </Text>
        <Text>products in your cart</Text>
      </HStack>
    </Box>
  );
};

export default CartHead;
