import { gql } from "@apollo/client";

export const get_all_customer_wishlist = gql`
  query getAllWishlist {
    get_all_customer_wishlist: getAllCustomerWishlists {
      items {
        id
        customer_id
        vendor_product_variant_id
        created_at
        updated_at
        is_deleted
        customer {
          id
        }
        vendor_product_variant_customer_wishlist {
          id
          name
          description
          icon
          vendor_product_id
          base_price
          stock_count
          value
          product_rating {
            id
            rating
            vendor_product_variant_id
            review
            error {
              status_code
              message
            }
          }
          product_tax {
            id
            name
            percentage
          }
          product_tax_id
          time_to_ship
          original_price
          discount
          gallery
          created_at
          updated_at
          is_approved
          is_deleted
          selling_price
        }
      }
      pagination {
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_customer_wishlist_item = gql`
  mutation deleteCustomerWishlist($id: String!) {
    delete_customer_wishlist_item: deleteCustomerWishlist(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_customer_cart = gql`
  mutation createCustomerCart($data: customer_cart_input) {
    create_customer_cart: createCustomerCart(data: $data) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;
