import { gql } from "@apollo/client";


export const get_all_filter_products_query = gql`
  query getAllProducts($category_id:String,$vendor_id:String) {
    get_all_filter_products: getAllProducts (category_id:$category_id,vendor_id:$vendor_id) {
      items{ 
        id
        name
        description
        
      }
    }
  }
`;