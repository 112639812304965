import { gql } from "@apollo/client";

export const create_category_mutation = gql`
  mutation createCategory($data: category_input) {
    create_category: createCategory(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_category_mutation = gql`
  mutation updateCategory($id: String!, $data: category_input) {
    update_category: updateCategory(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_category_query = gql`
  query getCategory($id: String!) {
    get_category: getCategory(id: $id) {
      id
      name
      description
      icon
      banner_image
      is_deleted
      parent_category_id
      sub_categories {
        id
        name
        description
        icon
        banner_image
        is_deleted
        parent_category_id
        sub_categories {
          id
          name
          description
          icon
          banner_image
          is_deleted
          parent_category_id
        }
      }
      products {
        id
        name
        description
        product_code
        stock_keeping_unit
        icon
        banner_image
        gallery
        is_deleted
        created_at
        updated_at
        category {
          id
          name
        }
        vendor_products {
          id
          vendor_id
          product_id
          icon
          banner_image
          gallery
          is_deleted
          vendor_product_variants {
            id
            name
            description
            icon
            vendor_product_id
            product_tax_id
            stock_keeping_unit
            base_price
            stock_count
            value
            time_to_ship
            original_price
            discount
            gallery
            created_at
            updated_at
          }
        }
      }
    }
  }
`;

export const get_all_categories_query = gql`
  query {
    get_all_categories: getAllCategories {
      items {
        id
        name
        description
        icon
        banner_image
        is_deleted
        parent_category_id
        sub_categories {
          id
          name
        }
      }
      pagination {
        page_limit
        page_number
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_category_mutation = gql`
  mutation deleteCategory($id: String!) {
    delete_category: deleteCategory(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
