import { gql } from "@apollo/client";

export const get_all_country_query = gql`
  query getAllCountries($country_name: String, $page_number: Float, $page_limit: Float ) {
    get_all_country: getAllCountries(country_name: $country_name, page_number: $page_number, page_limit: $page_limit) {
        items{
            id
            name
        }
        error{
            status_code
            message
        }
        pagination{
            page_number
            page_limit
            total_count
        }
    }
}`;