import React from "react";
import HeaderContents from "./header_contents";
import MenuBar from "./menu_bar";

const Header = () => {

 return(
  <>
  <HeaderContents/>
  <MenuBar/>
  </>
 )
};
export default Header;