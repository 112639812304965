import React from "react";
import ProductsCard from "../home_page_product/product_card";
import { Box, HStack, SimpleGrid, Text } from "native-base";

const RelatedProductList = (props) => {
  const { list } = props;
console.log("all_products", list);
return (
  <div>
    <hr style={{ width: "80%", marginBottom: "30px" }} />
    <Box>
      <Text bold fontSize={"xl"}>
        Related products
      </Text>
    </Box>
    <div className="vendorproduct-gird">
      {list?.map((item) => (
        <div className="product-card-list" key={item?.id}>
          <ProductsCard
            id={item?.id}
            icon={item?.icon}
            stock_count={item?.stock_count}
            discount={item?.discount}
            category={item?.vendor_product?.product?.category?.name}
            product_name={item.vendor_product?.product?.name}
            variant_name={item?.name}
            base_price={item?.base_price}
            selling_price={item?.selling_price}
            vendor_name={item?.vendor_product?.vendor?.name}
            vendor_id={item?.vendor_product?.vendor?.id}
            stock={item?.value}
            unit={item?.unit}
          />
        </div>
      ))}
    </div>
  </div>
);
};

export default RelatedProductList;
