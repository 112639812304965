import React, { useEffect } from "react";
import { Box, VStack } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Header from "@views/components/layout/header";
import Footer from "@views/components/layout/footer";
const Layout1 = ({ children }) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // This option enables smooth scrolling
  });
  return (
    <ScrollView>
      <VStack>
        <Header />
        <Box minH={"500px"} backgroundColor={"#00ff000a"}>
          {children}
        </Box>
        <Footer />
      </VStack>
    </ScrollView>
  );
};
export default Layout1;
