import { gql } from "@apollo/client";

export const get_all_vendor_product_variants = gql`
  query getAllVendorProductVariants(
    $category_id: String
    $product_id: String
    $page_number: Float
    $page_limit: Float
    $from_price: Float
    $to_price: Float
  ) {
    get_all_variants: getAllVendorProductVariants(
      product_id: $product_id
      category_id: $category_id
      page_number: $page_number
      page_limit: $page_limit
      from_price: $from_price
      to_price: $to_price
    ) {
      items {
        id
        name
        value
        stock_count
        base_price
        original_price
        selling_price
        description
        icon
        time_to_ship
        discount
        product_tax_id
        vendor_product_id
        unit
        vendor_product {
          id
          vendor_id
          product_id
          vendor {
            id
            name
            contact_person_name
            description
            logo
            contact_number
          }
          product {
            id
            name
            category {
              id
              icon
              name
              description
              sub_categories {
                id
                name
                icon
                description
              }
            }
          }
        }
        average_rating
        product_rating {
          id
          review
          rating
        }
      }
      pagination {
        page_limit
        page_number
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const get_one_vendor_product_variant = gql`
  query getVendorProductVariant($id: String!) {
    get_one_variant: getVendorProductVariant(id: $id) {
      id
      name
      description
      vendor_product_id
      product_tax_id
      stock_keeping_unit
      base_price
      stock_count
      icon
      value
      time_to_ship
      original_price
      discount
      gallery
      selling_price
      view_count
      unit
      vendor_product {
        id
        vendor_id
        product_id
        icon
        vendor {
          id
          name
          name_of_commodities
          contact_person_name
          owner {
            user_name
          }
        }
        banner_image
        vendor_product_variants {
          id
          name
          description
          vendor_product_id
          product_tax_id
          icon
          base_price
          discount
          selling_price
          vendor_product {
            id
            product {
              id
              name
              category {
                id
                name
              }
            }
            vendor {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export const get_one_vendor_product_vendor_details = gql`
  query getVendorProductVariant($id: String!) {
    get_one_variant_vendor_details: getVendorProductVariant(id: $id) {
      id
      vendor_product {
        vendor {
          id
          name
          contact_person_name
          contact_number
          owner {
            mobile
            user_name
          }
          vendor_address {
            address
            id
            door_no
            address_line1
            address_line2
            city
            state {
              name
            }
            district {
              name
            }
            country {
              name
            }
          }
        }
        vendor {
          id
          name
        }
      }
    }
  }
`;
