import { Box, HStack, Hidden, Stack, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import FooterLogo1 from "@assets/images/logo1.png";
import FooterLogo2 from "@assets/images/logo2.png";
import FooterLogo3 from "@assets/images/logo3.png";
import FooterLogo4 from "@assets/images/logo4.png";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import Instagram from "@assets/icons/instagram.png";
import Youtube from "@assets/icons/youtube.png";

const Footer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleViewCart = () => {
    history.push(ROUTES.CART);
  };
  const handleTrackMyOrder = () => {
    history.push(ROUTES.TRACK_YOUR_ORDER);
  };
  const handleAbout = () => {
    history.push(ROUTES.ABOUT_US);
  };
  const handleContact = () => {
    history.push(ROUTES.CONTACT_US);
  };
  return (
    <VStack variant={"footer_container"}>
      <HStack
        justifyContent={"flex-start"}
        alignItems={"center"}
        padding="10px"
      >
        <Hidden from={"md"} till={"3xl"}>
          <VStack space={"5px"}>
            <Box padding="10px">
              <img
                src={
                  "https://v-agriosp-assets.blr1.vultrobjects.com/ada4cee4-9565-46ec-bc33-1ce3ee4b4b74governmentlogo.png"
                }
                alt="government_logo"
                class="header-logo"
              />
            </Box>
            <VStack>
              <Text variant={"FooterTitleVariant"}>
                {t("deparment_of_agricultural_marketing")}
              </Text>
              <Text variant={"FooterTitleVariant"}>
                {t("agri_business_online_sales_portal")}
              </Text>

              <Text variant={"FooterTitleText"}>Uzhavan Kart</Text>
            </VStack>
          </VStack>
        </Hidden>
        <Hidden from={"xs"} till={"sm"}>
          <Box padding="10px">
            <img
              src={
                "https://v-agriosp-assets.blr1.vultrobjects.com/ada4cee4-9565-46ec-bc33-1ce3ee4b4b74governmentlogo.png"
              }
              alt="government_logo"
              class="header-logo"
            />
          </Box>
          <VStack>
            <Text variant={"FooterTitleVariant"}>
              {t(
                "deparment_of_agricultural_marketing_&_agri_business_online_sales_portal"
              )}
            </Text>
            <Text variant={"FooterTitleText"}>Uzhavan Kart</Text>
          </VStack>
        </Hidden>
      </HStack>
      <Stack variant={"footer_stack_container"}>
        <VStack space={2.5}>
          <Text variant={"FooterTextVariant"}>{t("address")}</Text>
          <Text width={"35vh"}>{t("address_details")}</Text>
          <Text>
            <b>{t("call")}</b>
            <a className="footer-a-tag" href="tel:1800 900 000">
              1800 900 000
            </a>
          </Text>
          <Text>
            <b>Email:</b> help@agri.com
          </Text>
          <Text>
            <b>{t("hours")}</b> 10:00 - 18:00, Mon-Fri
          </Text>
        </VStack>
        <div class="footer-content" style={{ width: "200px" }}>
          <Text variant={"FooterTextVariant"}>{t("company")}</Text>
          <a onClick={handleAbout}>{t("about_us")}</a>
          {/* <a >{t("delivery_information")}</a> */}
          <a>{t("privacy_policy")}</a>
          <a>{t("terms_conditions")}</a>
          <a onClick={handleContact}>{t("contact_us")}</a>
          {/* <a >{t("support_center")}</a> */}
        </div>
        {/* <div class="footer-content">
          <Text variant={"FooterTextVariant"}>{t("account")}</Text>
          <a >{t("sign_in")}</a>
          <a  onClick={handleViewCart}>{t("view_cart")}</a>
          <a >{t("my_wishlist")}</a>
          <a  onClick={handleTrackMyOrder}>{t("track_my_order")}</a>
          <a >{t("shipping_details")}</a>
          <a >{t("compare_product")}</a>
        </div> */}
        <div class="footer-icon">
          <HStack space={2}>
            <div class={"BsFacebook"}>
              <a
                href="https://www.facebook.com/people/Agrimarket/100084579571351/"
                target={"_blank"}
              >
                <BsFacebook size={27} className="Facebook" />
              </a>
            </div>
            <a
              href="https://www.youtube.com/@departmentofagriculturalma6521"
              target={"_blank"}
            >
              <img src={Youtube} className="Youtube" />
            </a>
            <a
              href="https://www.instagram.com/tnagrimarketing/"
              target={"_blank"}
            >
              <img src={Instagram} className="Instagram" />
            </a>
            <a href="https://twitter.com/tnagrimarketing" target={"_blank"}>
              <AiFillTwitterCircle size={30} className="Twitter" />
            </a>
          </HStack>
        </div>
      </Stack>
      {/* <HStack justifyContent={"spsce-between"}>
        <Box flex={1} justifyContent="center" ml={"10vh"} alignItems="start">
          @2023
        </Box>
        <Box flex={1} alignItems="end" mr={"10vh"}>
          {t("secured_payment_geteways")}
          <HStack space={3} mt={2}>
            <img
              src={FooterLogo4}
              alt="lgo4"
              className="footer-image"
              id="visa-logo"
              style={{height:"20px"}}
            />
            <img src={FooterLogo2} alt="lgo2" className="footer-image" style={{height:"20px"}}/>
            <img src={FooterLogo3} alt="lgo3" className="footer-image" style={{height:"25px"}} />
            <img src={FooterLogo1} alt="lgo1" className="footer-image" style={{height:"30px"}}/>
          </HStack>
        </Box>
      </HStack> */}
    </VStack>
  );
};
export default Footer;
