import { Button, Form } from "antd";
import { Box, HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "../ui/antd_form/antd_form";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  get_all_district_query,
  get_all_state_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { get_customer_details_query } from "@services/redux/slices/dynamic_entity/graphql/check_customer_detail_query";

const ContactDetails = (props) => {
  const { customer_loading ,setMobile} = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const get_customer_details = (values) => {
    let key = [{ key: "checkCustomerDetail", loading: true }];
    let query = get_customer_details_query;
    let variables = { mobile: values.mobile };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    setMobile(values.mobile);
    get_customer_details(values);
  };

  return (
    <Box>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        {/* <VForm.Grid columns={2} spacingX={20}> */}

        <VForm.TextBox
          label={"Mobile"}
          field={["mobile"]}
          rules={[
            {
              required: true,
              message: `mobile number is required`,
            },
            {
              pattern: new RegExp("^[6-9][0-9]{9}$"),
              message: t("error:mobile_not_valid"),
            },
          ]}
        />

        {/* </VForm.Grid> */}
        <HStack variant="address_close_hstack">
          <VForm.Button
            buttonText={t("Submit")}
            isLoading={customer_loading}
            disabled={false}
          ></VForm.Button>
        </HStack>
      </Form>
    </Box>
  );
};

export default ContactDetails;
