import { gql } from "@apollo/client";

export const get_all_state_query = gql`
  query getAllStates($state_name: String, $country_id: String,$page_number: Float, $page_limit: Float ) {
    get_all_state: getAllStates(state_name: $state_name, country_id: $country_id,page_number: $page_number, page_limit: $page_limit) {
        items{
            id
            name
        }
        error{
            status_code
            message
        }
        pagination{
            page_number
            page_limit
            total_count
        }
    }
}`;