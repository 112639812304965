import { gql } from "@apollo/client";

export const get_all_district_query = gql`
  query getAllDistricts($district_name: String, $state_id: String,$page_number: Float, $page_limit: Float ) {
    get_all_district: getAllDistricts(district_name: $district_name, state_id: $state_id,page_number: $page_number, page_limit: $page_limit) {
        items{
            id
            name
        }
        error{
            status_code
            message
        }
        pagination{
            page_number
            page_limit
            total_count
        }
    }
}`;