import { gql } from "@apollo/client";

export const get_customer_details_query = gql`
  query checkCustomerDetail($mobile: String!) {
    checkCustomerDetail(mobile: $mobile) {
      is_customer
      id
    }
  }
`;

export const create_customer_details_mutation = gql`
  mutation createCustomerDetail($data: customer_detail_input) {
    createCustomerDetail(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_customer_views_vendor_detail_mutation = gql`
  mutation createCustomerViewsVendorDetails(
    $data: customer_detail_report_input
  ) {
    createCustomerViewsVendorDetails(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
