import React from "react";
import { Box, ScrollView } from "native-base";
import MyAddressList from "@views/components/profile/Addresses/my_address_list";
const Address = () => {
  return (
    <Box height="500px">
      <ScrollView>
        <MyAddressList />
        </ScrollView>
    </Box>
  );
};
export default Address;
