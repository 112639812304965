import React, { useEffect } from "react";
import { Box, HStack, VStack } from "native-base";
import VForm from "@views/components/ui/antd_form/antd_form";
import { useDispatch } from "react-redux";
import { Button, Form } from "antd";
import { showNotification } from "@helpers/notify";
import { useTranslation } from "react-i18next";
import {
  get_all_state_query,
  get_all_district_query,
  get_all_country_query,
  useDynamicSelector,
  dynamicRequest,
  update_customer_mutation,
  dynamicClear,
  get_all_address_query
} from "@services/redux";
import { useState } from "react";

const MyAddressDetails = (props) => {
  const dispatch = useDispatch();
  const { initialValues } = props;
  const { t } = useTranslation();
  const [filter_state_id, setFilterStateId] = useState();
  const [filter_district_id, setFilterDistrictId] = useState();


  const { items: state_items, loading: state_loading } =
    useDynamicSelector("get_all_state");
  const { items: district_items, loading: district_loading } =
    useDynamicSelector("get_all_district");
  const { items: country_items, loading: country_loading } =
    useDynamicSelector("get_all_country");
  const { status: update_status, loading: update_loading } =
    useDynamicSelector("update_customer");
  const [form] = Form.useForm();
  useEffect(() => {
    let keys = [{ key: "get_all_country", loading: true }];
    dispatch(dynamicRequest(keys, get_all_country_query));
  }, []);
  useEffect(() => {
    let keys = [{ key: "get_all_state", loading: true }];
    dispatch(dynamicRequest(keys, get_all_state_query));
  }, []);
  useEffect(() => {
    let keys = [{ key: "get_all_district", loading: true }];
    dispatch(dynamicRequest(keys, get_all_district_query));
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (update_status === "success" && !initialValues?.id) {
      showNotification({
        type: "success",
        message: `${t("address_create_message")}`,
      });
      dispatch(dynamicClear("update_customer"));
      let keys = [{ key: "get_all_address", loading: true }];
      dispatch(dynamicRequest(keys, get_all_address_query));
      form.resetFields();
      props.close();
    } else if (update_status === "failure" && !initialValues?.id) {
      showNotification({
        type: "failure",
        message: `${t("address_create_failure_message")}`,
      });
      dispatch(dynamicClear("update_customer"));
    }
  }, [update_status]);


  const onStateChange = (value) => {
    setFilterStateId(value);
    if (value) {
      let keys = [{ key: "get_all_district", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_district_query, {
          state_id: value,
        })
      );
    }
  };

  const handleSubmit = (data) => {
    data = { ...data, id: initialValues?.id};

    let variables = { data: { customer_address: data } };
    let keys = [{ key: "update_customer", loading: true }];
    dispatch(dynamicRequest(keys, update_customer_mutation, variables, "M"));
  };

  

  return (
    <Box variant="address_details_box">
      <VStack variant="address_details_vstack">
        <Form
          name={"VENDOR_PRODUCT_VARIANT_DETAILS"}
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
        >
          <VForm.Grid columns={2} spacingX={20}>
            {/* <VForm.Select
              label={t("form:title")}
              options={[
                {
                  id: "Mr",
                  label: "Mr",
                },
                {
                  id: "Mrs",
                  label: "Mrs",
                },
                {
                  id: "Ms",
                  label: "Ms",
                },
              ]}
              rules={[
                {
                  required: true,
                  message: `${t("form:enter_title")}`,
                },
              ]}
              field={"t"}
            /> */}
            <VForm.TextBox
              label={t("form:name")}
              rules={[
                {
                  required: true,
                  message: t("form:Enter_name"),
                },
              ]}
              field={"contact_person"}
            />

            <VForm.TextBox
              label={t("form:address_title")}
              rules={[
                {
                  required: true,
                  message: t("form:enter_address_title"),
                },
              ]}
              field={"title"}
            />
            <VForm.TextBox
              label={t("form:contact_number")}
              rules={[
                {
                  required: true,
                  message: t("form:Enter_your_contact_number"),
                },
                {
                  pattern: new RegExp("^[6-9][0-9]{9}$"),
                  message: `${t("form:enter_valid_contact_number")}`,
                },
              ]}
              field={"mobile_no"}
            />

            <VForm.TextBox
              label={t("form:door_no")}
              field={["door_no"]}
              rules={[
                {
                  required: true,
                  message: `${t("form:Enter_your_door_no")}`,
                },
              ]}
            />
            <VForm.TextBox
              label={t("form:address1")}
              field={["address_line1"]}
              rules={[
                {
                  required: true,
                  message: `${t("form:enter_your_address")}`,
                },
              ]}
            />
            <VForm.TextBox
              label={t("form:address2")}
              field={["address_line2"]}
            />
            {/* <VForm.Select
              loading={country_loading}
              label={t("form:country")}
              field={"country_id"}
              onChange={onCountryChange}
              options={country_items}
              rules={[
                {
                  required: true,
                  message: `${t("form:enter_your_country")}`,
                },
              ]}
            /> */}
            <VForm.Select
              loading={state_loading}
              value={filter_state_id}
              label={t("form:state")}
              options={state_items}
              onChange={onStateChange}
              allowClear={true}
              field={"state_id"}
              rules={[
                {
                  required: true,
                  message: `${t("form:enter_your_state")}`,
                },
              ]}
            />
            <VForm.Select
              value={filter_state_id}
              loading={district_loading}
              label={t("form:district")}
              field={"district_id"}
              options={district_items}
              allowClear={true}
              rules={[
                {
                  required: true,
                  message: `${t("form:enter_your_district")}`,
                },
              ]}
            />
            <VForm.TextBox
              label={t("form:other_state")}
              field={["other_state"]}
            />
            <VForm.TextBox
              label={t("form:other_district")}
              field={["other_district"]}
            />
            <VForm.TextBox
              label={t("form:city")}
              field={["city"]}
              rules={[
                {
                  required: true,
                  message: `${t("form:enter_your_city")}`,
                },
              ]}
            />
            <VForm.TextBox
              label={t("form:zip_code")}
              field={["zipcode"]}
              rules={[
                {
                  required: true,
                  message: `${t("form:enter_your_pin")}`,
                },
              ]}
            />
            
          </VForm.Grid>
          <HStack variant="address_close_hstack">
            <Button onClick={props.close}>{t("close")}</Button>

            <VForm.Button
              buttonText={props.label === "Add" ? t("Submit") : t("Update")}
              isLoading={update_loading}
              disabled={false}
            ></VForm.Button>
          </HStack>
        </Form>
      </VStack>
    </Box>
  );
};

export default MyAddressDetails;
