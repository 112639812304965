import "../../../../src/index.css";

import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Image,
  Typography,
  Tooltip,
  Spin,
  Pagination,
} from "antd";
import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import {
  dynamicRequest,
  get_search_records_query,
  useDynamicSelector,
  create_customer_cart_mutation,
  create_customer_wishlist_mutation,
  dynamicClear,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import Rating from "@views/components/products_list/rating";
import { BsCart3 } from "react-icons/bs";
import { AiOutlineHeart, AiOutlineHome } from "react-icons/ai";
import { ROUTES } from "@views/routes/my_routes";
import { showNotification } from "@helpers/notify";
import { retrieveItem, storeItem } from "@helpers/storage";
import { useTranslation } from "react-i18next";
import { RxSlash } from "react-icons/rx";
import ProductsCard from "../home_page_product/product_card";

const GlobalSearch = () => {
  const { t } = useTranslation();
  const add_cart = retrieveItem("cart_list");
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(10);
  const token = retrieveItem("token");
  let cart_list = [];
  if (add_cart) {
    cart_list = [...add_cart];
  }

  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [active_id, setActiveId] = useState("");

  const { items, loading, pagination } =
    useDynamicSelector("get_search_records");
  const { status: cart_status, loading: cart_loading } = useDynamicSelector(
    "create_customer_cart"
  );
  const { status: wishlist_status, error: wishlist_error } = useDynamicSelector(
    "create_customer_wishlist"
  );
  useEffect(() => {
    let keys = [{ key: "get_search_records", loading: true }];
    let variables = {
      search_string: state,
      page_number: current,
      page_limit: page,
    };
    dispatch(dynamicRequest(keys, get_search_records_query, variables));
  }, [state]);
  console.log("items", items);
  useEffect(() => {
    if (cart_status == "success") {
      showNotification({
        type: "success",
        message: `${t("cart_create_message")}`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    } else if (cart_status === "failure") {
      showNotification({
        type: "failure",
        message: `${t("cart_create_failure_message")}`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    }
  }, [cart_status]);

  useEffect(() => {
    if (wishlist_status == "success") {
      showNotification({
        type: "success",
        message: `${t("wishlist_create_message")}`,
      });
      history.push(ROUTES.WISHLIST);
      dispatch(dynamicClear("create_customer_wishlist"));
    } else if (
      wishlist_status === "failure" &&
      wishlist_error?.message === "Product already added to wishlist"
    ) {
      showNotification({
        type: "failure",
        message: wishlist_error.message,
      });
      dispatch(dynamicClear("create_customer_wishlist"));
    } else if (wishlist_status === "failure") {
      showNotification({
        type: "failure",
        message: `${t("wishlist_create_failure_message")}`,
      });
      dispatch(dynamicClear("create_customer_wishlist"));
    }
  }, [wishlist_status]);

  const mouseOver = (e) => {
    e.target.style.color = "#22C55E";
  };

  const MouseOut = (e) => {
    e.target.style.color = "black";
  };

  const handleTextPress = (id) => {
    history.push(`${ROUTES.VENDOR_PRODUCT_LIST}/${id}`);
  };

  const handleProduct = (id, name) => {
    history.push({
      pathname: `${ROUTES.PRODUCT_DETAILS}/${id}`,
      state: {
        name: name,
      },
    });
  };

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_search_records", loading: true }];
    let variables = {
      search_string: state,
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, get_search_records_query, variables, "M"));
    setCurrent(page_number);
    setPage(page_limit);
  };

  const handleWishList = (id) => {
    if (token != null) {
      let keys = [{ key: "create_customer_wishlist", loading: true }];
      let variables = { data: { vendor_product_variant_id: id } };
      dispatch(
        dynamicRequest(keys, create_customer_wishlist_mutation, variables, "M")
      );
    } else {
      showNotification({
        type: "failure",
        message: `${t("login_to_wishlist_message)")}`,
      });
    }
  };

  const handleHome = () => {
    history.push({ pathname: ROUTES.USER_HOME });
  };

  const handleAddCart = (id, image, name, price, quantity) => {
    if (token != null) {
      let keys = [{ key: "create_customer_cart", loading: true }];
      let variables = {
        data: { product_count: 1, vendor_product_variant_id: id },
      };
      dispatch(
        dynamicRequest(keys, create_customer_cart_mutation, variables, "M")
      );
    } else {
      let cart_object = {};
      let index = cart_list.findIndex((obj) => obj.id === id);
      if (index === -1) {
        cart_object["quantity"] = 1;
        cart_object["id"] = id;
        cart_object["image"] = image;
        cart_object["name"] = name;
        cart_object["price"] = price;
        cart_list.push(cart_object);
      } else {
        cart_list[index].price += price;
        cart_list[index].quantity += 1;
      }

      storeItem("cart_list", cart_list);
      showNotification({
        type: "success",
        message: `${t("cart_create_message")}`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    }
  };

  return (
    <Box>
      {loading && (
        <Spin size="medium">
          <div className="content" />
        </Spin>
      )}
      {!loading &&
        (items?.length == 0 || items == null ? (
          <Box variant="NotFound">
            <Center>
              <VStack variant="not_found_vstack">
                <Image
                  className="not_found_img"
                  preview={false}
                  alt={t("not_found")}
                  src={
                    "https://v-agriosp-assets.blr1.vultrobjects.com/b60d47d7-c140-429e-9661-974c9cd1ff36.png"
                  }
                ></Image>
                <Text bold variant="not_found_text">
                  {state} product is not available
                </Text>
              </VStack>
            </Center>
          </Box>
        ) : (
          <>
            <HStack variant="search_breadcrumb_hstack_1">
              <Pressable onPress={handleHome} _hover={{ color: "green.800" }}>
                <Text variant="search_breadcrumb_text">
                  <AiOutlineHome />
                  {t("Home")}
                </Text>
              </Pressable>
              <RxSlash />
              <Text variant="search_breadcrumb_state_text">{state}</Text>
            </HStack>
            {/* <HStack variant="search_card_hstack"> */}
            <div className="related-product-container">
              {items?.map((item, index) => (
                <div className="product-card-list" key={item?.id}>
                  <ProductsCard
                    id={item?.id}
                    icon={item?.icon}
                    stock_count={item?.stock_count}
                    discount={item?.discount}
                    category={item?.vendor_product?.product?.category?.name}
                    product_name={item?.vendor_product?.product?.name}
                    variant_name={item?.name}
                    base_price={item?.base_price}
                    selling_price={item?.selling_price}
                    rating={item?.average_rating}
                    vendor_name={item?.vendor_product?.vendor?.name}
                    vendor_id={item?.vendor_product?.vendor?.id}
                    stock={item?.value}
                    unit={item?.unit}
                  />
                </div>
              ))}
            </div>
            {/* </HStack> */}
          </>
        ))}
    </Box>
  );
};

export default GlobalSearch;
