const { gql } = require("@apollo/client");

export const get_all_addresses = gql`
  query getAllCustomerAddress {
    get_all_addresses: getAllAddresses {
      items {
        id
        door_no
        mobile_no
        name
        address_line1
        address_line2
        title
        city
        state_id
        other_state
        zipcode
        other_district
        district_id
        country_id
        is_primary
        created_at
        created_at
        is_deleted
        error {
          status_code
          message
        }
      }
    }
  }
`;
