import { gql } from "@apollo/client";

export const get_all_home_screen_products_query = gql`
  query getAllHomeScreenProducts($page_number: Float, $page_limit: Float) {
    get_all_home_screen_products: getAllHomeScreenProducts(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        vendor_product_variant_id
        vendor_product_variant {
          id
          name
          description
          icon
          vendor_product_id
          product_tax_id
          stock_keeping_unit
          base_price
          stock_count
          value
          selling_price
          description
          icon
          time_to_ship
          discount
          product_tax_id
          vendor_product_id
          unit
          vendor_product {
            id
            vendor_id
            product_id
            icon
            banner_image
            gallery
            vendor {
              id
              name
              contact_person_name
              description
              logo
            }
            product {
              id
              name
              category {
                id
                icon
                name
                description
                sub_categories {
                  id
                  name
                  icon
                  description
                }
              }
            }
          }
          average_rating
          product_rating {
            id
            review
            rating
          }
        }
        sort_order
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
