import { gql } from "@apollo/client";

export const delete_address_mutation = gql`
  mutation deleteAddress($id: String!) {
    delete_address: deleteAddress(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;


export const update_address_mutation = gql`
  mutation updateCustomer($id: String!, $data: address_input) {
    update_address: updateAddress(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;


export const update_customer_mutation = gql`
  mutation updateCustomer($data: customer_input) {
    update_customer: updateCustomer(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_address_query = gql`
  query getAllAddresses($page_number : Float, $page_limit: Float) {
    get_all_address: getAllAddresses(page_number: $page_number, page_limit: $page_limit) {
      items{
        id
        contact_person
        door_no
        mobile_no
        address_line1
        address_line2
        title
        city
        state{
          id
          name
          country_id
        }
        district{
          id
          name
          zipcodes
          state_id
        }
        country{
          id
          name
        }
        other_state
        other_district
        zipcode
        error{
            status_code
            message
        }
      }
      pagination{
        page_number
        page_limit
        total_count
      }
      error{
        status_code
        message
      }
    }
  }
  `;

  export const get_one_address_query = gql`
  query getAddress($id :String!) {
    get_one_address: getAddress(id: $id) {
        id
        door_no
        mobile_no
        address_line1
        address_line2
        title
        city
        state
        district
        country
        other_state
        other_district
        zipcode
        error{
            status_code
            message
        }
    }
  }
  `;