import React, { useEffect } from "react";
import ReactImageMagnify from "react-image-magnify";
const ImageMagnifier = ({ src }) => {
  return (
    <div className="image_set">
      <ReactImageMagnify
        {...{
          smallImage: {
            alt: "Wristwatch by Ted Baker London",
            isFluidWidth: true,
            src: src,
          },
          largeImage: {
            src: src,
            width: 1200,
            height: 1800,
          },
        }}
      />
    </div>
  );
};

export default ImageMagnifier;
