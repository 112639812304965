import React from "react";
import { Box, HStack, VStack, Text, Center, Divider, Image } from "native-base";
import { Slider, Button, Space, Col, Row, Card, Spin } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import NoPicture from "@assets/images/no-pictures.png";

export const SubCategoryCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  console.log("propspropsprops", props);

  const handleClick = (category_id, id, name) => {
    if (!props?.has_sub) {
      history.push({
        pathname: `${ROUTES.PRODUCT}/${category_id}`,
        state: {
          name: props.name,
          category: {
            id: props?.category?.id,
            name: props?.category?.name,
          },
        },
      });
    } else {
      history.push({
        pathname: `${ROUTES.SUB_CATEGORY}/${category_id}`,
        state: { name: props.name },
      });
    }
  };

  return (
    <>
      <Box paddingBottom={10}>
        <Card
          hoverable
          type="inner"
          bordered={false}
          style={{ borderRadius: "10px", textAlign: "center" }}
          bodyStyle={{ padding: "0px" }}
          onClick={() => handleClick(props.id, props.name)}
        >
          <Box
            borderRadius="10px"
            width={["0px", "240px", "230px", "25vw", "18vw", "260px", "270px"]}
            height={[
              "0px",
              "150px",
              "150px",
              "160px",
              "160px",
              "160px",
              "170px",
            ]}
            backgroundColor={props.bg}
            px="4"
            pt="4"
            justifyContent={"space-between"}
            padding={"6px"}
          >
            <Box alignItems={"start"} justifyContent={"start"}>
              <Text
                fontWeight={600}
                fontSize={["0px", "15px", "25px"]}
                // marginTop="15px"
              >
                {props.name}
              </Text>
            </Box>
            <Box alignItems={"end"}>
              {props.icon != null ? (
                <img
                  src={props?.icon}
                  alt="category_icon"
                  style={{ width: "50%" }}
                />
              ) : (
                <img
                  src={NoPicture}
                  alt="category_icon"
                  style={{ width: "40%" }}
                />
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  );
};
