import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  Link,
  Divider,
  Hidden,
} from "native-base";
import { Slider, Button, Space, Col, Row, Card, Spin } from "antd";
// import { CategoryCard } from "./category_card";
import {
  get_all_categories_query,
  dynamicRequest,
  dispatch,
  useDynamicSelector,
  get_all_vendor_product_variants,
  get_all_home_screen_products_query,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "node_modules/swiper/swiper.scss";
import "node_modules/swiper/modules/navigation/navigation.scss";
import "node_modules/swiper/modules/pagination/pagination.scss";
import "node_modules/swiper/modules/scrollbar/scrollbar.scss";
import "../../../index.css";

// import "node_modules/swiper/swiper.min.css";
// import "swiper/.css";
import SwiperCore, {
  Autoplay,
  Keyboard,
  Pagination,
  Scrollbar,
  Zoom,
  A11y,
  Navigation,
} from "swiper";
import { FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import { RightCircleFilled, LeftCircleFilled } from "@ant-design/icons";
import "@src/style.css";
import ProductsCard from "./product_card";
import { MdOutlineArrowForwardIos } from "react-icons/md";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export const ProductBox = () => {
  const [swiper_end, setSwiperEnd] = useState(false);
  const dispatch = useDispatch();
  const { items: all_products, loading: loading } = useDynamicSelector(
    "get_all_home_screen_products"
  );
  const getAllProducts = () => {
    let keys = [{ key: "get_all_home_screen_products", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_home_screen_products_query, {
        page_number: 1,
        page_limit: 100000000,
      })
    );
  };

  useEffect(() => {
    getAllProducts();
  }, []);
  const ProductHead = () => {
    return (
      <>
        {/* <Hidden from="xs" till="md"> */}
        <HStack justifyContent={"space-between"} marginBottom={"40px"}>
          <Box>
            <Text
              bold
              fontSize="30px"
              color="#3bb77e"
              marginLeft={["", , "50px"]}
            >
              Products
            </Text>
          </Box>
          {/* {swiper_end ==   ?
              <div>
                view more
              </div> : */}
          {/* <HStack paddingRight={{ base: 0, lg: 10 }} space={10}>
            <Button
              className="custom_prev"
              type="link"
              icon={<LeftCircleFilled className="LeftCircleFilled" />}
              size={"large"}
            />
            <Button
              className="custom_next"
              type="link"
              icon={<RightCircleFilled className="LeftCircleFilled" />}
              size={"large"}
            />
          </HStack> */}
          {/* } */}
        </HStack>
        {/* </Hidden> */}
      </>
    );
  };

  const ProductBody = () => {
    return (
      <Box paddingX={10}>
        {loading && (
          <Spin size="medium">
            <div className="content" />
          </Spin>
        )}
        {/* <Swiper
          modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom]}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
            1440: {
              slidesPerView: 5,
            },
            1500: {
              slidesPerView: 6,
            },
            2560: {
              slidesPerView: 7,
            },
          }}
          // autoplay={true}
          disableOnInteraction={true}
          navigation={{
            nextEl: ".custom_next",
            prevEl: ".custom_prev",
          }}
          loop={true}
          spaceBetween={130}
          slidesPerView={1}
          className="mySwiper"
          allowSlideNext={true}
          // onEnded={(swiper) => {setSwiperEnd(swiper)}}
        >*/}
        <div className="vendorproduct-gird">
          {all_products?.map((item) => (
            // <SwiperSlide>
            <ProductsCard
              id={item?.vendor_product_variant?.id}
              icon={item?.vendor_product_variant?.icon}
              stock_count={item?.vendor_product_variant?.stock_count}
              discount={item?.vendor_product_variant?.discount}
              category={
                item?.vendor_product_variant?.vendor_product?.product?.category
                  ?.name
              }
              product_name={
                item.vendor_product_variant?.vendor_product?.product?.name
              }
              variant_name={item?.vendor_product_variant?.name}
              base_price={item?.vendor_product_variant?.base_price}
              selling_price={item?.vendor_product_variant?.selling_price}
              rating={item?.vendor_product_variant?.average_rating}
              vendor_name={
                item?.vendor_product_variant?.vendor_product?.vendor?.name
              }
              vendor_id={
                item?.vendor_product_variant?.vendor_product?.vendor?.id
              }
              stock={item?.vendor_product_variant?.value}
              unit={item?.vendor_product_variant?.unit}
            />
          ))}
        </div>
        {/* </SwiperSlide> */}
        {/* </Swiper> */}
        {/* <Box position={"absolute"} zIndex={"1"}>
            <MdOutlineArrowForwardIos/>
          </Box> */}
      </Box>
    );
  };
  return (
    <>
      <VStack>
        <ProductHead />
        <ProductBody />
      </VStack>
    </>
  );
};
