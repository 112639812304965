import { Box, HStack, Hidden, Pressable, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuBar from "./menu_bar";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { MdOutlineVibration } from "react-icons/md";
import UzhavanKartLogo from "@assets/images/uzhavan_kart_logo.png";
import HundredLogo from "@assets/icons/100_.png";
const HeaderContents = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleHome = () => {
    history.push(ROUTES.USER_HOME);
  };
  const handleAbout = () => {
    history.push(ROUTES.ABOUT_US);
  };
  const handleContact = () => {
    history.push(ROUTES.CONTACT_US);
  };
  const handleVendor = () => {
    history.push(ROUTES.VENDOR_LIST);
  };
  return (
    <VStack flex={1}>
      {/* <HStack variant="header_content_vstack">
        <Box variant="headerTitlesOne"> */}
      {/* <Pressable onPress={handleHome}><Text color={"white"}>{t("home")}</Text></Pressable>| */}
      {/* <Pressable onPress={handleAbout}>
            <Text variant={"header_titles_one_text"}>{t("about_us")}</Text>
          </Pressable>
          |
          <Pressable onPress={handleContact}>
            <Text variant={"header_titles_one_text"}>{t("contact_us")}</Text>
          </Pressable>
          |
          <Pressable onPress={handleVendor}>
            <Text variant={"header_titles_one_text"}>{t("FPO")}</Text>
          </Pressable>
          | */}
      {/* <Pressable onPress={handleVendor}><Text variant={"header_titles_one_text"}>{t("FPO Login")}</Text></Pressable>| */}
      {/* <Pressable onPress={handleVendor}><Text variant={"header_titles_one_text"}>{t("Admin Login")}</Text></Pressable>| */}
      {/* <Pressable onPress={handleContact}>
            <MdOutlineVibration color="white" size={18} cursor="pointer" />
          </Pressable>
        </Box>
        <Box variant="headerTitlesTwo">
          {t("need_help")}
          <a href="tel:1800 900 000" className="tell-link">
            1800 900 000
          </a>
        </Box>
      </HStack> */}
      <Hidden from="md" till="3xl">
        <HStack variant="header_content_vstack_image1" justifyContent={"start"}>
          <HStack space={"10px"} alignItems={"center"} alignSelf={"start"}>
            <Box>
              <Pressable onPress={handleHome}>
                <img
                  src={UzhavanKartLogo}
                  alt="government_logo"
                  width={"100px"}
                />
              </Pressable>
            </Box>
            <VStack space="5px">
              <Text variant={"menu_bar_title_text"}>Uzhavan Kart</Text>
              {/* <VStack>
                <Text variant={"menu_bar_title_text_two"}>
                  Department of Agricultural
                </Text>
                <Text variant={"menu_bar_title_text_two"}>
                  Marketing & Agri Business
                </Text>
              </VStack> */}
            </VStack>
          </HStack>
        </HStack>
      </Hidden>
      <Hidden from="xs" till="sm">
        <HStack
          style={{
            backgroundColor: "#5dc56a",
            height: "80%",
            alignItems: "center",
          }}
          width={"100%"}
          //  variant="header_content_vstack_image"
        >
          <Box alignItems={"flex-start"} width={"70%"}>
            <HStack space={"3px"} alignItems={"center"}>
              <Box>
                <Pressable onPress={handleHome}>
                  <img
                    src={UzhavanKartLogo}
                    alt="government_logo"
                    width={"110px"}
                  />
                </Pressable>
              </Box>
              <VStack space="5px">
                <Text variant={"menu_bar_title_text"}>Uzhavan Kart</Text>

                {/* <Text variant={"menu_bar_title_text_two"}>
                  Department of Agricultural Marketing & Agri Business
                </Text> */}
              </VStack>
            </HStack>
          </Box>
          <Box width={"30%"}>
            <HStack
              space="20px"
              alignItems={"center"}
              justifyContent={"flex-end"}
              pr={"20px"}
            >
              <Box variant="header_content_vstack_image_box">
                <Pressable onPress={handleHome}>
                  <img
                    src="https://v-agriosp-assets.blr1.vultrobjects.com/ada4cee4-9565-46ec-bc33-1ce3ee4b4b74governmentlogo.png"
                    alt="government_logo"
                    className="government_logo"
                  />
                </Pressable>
              </Box>
              <Box>
                <img
                  src={HundredLogo}
                  alt="logo"
                  style={{
                    width: "85px",
                    objectFit: "contain",
                    // transform: "scale(2.4)",
                  }}
                />
              </Box>
              <Box>
                <img
                  src="https://v-agrigl-assets.blr1.vultrobjects.com/international-day-of-millets-logo.png"
                  alt="logo"
                  style={{ width: "85px" }}
                />
              </Box>
              <Box>
                <img
                  src="https://v-agrigl-assets.blr1.vultrobjects.com/G20_India_Logo.png"
                  alt="logo"
                  style={{ width: "85px" }}
                />
              </Box>
            </HStack>
          </Box>
        </HStack>
      </Hidden>
    </VStack>
  );
};

export default HeaderContents;
