import LoginComponent from "@views/components/auth/login";
import { Box, Pressable, Center } from "native-base";
import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { navigate } from "@helpers/navigator";
import { useCheckLogin } from "@helpers/auth";
const Login = (props) => {
  const is_logged_in = useCheckLogin();
  useEffect(() => {
    if (is_logged_in === true) {
      navigate("/home");
    }
  }, [is_logged_in]);

  return (
    <Box flex={1}>
      <LoginComponent />
    </Box>
  );
};
export default Login;
