import React from 'react'
import { FilterBox } from '../home_page_category/filter_box'
import { useLocation } from 'react-router-dom';
import { dynamicRequest, get_all_vendor_product_variants, useDynamicSelector } from '@services/redux';
import { useDispatch } from 'react-redux';
import { Box, HStack, Text } from 'native-base';
import ProductsFilterCard from './product_card';
const FilterPage = () => {

  const location = useLocation();
  const { items: get_all_variants } = useDynamicSelector("get_all_variants")
  return (
    <div className="filter-page">
      <HStack variant={"vendor_code_tag_hstack"}>
        <Text variant={"vendor_code_tag_hstack_text"}>
          We found <span
            style={{
              color: "green",
              fontWeight: "bold",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >{get_all_variants?.length} </span>items for you!
        </Text>
        <Text flex={1}>
          {/* We found {get_all_variants?.length} items for you! */}
        </Text>
      </HStack>
      <HStack width={"100%"}>
        <div className="filterBox">
          <FilterBox />
        </div>
        <Box variant={"filter_product_card_box"}>
          <ProductsFilterCard list={location?.state?.price_list} />
        </Box>
      </HStack>
    </div>
  )
}
export default FilterPage