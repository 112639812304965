import React, { useEffect, useState, ReactSelect } from "react";
import { Button, Card, Col, Image, Row, Space, Spin, Typography } from "antd";
import Sunoil from "@assets/images/sunoil.png";
import Rating from "../products_list/rating";
import {
  AspectRatio,
  Box,
  Center,
  Hidden,
  HStack,
  Pressable,
  Text,
  Tooltip,
  VStack,
} from "native-base";
import {
  get_all_products_query,
  dynamicRequest,
  dispatch,
  useDynamicSelector,
  get_all_vendor_product_variants,
  get_all_vendor_list,
  create_customer_cart_mutation,
  dynamicClear,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { BsCart3 } from "react-icons/bs";
import "../../../index.css";
import { AiOutlineHeart } from "react-icons/ai";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { showNotification } from "@helpers/notify";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ProductsFilterCard = (props) => {
  const dispatch = useDispatch();
  const { items: all_products, loading: loading } =
    useDynamicSelector("get_all_variants");
  const { items: vendors, loading: vendor_loading } =
    useDynamicSelector("get_all_vendors");
  const history = useHistory();
  const { t } = useTranslation();

  // const { category_id } = useParams();

  const { state } = useLocation();

  const { list } = props;
  const { loading: create_loading, status: cart_status } = useDynamicSelector(
    "create_customer_cart"
  );
  const { rating, review } = useDynamicSelector("get_product_rating");

  useEffect(() => {
    // getAllProducts();
    getAllVendors();
  }, []);

  useEffect(() => {
    getAllProductsPriceByList();
  }, [list]);
  useEffect(() => {
    if (cart_status == "success") {
      showNotification({
        type: "success",
        message: `Product added to cart successfully`,
      });
      history.push(ROUTES.CART);
      dispatch(dynamicClear("create_customer_cart"));
    } else if (cart_status === "failure") {
      showNotification({
        type: "failure",
        message: `Failed to add an item to cart`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    }
  }, [cart_status]);

  const getAllProductsPriceByList = () => {
    let keys = [{ key: "get_all_variants", loading: true }];

    let variable = {
      from_price: list ? list[0] : null,
      to_price: list ? list[1] : null,
      page_number: 1,
      page_limit: 10,
    };

    dispatch(dynamicRequest(keys, get_all_vendor_product_variants, variable));
  };

  const getAllVendors = () => {
    let keys = [{ key: "get_all_vendors", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_vendor_list, {
        page_number: 1,
        page_limit: 10,
      })
    );
  };

  const handleClick = (product_id) => {
    history.push({
      pathname: `${ROUTES.PRODUCT_DETAILS}/${product_id}`,
      state: { name: state?.name },
    });
  };

  const handleVendorPage = (id) => {
    history.push({ pathname: `${ROUTES.VENDOR_PRODUCT_LIST}/${id}` });
  };

  const handleAddCart = (id) => {
    let keys = [{ key: "create_customer_cart", loading: true }];
    let variables = {
      data: {
        customer_id: "e01b667c-02b0-4de8-806c-2b8028c915ec",
        product_count: 1,
        vendor_product_variant_id: id,
      },
    };
    dispatch(
      dynamicRequest(keys, create_customer_cart_mutation, variables, "M")
    );
  };

  return (
    <Box height={"100vh"} width={"100%"} flex={1}>
      <Text
        bold
        fontSize="35px"
        color="#3bb77e"
        marginTop={"10px"}
        marginLeft={["", , "70px"]}
      >
        {state?.name}
      </Text>
      {loading && (
        <Spin size="medium">
          <div className="content" />
        </Spin>
      )}
      {!loading &&
        (all_products?.length == 0 || all_products == null ? (
          <Box variant="no_products_found">
            <Center variant="NotFount">
              <VStack alignItems={"center"}>
                <Text variant="no_products_found">{t("no_products")} ...</Text>
              </VStack>
            </Center>
          </Box>
        ) : (
          <HStack marginLeft={10} space={5} flexWrap={"wrap"}>
            {all_products?.map((item, index) => (
              <VStack margin={"20px"} space={20} flexWrap={"wrap"}>
                <Card
                  hoverable
                  style={{
                    height: 400,
                    width: 250,
                    borderRadius: "10px",
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                >
                  <Pressable onPress={() => handleClick(item.id)}>
                    {item?.stock_count > 0 ? (
                      <Box variant="Vendor_product_variant_box">
                        <img
                          class="product-image"
                          alt={item?.name}
                          src={item?.icon}
                        />
                      </Box>
                    ) : (
                      <>
                        <Box variant="outofstack_box" height={"90%"}>
                          <Text variant={"outofstack_box_text"}>
                            Out of Stock
                          </Text>
                        </Box>
                        <Box variant="out_of_stock_image_box">
                          <img
                            className="product-image unstacked"
                            alt={item?.name}
                            src={item?.icon}
                          />
                        </Box>
                      </>
                    )}
                  </Pressable>
                  <Box variant="product_card_alignment">
                    <Text variant="bold_text"> </Text>
                    <VStack variant="product_card_details">
                      <Box variant="product_card_height">
                        <Text variant="product_name" color={"#adadad"}>
                          {item?.vendor_product?.product?.category?.name} /{" "}
                          {item?.vendor_product?.product?.name}
                        </Text>
                        <Text bold>{item.name} </Text>
                        <Rating count={item?.average_rating} />
                        <Typography.Link
                          onClick={() =>
                            handleVendorPage(item?.vendor_product?.vendor?.id)
                          }
                        >
                          <Text>
                            {t("by")}{" "}
                            <Text
                              style={{ color: "#22c55e", marginLeft: "10px" }}
                            >
                              {item?.vendor_product?.vendor?.name}
                            </Text>
                          </Text>
                        </Typography.Link>
                      </Box>
                      <HStack space={2} width={"max-content"}>
                        <Text variant="vendor_name">
                          ₹ {item.selling_price.toFixed(2)}
                        </Text>
                        {item?.discount != 0 ? (
                          <>
                            /
                            <Text strikeThrough variant="vendor_name">
                              ₹ {item.original_price.toFixed(2)}
                            </Text>
                          </>
                        ) : (
                          ""
                        )}
                        <Button
                          disabled={item?.stock_count <= 0 ? true : false}
                          loading={create_loading}
                          className="cart-btn"
                          icon={<BsCart3 />}
                          onClick={() => handleAddCart(item?.id)}
                        >
                          {t("add")}
                        </Button>
                      </HStack>
                    </VStack>
                  </Box>
                </Card>
              </VStack>
            ))}
          </HStack>
        ))}
    </Box>
  );
};

export default ProductsFilterCard;
