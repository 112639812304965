import { Box, Pressable, VStack } from "native-base";
import React from "react";

const ProductImages = (props) => {
  const { set_image, gallery } = props;
  const handleChange = (image) => {
    set_image(image);
  };
  return (
    <div>
      <VStack>
        <Box>
          {gallery?.map((img, index) => {
            return (
              <Pressable
                onPress={() => {
                  handleChange(img);
                }}
                key={index}
              >
                <Box
                  width={"80px"}
                  mb={"10px"}
                  height={""}
                  padding={"10px"}
                  style={{ border: "1px solid black", borderRadius: "10px" }}
                >
                  <img
                    src={img}
                    style={{
                      objectFit: "contain",
                      height: "80px",
                    }}
                  />
                </Box>
              </Pressable>
            );
          })}
        </Box>
      </VStack>
    </div>
  );
};

export default ProductImages;
