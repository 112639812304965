import ProductDetails from "@views/components/products_list/product_details";
import { Box, ScrollView } from "native-base";
import React, { useEffect } from "react";
const ProductDetailsPage = () => {

  return (
    <Box flex={1}>
      <ProductDetails />
    </Box>
  );
};

export default ProductDetailsPage;
