import {
  dynamicRequest,
  get_vendor_products,
  useDynamicSelector,
} from "@services/redux";
import { Button, Card, Skeleton, Spin } from "antd";
import {
  Box,
  Center,
  HStack,
  Pressable,
  Stack,
  Text,
  VStack,
} from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineHome } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { RxSlash } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { IoLocationSharp } from "react-icons/io5";
import VendorProductDetails from "./vendor_product_details";
import { ROUTES } from "@views/routes/my_routes";
import { BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import Instagram from "@assets/icons/instagram.png";

const VendorProductList = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const vendor_id = id;
  const handleHome = () => {
    history.push({ pathname: ROUTES.USER_HOME });
  };
  const handleBack = () => {
    // history.goBack();
    history.push(ROUTES.VENDOR_LIST);
  };
  const {
    name,
    contact_number,
    contact_person_name,
    agency_name,
    ceo_name,
    ceo_contact_number,
    facebook_url,
    instagram_url,
    twitter_url,
    logo,
    vendor_address,
    vendor_products,
    website,
    loading,
    owner,
    name_of_commodities,
  } = useDynamicSelector("get_vendor");
  console.log("get_vendor,", contact_number);
  const getOneVendorProducts = () => {
    let keys = [{ key: "get_vendor", loading: true }];
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(keys, get_vendor_products, variables));
  };
  useEffect(() => {
    getOneVendorProducts();
  }, []);
  if (loading)
    return (
      <Center h="500px">
        <Box size="45px">
          <Spin size="default"></Spin>
        </Box>
      </Center>
    );
  return (
    <>
      <Box pl={"18px"} height={"70px"} justifyContent={"center"}>
        <HStack space={3} alignItems="center">
          <Pressable onPress={handleHome}>
            <Text variant={"tag_text_home"}>
              <AiOutlineHome />
              {t("Home")}
            </Text>
          </Pressable>
          <RxSlash size={15} />
          <Pressable onPress={handleBack}>
            <Text variant={"tag_text_unhide"}>{t("Vendors_List")}</Text>
          </Pressable>
          <RxSlash size={15} />
          <Text variant={"tag_text_hide"}>{name}</Text>
        </HStack>
      </Box>
      <HStack paddingX="10%">
        <Card
          style={{
            width: "100%",
            backgroundColor: "#ffd55d40",
            borderRadius: "20px",
            minHeight: "250px",
          }}
        >
          <Stack variant="vendor_content_container">
            <VStack variant="vendor_content_container_vstack">
              {logo ? (
                <img src={logo} alt={logo} className="vendor-card-img" />
              ) : (
                <Skeleton.Image className="Skeleton-list-img " />
              )}
            </VStack>
            <Box width={"30%"}>
              <Text fontSize={"25px"} fontWeight="bold">
                {name}
              </Text>
              <Box>
                <HStack mt={"10px"}>
                  {agency_name && (
                    <>
                      <Text>
                        <b>{t("Agency")}</b>
                        {" : "}
                      </Text>
                      <Text>{agency_name}</Text>
                    </>
                  )}
                </HStack>
                {name_of_commodities && (
                  <VStack mt={"10px"}>
                    <Text bold>{t("Commodities ")}</Text>
                    <Text>{name_of_commodities}</Text>
                  </VStack>
                )}
              </Box>
            </Box>
            <Box variant={"vendor_address_box"}>
              <Text>
                <IoLocationSharp color="red" /> <b>Address : </b>
              </Text>
              <div class="address-tag">
                {vendor_address?.[0]?.address && (
                  <p>{vendor_address?.[0]?.address}</p>
                )}
                {/* <HStack>
                  {vendor_address?.[0]?.door_no && (
                    <p>{vendor_address?.[0]?.door_no}</p>
                  )}
                  {vendor_address?.[0]?.address_line1 && (
                    <p>{vendor_address?.[0]?.address_line1},</p>
                  )}
                </HStack>
                <HStack>
                  {vendor_address?.[0]?.city && (
                    <p>{vendor_address?.[0]?.city},</p>
                  )}
                  {vendor_address?.[0]?.zipcode && (
                    <p>{vendor_address?.[0]?.zipcode},</p>
                  )}
                </HStack>
                <HStack>
                  {vendor_address?.[0]?.district?.name && (
                    <p>{vendor_address?.[0]?.district?.name},</p>
                  )}
                  {vendor_address?.[0]?.state?.name && (
                    <p>{vendor_address?.[0]?.state?.name},</p>
                  )}
                </HStack>
                {vendor_address?.[0]?.country?.name && (
                  <p>{vendor_address?.[0]?.country?.name}.</p>
                )} */}
              </div>
              <Text></Text>
              <Text>
                <FiPhoneCall color="green" /> <b>{t("call_us")}</b>
              </Text>
              <div className="address-tag">
                <HStack>
                  {contact_person_name && (
                    <p>
                      {" "}
                      <b>{contact_person_name}</b> :{" "}
                    </p>
                  )}
                  {owner?.mobile && (
                    <a href={`tel:${owner?.mobile}`} className="a-link-tag">
                      {owner?.mobile}
                    </a>
                  )}
                </HStack>
              </div>
              {ceo_name || ceo_contact_number ? (
                <div style={{ marginLeft: "10%" }}>
                  <Text bold>{"CEO :-"}</Text>
                </div>
              ) : (
                ""
              )}
              <div className="address-tag">
                <HStack>
                  {ceo_name && (
                    <p>
                      {" "}
                      <b>{ceo_name}</b> :{" "}
                    </p>
                  )}
                  {ceo_contact_number && (
                    <a
                      href={`tel:${ceo_contact_number}`}
                      className="a-link-tag"
                    >
                      {ceo_contact_number}
                    </a>
                  )}
                </HStack>
              </div>
            </Box>
            <Box width={"20%"} justifyContent="end">
              <Text variant={"vendor_site_text"}>
                {/* {t("WebSite")} */}
                {/* {":"} */}
                <a href={website} target="_blank" className="a-link-tag">
                  {website}
                </a>
              </Text>
              <Text bold>
                <b>{t("follow_us")}</b>
              </Text>
              <HStack space={3}>
                <a href={facebook_url} target="_blank">
                  <BsFacebook size={27} className="Facebook" />
                </a>
                <a href={instagram_url} target="_blank">
                  <img
                    src={Instagram}
                    alt="Instagram"
                    class="vendor-social-media-icon"
                  />
                </a>
                <a href={twitter_url} target="_blank">
                  <AiFillTwitterCircle size={30} className="Twitter" />
                </a>
              </HStack>
            </Box>
          </Stack>
        </Card>
      </HStack>
      <Box marginTop={"30px"}>
        <VendorProductDetails product_list={vendor_products} />
      </Box>
    </>
  );
};

export default VendorProductList;
