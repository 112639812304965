import React, { useState } from "react";
import { Rate } from "antd";

const Rating = (props) => {

  return (
    <span>
      <Rate style={{fontSize: 20}} allowHalf disabled defaultValue={props.count} />
    </span>
  );
};

export default Rating;
