import React, { useEffect, useState, ReactSelect } from "react";
import { Button, Card, Col, Image, Row, Space, Spin, Typography } from "antd";
import {
  AspectRatio,
  Box,
  HStack,
  Pressable,
  Text,
  Tooltip,
  VStack,
} from "native-base";
import {
  get_all_products_query,
  dynamicRequest,
  dispatch,
  useDynamicSelector,
  get_all_vendor_product_variants,
  dynamicClear,
  create_customer_cart_mutation,
  get_all_vendor_list,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { BsCart3 } from "react-icons/bs";
import "../../../index.css";
import { AiOutlineHeart } from "react-icons/ai";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import Rating from "../products_list/rating";
import { showNotification } from "@helpers/notify";
import { Autoplay } from "swiper";
import { useTranslation } from "react-i18next";
import { AmountFormat } from "@helpers/constants";

const ProductsCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { loading: create_loading, status: cart_status } = useDynamicSelector(
    "create_customer_cart"
  );

  const { category_id } = useParams();

  const { state } = useLocation();
  const category_name = state?.name;
  const [active_id, setActiveId] = useState("");
  useEffect(() => {
    if (cart_status == "success" && active_id === props?.id) {
      setActiveId("");
      showNotification({
        type: "success",
        message: `Product added to cart successfully`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    } else if (cart_status === "failure") {
      showNotification({
        type: "failure",
        message: `Failed to add an item to cart`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    }
  }, [cart_status]);

  const handleClick = (product_id, category_name) => {
    history.push({
      pathname: `${ROUTES.PRODUCT_DETAILS}/${product_id}`,
      state: { name: category_name },
    });
  };

  const handleVendorPage = (id) => {
    history.push({ pathname: `${ROUTES.VENDOR_PRODUCT_LIST}/${id}` });
  };

  const handleAddCart = (id) => {
    let keys = [{ key: "create_customer_cart", loading: true }];
    let variables = {
      data: { product_count: 1, vendor_product_variant_id: id },
    };
    dispatch(
      dynamicRequest(keys, create_customer_cart_mutation, variables, "M")
    );
  };
  console.log("wevprops", props);
  return (
    <Box flex={1}>
      {/* <Text bold variant="category_name">
        {category_name}
      </Text> */}
      <HStack marginLeft={10} space={5} flexWrap={"wrap"}>
        <VStack variant="product_card">
          <Pressable onPress={() => handleClick(props?.id, props?.category)}>
            <Card
              hoverable
              className="product-card"
              style={{
                height: "386px",
                width: 250,
                borderRadius: "10px",
                marginTop: "30",
                marginBottom: "50px",
                minHeight: "386px",
                maxHeight: "900px",
              }}
            >
              <Box variant="Vendor_product_variant_box">
                <Box variant="Vendor_product_variant_box">
                  <img
                    class="product-image"
                    alt="product_image"
                    cart_status
                    src={props?.icon}
                  />
                </Box>
              </Box>
              <Box>
                <VStack variant="product_card_details">
                  <Box variant="product_card_height">
                    <Text bold>
                      {props?.variant_name}
                      {props?.stock &&
                        props?.unit &&
                        `, ${props?.stock} ${props?.unit}`}{" "}
                    </Text>
                    <Text variant="product_name">
                      {props?.category} / {props?.product_name}
                    </Text>
                    {/* <Rating count={props?.rating} /> */}
                    <HStack space={2}>
                      <Text>{t("by ")}</Text>
                      <Typography.Link
                        onClick={() => handleVendorPage(props.vendor_id)}
                      >
                        <Text variant="vendor_name">{props?.vendor_name}</Text>
                      </Typography.Link>
                    </HStack>
                  </Box>
                  {/* <HStack space={2} variant="search_amount_hstack"> */}
                  <HStack alignItems={"center"} height={"10px"} space={2}>
                    {props?.discount != 0 ? (
                      <Text color={"red.400"} fontSize={"1.5rem"}>
                        {"-"}
                        {props?.discount}%
                      </Text>
                    ) : (
                      ""
                    )}
                    {props?.base_price && (
                      <Text variant="product_details" marginTop={"0px"}>
                        <AmountFormat amount={props.base_price} />
                      </Text>
                    )}
                  </HStack>
                  {props?.selling_price && (
                    <HStack>
                      <Text fontSize={"12px"}>M.R.P :</Text>
                      <Text strikeThrough fontSize={"12px"}>
                        ₹{" "}
                        {props?.selling_price?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    </HStack>
                  )}
                </VStack>
              </Box>
            </Card>
          </Pressable>
        </VStack>
      </HStack>
    </Box>
  );
};

export default ProductsCard;
