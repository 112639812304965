import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import React, { useState } from "react";
import Select from "react-select";
import {
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  ScrollView,
} from "native-base";
import { Scrollbars } from "react-custom-scrollbars-2";

import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { DatePicker, Button, Input } from "antd";
import toast from "react-hot-toast";
import {
  dynamicClear,
  dynamicRequest,
  registerUser,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { storeItem } from "@helpers/storage";
import { useTranslation } from "react-i18next";
import { backgroundColor } from "styled-system";
import logo from "@assets/images/governmentlogo.png";

const RegisterForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { _mob, _email } = props;
  const [email, setEmail] = useState(_email);
  const [mobile, setMobile] = useState(_mob);
  const [mobile_otp, setMobileOTP] = useState("");
  const [user_name, setUserName] = useState("");
  const [name, setName] = useState();
  const [gender, setGender] = useState();
  const [dob, setDob] = useState();

  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState();

  const [showPass, setShowPass] = React.useState(false);

  const {
    token: register_token,
    error,
    loading: registerLoading,
    status: registerStatus,
    name: registeredUserName,
    user_id,
    profile_id,
    is_preference_added,
    is_profile_added,
    //gender: user_gender,
  } = useDynamicSelector("register_user");

  useEffect(() => {
    storeItem("token", register_token);
    storeItem("name", registeredUserName);
    storeItem("user_id", user_id);
    //storeItem("gender", user_gender);

    if (error) {
      toast.error(error.message);
      dispatch(dynamicClear("register_user"));
    }
    if (register_token) {
      navigate(ROUTES.USER_HOME);
      toast.success(`Welcome ${registeredUserName}`);
    }
  }, [register_token, error, registeredUserName, profile_id]);

  useEffect(() => {
    if (registerStatus === "success") {
      toast.success(t("registered_successfully"));
      dispatch(dynamicClear("register_user"));
    }
  }, [registerStatus]);

  const Register = () => {
    let values = {
      email: email,
      mobile_otp:mobile_otp,
      // email_otp: email_otp,
      mobile: mobile,
      user_name: user_name,
      name: name,
      password: password,
    };
    let user_register_key = [{ key: "register_user", loading: true }];
    let user_register_query = registerUser;
    let user_register_variables = { data: values };
    dispatch(
      dynamicRequest(
        user_register_key,
        user_register_query,
        user_register_variables,
        "M"
      )
    );
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      Register();
    }
  };
  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <Center>
        <Stack
          my={2}
          // alignItems={"center"}
          // justifyContent={"center"}
          flexDirection={{
            base: "column",
            md: "row",
          }}
          // p="50px"
          w={{
            md: "100%",
            sm: "50%",
          }}
          maxW={{
            md: "100%",
            sm: "50%",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <VStack
            flex="1"
            px="1"
            py="6"
            _light={{
              bg: "white",
            }}
            _dark={{
              bg: "coolGray.800",
            }}
            position={"relative"}
            space="1"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            width="250px"
            borderRadius={{
              base: "2xl",
              md: "xl",
            }}
            padding={10}
          >
            <Image
              cursor={"pointer"}
              height={20}
              alignContent={"center"}
              alt="logo"
              resizeMode={"contain"}
              source={logo}
            />
            <Text
              variant="LogoGap"
              color={"#712e5a"}
              bold
              fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
              textAlign={"center"}
              fontWeight="normal"
            >
              {t("user_signup")}
            </Text>

            <VStack space="7">
              <VStack space="3" p={10}>
                <Box>
                  <ScrollView>
                    <Box paddingTop={4} paddingBottom={2}>
                      <Text variant="input_header">{t("mobile")}</Text>
                    </Box>

                    <Input
                      disabled={true}
                      variant="rounded"
                      placeholder="Mobile"
                      // onChangeText={(text) => setEmail(text)}
                      value={mobile}
                    />
                    <Box paddingTop={4} paddingBottom={2}>
                      <Text variant="input_header">{t("otp")}</Text>
                    </Box>

                    <Input
                      variant="rounded"
                      placeholder="OTP"
                      onChange={(e) => setMobileOTP(e.target.value)}
                      value={mobile_otp}
                    />

                    <Box variant="input_header_box">
                      <Text variant="input_header">{t("user_name")}</Text>
                    </Box>

                    <Input
                      variant="rounded"
                      placeholder="User Name"
                      onChange={(e) => setUserName(e.target.value)}
                      value={user_name}
                    />
                    <Box variant="input_header_box">
                      <Text variant="input_header">{t("name")}</Text>
                    </Box>

                    <Input
                      variant="rounded"
                      placeholder="Name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />

                    <Box variant="input_header_box">
                      <Text variant="input_header">{t("password")}</Text>
                    </Box>

                    <Input.Password
                      variant="rounded"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />

                    <Box variant="input_header_box">
                      <Text variant="input_header">
                        {t("confirm_password")}
                      </Text>
                    </Box>

                    <Input.Password
                      onKeyPress={handleKeypress}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      //   variant="rounded"
                      placeholder="Confirm Password"
                      onChange={(e) => {
                        setConfirmpassword(e.target.value);
                      }}
                      value={confirmpassword}
                    />

                    <Box variant="terms_box">
                      <Box>
                        <Checkbox variant="terms_checkbox">
                          <Text variant="terms_text">
                            I agree to the <b>Term of Services</b>
                          </Text>
                        </Checkbox>
                      </Box>
                    </Box>
                  </ScrollView>
                </Box>
                <Box my={5} alignItems={"center"}>
                  <Button
                    style={{ width: "100px" }}
                    loading={registerLoading}
                    onClick={() => {
                      if (confirmpassword === password) {
                        Register();
                      } else if (confirmpassword !== password) {
                        toast.error("Password does not match");
                      }
                    }}
                    type={"primary"}
                  >
                    {t("register")}
                  </Button>
                </Box>
              </VStack>
            </VStack>
            <HStack
              space="1"
              safeAreaBottom
              alignItems="center"
              justifyContent="center"
            >
              <Text
                _light={{
                  color: "coolGray.800",
                }}
                _dark={{
                  color: "coolGray.400",
                }}
              >
                {t("had_account")}
              </Text>
              <Link
                _text={{
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                _light={{
                  _text: {
                    color: "#712e5a",
                  },
                }}
                _dark={{
                  _text: {
                    color: "#712e5a",
                  },
                }}
                onPress={() => {
                  navigate(ROUTES.LOGIN);
                }}
                /* onPress function naviagteTo:"SignUp" */
              >
                Login
              </Link>
            </HStack>
          </VStack>
        </Stack>
      </Center>
    </ScrollView>
  );
};

export default RegisterForm;
