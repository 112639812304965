import { Form, Input, Button } from "antd";
import { Box, HStack, Text } from "native-base";
import React from "react";

const OrderFeedback = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  return (
    <Box>
      <Form
        form={form}
        name={"Feedback"}
        layout={"vertical"}
        //   onFinish={handleSubmit}
      >
        <Form.Item label="" field={"feedback"}>
          <TextArea rows={4} placeholder="Write a feedback..." />
        </Form.Item>

        <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
          <Form.Item>
            <HStack space={5}>
              <Button htmlType="submit">Submit</Button>
              <Button>close</Button>
            </HStack>
          </Form.Item>
        </HStack>
      </Form>
    </Box>
  );
};
export default OrderFeedback;
