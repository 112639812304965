import {
  Box,
  HStack,
  VStack,
  Pressable,
  Text,
  Hidden,
  Stack,
} from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdContact } from "react-icons/io";
import Building from "@assets/icons/icons8-company-20.png";
import User from "@assets/icons/icons8-male-user-20.png";
import { Button, Dropdown } from "antd";
import Location from "@assets/icons/icons8-location-20.png";
import Setting from "@assets/icons/icons8-settings-20.png";
import Cart from "@assets/icons/icons8-shopping-bag-20.png";
import { GrSearch } from "react-icons/gr";
import { MdLogout } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import DropDownLists from "./menu_dropdown_lists";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { retrieveItem } from "@helpers/storage";
import CartIcon from "@assets/icons/shopping-cart.png";
import { BsFillHeartFill } from "react-icons/bs";
import { CgSearch } from "react-icons/cg";
const MenuBar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = retrieveItem("token");
  const onSearch = () => {
    let Input = document.getElementById("searchBox");
    let searchValue = Input.value.toUpperCase();
    history.push({ pathname: ROUTES.SEARCH, state: searchValue });
  };

  const clearLocalStorage = () => {
    try {
      window.localStorage.clear();
    } catch (e) {
      return;
    }
  };
  const handleAddresses = () => {
    history?.push({
      pathname: ROUTES.MY_ADDRESS,
    });
  };
  const handleOrders = () => {
    history?.push({
      pathname: ROUTES.ORDERS,
    });
  };
  const handleAccount = () => {
    history?.push({
      pathname: ROUTES.ACCOUNT_DETAILS,
    });
  };
  const handleOrderTrack = () => {
    history?.push({
      pathname: ROUTES.TRACK_YOUR_ORDER,
    });
  };
  const handleSettings = () => {
    history?.push({
      pathname: ROUTES.TRACK_YOUR_ORDER,
    });
  };
  const handleLogout = () => {
    clearLocalStorage();
    history?.push({
      pathname: ROUTES.LOGIN,
    });
  };
  const handleAbout = () => {
    history.push(ROUTES.ABOUT_US);
  };
  const handleContact = () => {
    history.push(ROUTES.CONTACT_US);
  };
  const handleVendor = () => {
    history.push(ROUTES.VENDOR_LIST);
  };
  const handleWishlist = () => {
    history?.push({
      pathname: ROUTES.WISHLIST,
    });
  };
  const handleCart = () => {
    history?.push({
      pathname: ROUTES.CART,
    });
  };
  const users = [
    {
      icon: (
        <img
          src="https://v-agriosp-assets.blr1.vultrobjects.com/img.png"
          alt="User"
        />
      ),
      label: <a onClick={handleAccount}>My Account</a>,
      key: "my_account",
    },
    {
      icon: <img src={Cart} alt="Cart" />,
      label: <a onClick={handleOrders}>Orders</a>,
      key: "orders",
    },
    // {
    //   icon: <img src="https://nt-agriosp-web-dev.s3.ap-south-1.amazonaws.com/icons8-location-20.png" alt="Location" />,
    //   label: <a onClick={handleOrderTrack}>Track Orders</a>,
    //   key: "track_orders",
    // },
    {
      icon: <img src={Building} alt="Building" />,
      label: <a onClick={handleAddresses}>My Address</a>,
      key: "my_address",
    },
    // {
    //   icon: <img src="https://nt-agriosp-web-dev.s3.ap-south-1.amazonaws.com/icons8-settings-20.png" alt="Setting" />,
    //   label: <a onClick={handleSettings}>Settings</a>,
    //   key: "settings",
    // },
    {
      icon: <MdLogout size={20} />,
      label: <a onClick={handleLogout}>Logout</a>,
      key: "login",
    },
  ];
  const guest = [
    {
      icon: <MdLogout size={20} />,
      label: <a onClick={handleLogout}>Login</a>,
      key: "login",
    },
  ];
  return (
    <div className="menu-bar-container">
      <div class="menu-bar">
        <DropDownLists />
      </div>
      <div class="search-box">
        <input
          type="search"
          placeholder="Search for products & categories..."
          class="menu-bar-input"
          id="searchBox"
          onKeyUp={onSearch}
        />
        <Button
          className="search-box-button"
          style={{
            border: "none",
            borderRadius: "20px",
            height: "36px",
            background: "rgb(93, 197, 106)",
            padding: "9px",
            // color: "white",
          }}
          onClick={onSearch}
        >
          <CgSearch size={20} color="white" />
        </Button>
      </div>
      <Pressable mx={2} onPress={handleAbout}>
        <Text variant={"header_titles_one_text"}>{t("about_us")}</Text>
      </Pressable>
      |
      <Pressable mx={2} onPress={handleContact}>
        <Text variant={"header_titles_one_text"}>{t("contact_us")}</Text>
      </Pressable>
      {/* |
      <Pressable mx={2} onPress={handleVendor}>
        <Text variant={"header_titles_one_text"}>{t("fpo")}</Text>
      </Pressable> */}
      {/* <div className="menu-bar-icon-box">
        <HStack variant={"menu_bar_icon_box"}>
          <Pressable onPress={handleCart}>
            <img src={CartIcon} alt="CartIcon" class="image-box" />
          </Pressable>
          <Dropdown
            menu={{ items: user ? users : guest }}
            placement={"bottom"}
            className="user-account-dropdown"
          >
            <IoMdContact size={38} color="#7e7878d1" />
          </Dropdown>
        </HStack>
      </div> */}
    </div>
  );
};

export default MenuBar;
