import { Button, Popconfirm, Space, Table, Tag, Tooltip, Rate } from "antd";
import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import WishlistHeader from "./wishlist_header";
import {
  get_all_customer_wishlist,
  delete_customer_wishlist_item,
  create_customer_cart,
  dynamicRequest,
  dynamicClear,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showNotification } from "@helpers/notify";

const api_data = [
  {
    key: "1",
    ImageURL:
      "https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/product-1-1.jpg",
    products: { name: "Field Roast Chao Cheese Creamy Original", star: 4 },
    Price: 32,
    address: "New York No. 1 Lake Park",
    tags: ["In stock"],
  },
  {
    key: "2",
    ImageURL:
      "https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/product-2-1.jpg",
    products: { name: "Blue Diamond Alonds Lightly Salted", star: 3.5 },
    Price: 42,
    address: "London No. 1 Lake Park",
    tags: ["In stock"],
  },
  {
    key: "3",
    ImageURL:
      "https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/product-4-1.jpg",
    products: { name: "Angie's Boomchickapop Sweet& Salty", star: 4 },
    Price: 32,
    address: "Sydney No. 1 Lake Park",
    tags: ["Out of stock"],
  },
];

const WishListProducts = () => {
  let temp = [];
  const [deleting_id, setDeleteId] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [get_wishlist_load, setGetWishlistLoading] = useState(true);
  const [data, setData] = useState(api_data);

  const {
    items: customer_wishlist,
    pagination: wishlist_pagination,
    error: fetch_error,
  } = useDynamicSelector("get_all_customer_wishlist");

  const {
    id: deleted_id,
    status: deletion_status,
    error: deletion_error,
  } = useDynamicSelector("delete_customer_wishlist_item");

  const {
    id: add_cart_id,
    status: add_cart_status,
    error: add_cart_error,
  } = useDynamicSelector("create_customer_cart");

  useEffect(() => {
    let keys = [{ key: get_all_customer_wishlist, loading: true }];
    dispatch(dynamicRequest(keys, get_all_customer_wishlist, {}));
  }, []);

  if (customer_wishlist) {
    temp = customer_wishlist.map((row, index) => ({
      key: index,
      ImageURL:
        "https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/product-1-1.jpg",
      products: {
        name: row.vendor_product_variant_customer_wishlist.name,
        star: 4,
        vendor_id:
          row.vendor_product_variant_customer_wishlist.vendor_product_id,
        vendor_product_variant_id: row.vendor_product_variant_id,
      },
      Price:
        Math.round(
          (row.vendor_product_variant_customer_wishlist.selling_price +
            Number.EPSILON) *
            100
        ) / 100,
      id: row.id,
      SubTotal:
        Math.round(
          (row.vendor_product_variant_customer_wishlist.selling_price +
            Number.EPSILON) *
            100
        ) / 100,
      Quantity: 1,
    }));
  }

  useEffect(() => {
    setData(temp);
    setTimeout(() => {
      setGetWishlistLoading(false);
    }, 3000);
  }, [temp < 1]);

  const handleDelete = (id) => {
    const newData = data.filter((item) => item.id !== id);
    setGetWishlistLoading(true);
    let keys = [{ key: "delete_customer_wishlist_item" }];
    let variables = {
      id: id,
    };
    dispatch(
      dynamicRequest(keys, delete_customer_wishlist_item, variables, "M")
    );
    setTimeout(() => {
      setData(newData);
    }, 2000);
  };

  useEffect(() => {
    if (deletion_status == "success") {
      setGetWishlistLoading(false);
    } else if (deletion_status == "failure") {
      setGetWishlistLoading(false);
    }
  }, [deletion_status]);

  const addToCart = (vendor_id, id) => {
    let keys = [{ key: "create_customer_cart" }];
    let variables = {
      data: {
        product_count: 1,
        vendor_product_variant_id: vendor_id,
      },
    };
    dispatch(dynamicRequest(keys, create_customer_cart, variables, "M"));
    setDeleteId(id);
    setGetWishlistLoading(true);
  };

  useEffect(() => {
    if (add_cart_status == "success") {
      showNotification({
        type: "success",
        message: `Added to Cart`,
      });
      handleDelete(deleting_id);
      dispatch(dynamicClear("create_customer_cart"));
    } else if (add_cart_status == "failure") {
      showNotification({
        type: "failure",
        message: `Failed to add to Cart`,
      });
    }
  }, [add_cart_status]);

  const columns = [
    {
      title: "Products",
      dataIndex: "ImageURL",
      width: "5%",
      render: (theImageURL) => (
        <Box
          borderWidth={1}
          borderRadius={8}
          borderColor={"gray.200"}
          margin={5}
        >
          <img alt={theImageURL} src={theImageURL} width="100px" />
        </Box>
      ),
    },
    {
      title: "",
      dataIndex: "products",
      key: "products",
      render: (product) => (
        <Box>
          <Text color="#3bb77e" fontSize={17} fontWeight={400}>
            {product.name}
          </Text>
          <HStack>
            <Rate disabled defaultValue={product.star} />
          </HStack>
        </Box>
      ),
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      render: (price) => (
        <Text
          fontSize={"32px"}
          fontFamily="Quicksand"
          fontWeight={700}
          color={"green.600"}
        >
          {" "}
          &#8377;{price}
        </Text>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type={"primary"}
          onClick={() => {
            addToCart(record.products.vendor_product_variant_id, record.id);
          }}
        >
          Add to Cart
        </Button>
      ),
    },
    {
      title: "Remove",
      key: "remove",
      render: (_, record) => (
        <Pressable>
          <Space size="middle">
            <Popconfirm
              title="Item will be removed from your wishlist"
              okButtonProps={{ color: "brown" }}
              onConfirm={() => handleDelete(record.id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        </Pressable>
      ),
    },
  ];
  return (
    <>
      <Box padding={100}>
        <WishlistHeader />
        <Table
          style={{ borderRadius: "20px" }}
          loading={get_wishlist_load}
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
        />
      </Box>
    </>
  );
};

export default WishListProducts;
