import { HStack, VStack, Box } from "native-base";
import Menu from "./components/menu";
import React from "react";

const ProfileLayout = (props) => {
  const { children } = props;
  return (
    <Box variant="order_page_total_box">
      <HStack variant="orderItems">
        <VStack variant="profile_title">
          <Menu title={props.title} />
        </VStack>
        {children}
      </HStack>
    </Box>
  );
};

export default ProfileLayout;
