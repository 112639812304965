import react, { useEffect, useState } from "react";
import { HStack, VStack, Box, Text, Pressable, Center } from "native-base";
import Menu from "../components/menu";
import { useTranslation } from "react-i18next";
import {
  Card,
  Image,
  Pagination,
  Popconfirm,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AntdModal from "@views/components/ui/antd_modal/antd_modal";
import MyAddressDetails from "./my_address_details";
import {
  delete_address_mutation,
  dynamicClear,
  dynamicRequest,
  get_all_address_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { showNotification } from "@helpers/notify";
import ProfileLayout from "..";

const MyAddressList = () => {
  const [current, setCurrent] = useState(1);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [page, setPage] = useState(10);

  const {
    items: all_address,
    pagination: address_pagination,
    loading: address_loading,
  } = useDynamicSelector("get_all_address");
  const { status: deleteStatus, error: deleteError } =
    useDynamicSelector("delete_address");
  const { status: update_status } = useDynamicSelector("update_customer");
  useEffect(() => {
    let keys = [{ key: "get_all_address", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_address_query, {
        page_number: current,
        page_limit: page,
      })
    );
  }, []);

  useEffect(() => {
    if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${t("address_delete_message")}`,
      });
      dispatch(dynamicClear("delete_address"));

      let keys = [{ key: "get_all_address", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_address_query, {
          page_number: current,
          page_limit: 10,
        })
      );
    } else if (deleteStatus === "failure") {
      showNotification({
        type: "failure",
        message: `${t("address_delete_failure_message")}`,
      });
      dispatch(dynamicClear("delete_address"));

      let keys = [{ key: "get_all_address", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_address_query, {
          page_number: current,
          page_limit: 10,
        })
      );
    } else if (update_status == "success" && actionItem?.id) {
      showNotification({
        type: "success",
        message: `${t("address_update_message")}`,
      });
      dispatch(dynamicClear("update_customer"));
      handleModalClose();
      let keys = [{ key: "get_all_address", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_address_query, {
          page_number: current,
          page_limit: 10,
        })
      );
    } else if (update_status == "failure" && actionItem?.id) {
      showNotification({
        type: "failure",
        message: `${t("address_update_failure_message")}`,
      });
      dispatch(dynamicClear("update_customer"));
    }
  }, [deleteStatus, update_status]);

  useEffect(() => {
    if (!modalOpen) {
      setActionItem({});
    }
  }, [modalOpen]);

  const handleAdd = () => {
    setActionType("add");
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDelete = (id) => {
    let keys = [{ key: "delete_address", loading: true }];
    let variables = { id: id };
    dispatch(dynamicRequest(keys, delete_address_mutation, variables));
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
    dispatch(dynamicClear("update_customer"));
  };

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_address", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        get_all_address_query,
        {
          page_number: page_number,
          page_limit: page_limit,
        },
        "M"
      )
    );
    setCurrent(page_number);
    setPage(page_limit);
  };
  return (
    <Box>
      {/* <ProfileLayout title="addresses"> */}
      <HStack>
        <VStack variant="Address_menu_vstack">
          <Menu title="addresses" />
        </VStack>
        <VStack variant="Address_top_vstack">
          <Text bold variant="Address_top_vstack_text">
            {t("my_address")}
          </Text>
          {address_loading ? (
            <Center variant="address_loading_center">
              <img
                id="loading-a"
                src="https://v-agriosp-assets.blr1.vultrobjects.com/d839dfd1-0975-492a-b775-1d139002af32_loading.png"
                alt="Loading..."
              />
            </Center>
          ) : (
            <Box>
              <HStack variant="add_address_hstack">
                <Card
                  className="add_address_card"
                  onClick={handleAdd}
                  hoverable
                >
                  <Box variant="add_address_card_box">
                    <VStack variant="add_address_card_box_vstack">
                      <Image
                        className="add_address_card_box_img"
                        preview={false}
                        src={
                          "https://v-agriosp-assets.blr1.vultrobjects.com/c4f32748-b2b2-460d-88db-742b8c300cdb.png"
                        }
                      />
                      <Text bold variant="add_address_card_box_text">
                        {t("add_address")}
                      </Text>
                    </VStack>
                  </Box>
                </Card>
                {all_address?.map((item, index) => (
                  <VStack>
                    <Card
                      title={item?.title}
                      hoverable
                      className="add_address_card"
                    >
                      <VStack>
                        <Box variant="address_content_Box">
                          <VStack>
                            <Text bold>{item?.contact_person} </Text>
                            <Text>
                              No: &nbsp;{item?.door_no},&nbsp;
                              {item?.address_line1}
                            </Text>
                            <Text>{item?.address_line2}</Text>
                            <Text>
                              {item?.city},&nbsp;{item?.district?.name}
                              {item?.other_district?.name},&nbsp;
                              {item?.zipcode} &nbsp;
                            </Text>
                            <Text>
                              {item?.state?.name} {item?.other_state?.name},
                              &nbsp; {t("india")}
                              &nbsp;
                            </Text>
                            <Text>{item?.mobile_no}</Text>
                          </VStack>
                        </Box>
                        <div>
                          <hr></hr>
                        </div>
                        <HStack variant="address_action_hstack">
                          <Typography.Link
                            className="card-typo"
                            onClick={() =>
                              handleEdit({
                                contact_person: item?.contact_person,
                                address_line1: item?.address_line1,
                                address_line2: item?.address_line2,
                                district_id: item?.district?.id,
                                state_id: item?.state?.id,
                                zipcode: item?.zipcode,
                                country_id: item?.country?.id,
                                mobile_no: item?.mobile_no,
                                city: item?.city,
                                title: item?.title,
                                door_no: item?.door_no,
                                id: item?.id,
                                other_state: item?.other_state,
                                other_district: item?.other_district,
                              })
                            }
                          >
                            <Tooltip
                              color="#5DC56A"
                              title={t("edit")}
                              placement="bottom"
                            >
                              <EditOutlined className="edit_address_icon" />
                            </Tooltip>
                          </Typography.Link>
                          <Popconfirm
                            title={t("delete_message")}
                            onConfirm={() => handleDelete(item?.id)}
                          >
                            <Tooltip
                              color="#f7584d"
                              title="Delete"
                              placement="bottom"
                            >
                              <DeleteOutlined className="delete_address_icon" />
                            </Tooltip>
                          </Popconfirm>
                        </HStack>
                      </VStack>
                    </Card>
                  </VStack>
                ))}
              </HStack>
              <Pagination
                showSizeChanger
                current={current}
                hideOnSinglePage={false}
                onChange={fetchData}
                pageSize={page}
                total={address_pagination?.total_count}
                pageSizeOptions={[1, 5, 10, 20]}
                className="pagination_address"
              />
            </Box>
          )}
          <AntdModal
            header={actionType === "add" ? "Add Address" : "Update Address"}
            isOpen={modalOpen}
            onClose={handleModalClose}
            component={
              <MyAddressDetails
                isOpen={modalOpen}
                label={actionType === "add" ? t("add") : t("update")}
                close={handleModalClose}
                initialValues={actionItem}
              />
            }
          />
        </VStack>
      </HStack>
      {/* </ProfileLayout> */}
    </Box>
  );
};

export default MyAddressList;
