import { Box, Text, VStack, HStack } from "native-base";
import { useHistory, useLocation } from "react-router-dom";
import VForm from "@views/components/ui/antd_form/antd_form";
import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showNotification } from "@helpers/notify";
import { ROUTES } from "@views/routes/my_routes";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { update_change_password_mutation } from "@services/redux/slices/dynamic_entity/graphql/graphql_orders";
const ChangePassword = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnteredPassword, setReEnteredPassword] = useState("");

  const {
    token: update_password_token,
    status: update_password_status,
    error: update_password_error,
    loading,
  } = useDynamicSelector("update_change_password");

  const clearLocalStorage = () => {
    try {
      window.localStorage.clear();
    } catch (e) {
      return;
    }
  };
  useEffect(() => {
    if (update_password_status === "success") {
      dispatch(dynamicClear("update_change_password"));
      clearLocalStorage();
      history?.push({
        pathname: ROUTES.LOGIN,
      });
      showNotification({
        type: "success",
        message: t("form:success_change_password"),
      });
    } else if (update_password_status === "failure") {
      showNotification({
        type: "failure",
        message:
          update_password_error?.message?.charAt(0).toUpperCase() +
          update_password_error?.message?.slice(1),
      });
    }
  }, [update_password_status]);

  const onValueChange = (values, AllValues) => {
    if (AllValues.new1_password) {
      setNew1Password(AllValues.new1_password);
    }
    if (AllValues.password) {
      setNewPassword(AllValues.password);
    }
  };
  const handleChangePassword = (values) => {
    if (values.new_password === values.new1_password) {
      delete values.new1_password;
      let update_change_password_keys = [
        { key: "update_change_password", loading: true },
      ];
      let update_change_password_variables = {
        current_password: values.old_password,
        new_password: values.new_password,
      };
      dispatch(
        dynamicRequest(
          update_change_password_keys,
          update_change_password_mutation,
          update_change_password_variables,
          "M"
        )
      );
    } else {
      showNotification({
        type: "failure",
        message: `New Password And Confirm Password Must Be Same`,
      });
    }
  };
  const handleBack = () => {
    dispatch(dynamicClear("update_change_password"));
    history.push(ROUTES.ACCOUNT_DETAILS);
  };

  return (
    <Box variant="change_password">
      <VStack space="10%">
        <Text mt={20} mb={10} variant="pageHeader">
          {t("change_password")}
        </Text>
        <Form
          form={form}
          onFinish={handleChangePassword}
          onValuesChange={onValueChange}
        >
          <VForm.Password
            label={t("form:old_password")}
            field={"old_password"}
            onChange={(event) => setOldPassword(event.target.value)}
            rules={[
              { required: true, message: `${t("form:enter_old_password")}` },
            ]}
          />

          <VForm.Password
            label={t("form:new_password")}
            onChange={(event) => setNewPassword(event.target.value)}
            rules={[
              { required: true, message: `${t("form:enter_new_password")}` },

              {
                pattern: new RegExp(
                  "^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()-_+./]){1,}).{6,}$"
                ),
                message: `${t("form:strong_password")}`,
              },
            ]}
            field={"new_password"}
          />
          <VForm.Password
            label={t("form:confirm_password")}
            value={reEnteredPassword}
            onChange={(event) => setReEnteredPassword(event.target.value)}
            field={"new1_password"}
            rules={[
              {
                required: true,
                message: `${t("form:re_enter_new_password")}`,
              },
            ]}
          />
          <HStack space="3" justifyContent={"flex-end"}>
            <Button onClick={handleBack}>{t("Back")}</Button>
            <VForm.Button
              buttonText={t("Update")}
              isLoading={loading}
              disabled={false}
            ></VForm.Button>
          </HStack>
        </Form>
      </VStack>
    </Box>
  );
};

export default ChangePassword;
