import { Space, Table, Tooltip, Typography, Pagination, Rate } from "antd";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { VStack, Box, Text, Badge, HStack } from "native-base";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { order_status } from "@helpers/constants";
import AntdModal from "@views/components/ui/antd_modal/antd_modal";
import OrderFeedback from "./order_feedback";

const OrderDetails = (props) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [is_modal_open, setModalVisible] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { initialValues } = { ...props };
  const history = useHistory();

  useEffect(() => {
    if (!is_modal_open) {
      setActionItem({});
    }
  }, [is_modal_open]);

  const handleView = (item) => {
    setModalVisible(true);
    setActionItem(item);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleRoute = (items) => {
    history.push({
      pathname: `${ROUTES.PRODUCT_DETAILS}/${items?.vendor_product_variant?.id}`,
    });
  };
  const handleChange = (e) => {
    setStatus(e);
  };

  let columns = [
    {
      title: t("table:product_order_code"),
      dataIndex: ["order_code"],
      align: "left",
      key: "order_code",
    },
    {
      title: t("table:product_name"),
      dataIndex: ["name"],
      align: "left",
      key: "name",
      render: (text, record) => {
        return (
          <>
            <VStack width={"200px"}>
              <a onClick={() => handleRoute(record)}>
                {record?.vendor_product_variant?.name}
              </a>
              <Rate onChange={handleChange} allowHalf />
              <Text onPress={handleView}>Write a feedback</Text>
            </VStack>
          </>
        );
      },
    },
    {
      title: t("table:product"),
      dataIndex: ["name"],
      align: "center",
      key: "name",
      render: (text, record) => (
        <a onClick={() => handleRoute(record)}>
          {
            <img
              alt={record?.vendor_product_variant?.icon}
              src={record?.vendor_product_variant?.icon}
              width="100px"
              height="120px"
            />
          }
        </a>
      ),
    },
    {
      title: t("table:Price"),
      dataIndex: ["selling_price"],
      align: "right",
      key: "selling_price",
      render: (text, record) => {
        return Number(
          record?.vendor_product_variant?.selling_price
        ).toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        });
      },
    },
    {
      title: t("table:quantity"),
      dataIndex: ["quantity"],
      align: "right",
      key: "quantity",
      render: (text, record) => {
        return record?.quantity?.toLocaleString("en-IN");
      },
    },
    {
      title: t("table:sub_total"),
      dataIndex: ["total_amount"],
      align: "right",
      key: "total_amount",
      flex: 1,
      render: (text, record) => (
        <Box>
          <Text>
            {Number(record?.total_amount).toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })}
          </Text>
        </Box>
      ),
    },
    {
      title: t("table:status"),
      dataIndex: ["status"],
      align: "left",
      key: "name",
      render: (text, record) => {
        return (
          <Badge
            colorScheme={
              record?.status === "returned" ||
              record?.status === "vendorCancelled" ||
              record?.status === "customerCancelled" ||
              record?.status === "return_to_vendor"
                ? "danger"
                : record?.status === "delivered"
                ? "success"
                : record?.status === "partially_delivered" ||
                  record?.status === "in_transit"
                ? "info"
                : record?.status === "order_initiated" ||
                  record?.status === "ordered"
                ? "blue"
                : "primary"
            }
            borderRadius={"xl"}
            width="fit-content"
          >
            {order_status[record?.status]}
          </Badge>
        );
      },
    },
  ];

  return (
    <Box variant="order_modal">
      <VStack variant="order_list_top_vstack">
        <Box alignItems={"center"}>
          <Text variant="pageHeader">
            {t("table:order_number")} : {initialValues.order_code}
          </Text>
        </Box>
        <AntdModal
          header="Feedback"
          isOpen={is_modal_open}
          onClose={handleClose}
          width="50%"
          label="view"
          component={<OrderFeedback initialValues={actionItem} />}
        />
        <Wrapper>
          <Table
            size="middle"
            columns={columns}
            dataSource={initialValues.customer_order_line_items}
            pagination={false}
          />
        </Wrapper>
        <Box alignItems="end">
          <Text variant="pageHeader">
            {t("table:total")} :{" "}
            {Number(initialValues?.t_amount).toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })}
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};
export default OrderDetails;
