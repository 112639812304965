import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  Link,
  Divider,
  Hidden,
  Pressable,
} from "native-base";
import { Slider, Button, Space, Col, Row, Card, Spin } from "antd";
import {
  get_all_categories_query,
  dynamicRequest,
  dispatch,
  useDynamicSelector,
  get_category_query,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "node_modules/swiper/swiper.scss";
import "node_modules/swiper/modules/navigation/navigation.scss";
import "node_modules/swiper/modules/pagination/pagination.scss";
import "node_modules/swiper/modules/scrollbar/scrollbar.scss";
import "../../../index.css";
// import "node_modules/swiper/swiper.min.css";
// import "swiper/.css";
import SwiperCore, {
  Autoplay,
  Keyboard,
  Pagination,
  Scrollbar,
  Zoom,
  A11y,
  Navigation,
} from "swiper";
import { FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import { RightCircleFilled, LeftCircleFilled } from "@ant-design/icons";
import "@src/style.css";
import SimpleGrid from "../ui/simple_grid/simple_grid";
import { SpaceContext } from "antd/lib/space";
import { CategoryCard } from "./category_card";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import { SubCategoryCard } from "./sub_category_card";
import { AiOutlineHome } from "react-icons/ai";
import { RxSlash } from "react-icons/rx";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export const SubCategoryBox = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { category_id } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const handleHome = () => {
    history.push({ pathname: ROUTES.USER_HOME });
  };

  const get_category = useDynamicSelector("get_category");
  const { loading } = useDynamicSelector("get_category");

  const getOneCategory = () => {
    let keys = [{ key: "get_category", loading: true }];
    let variable = {
      id: category_id,
    };
    dispatch(dynamicRequest(keys, get_category_query, variable));
  };
  useEffect(() => {
    getOneCategory();
  }, [category_id]);
  console.log("get_category", get_category);
  const CategoryHead = () => {
    return (
      <>
        {/* <Hidden from="xs" till="md"> */}
        <HStack
          space={3}
          alignItems="center"
          justifyContent={"flex-start"}
          marginLeft={["", , "50px"]}
        >
          <Pressable onPress={handleHome}>
            <Text variant={"tag_text_home"}>
              <AiOutlineHome />
              {t("Home")}
            </Text>
          </Pressable>
          <RxSlash size={10} />
          <Pressable disabled>
            <Text variant={"tag_text_hide"}>{get_category?.name}</Text>
          </Pressable>
        </HStack>
        <HStack
          justifyContent={"space-between"}
          marginBottom={"40px"}
          mt={"30px"}
        >
          <Box>
            <Text
              bold
              fontSize="30px"
              color="#3bb77e"
              marginLeft={["", , "50px"]}
            >
              {t("Sub categories")}
            </Text>
          </Box>
          {/* <HStack paddingRight={10} space={10}>
              <Button
                className="custom_prev_1"
                type="link"
                icon={
                  <LeftCircleFilled
                    className="LeftCircleFilled"
                    // style={{ }}
                  />
                }
                size={"large"}
              />

              <Button
                className="custom_next_1"
                type="link"
                icon={
                  <RightCircleFilled
                    className="LeftCircleFilled"
                    // style={{ fontSize: "30px", color: "#3bb77e" }}
                  />
                }
                size={"large"}
              />
            </HStack> */}
        </HStack>
        {/* </Hidden> */}
      </>
    );
  };
  const CategoryBody = () => {
    return (
      <Box alignItems={"center"} paddingX={10}>
        <Box mb={"20px"}></Box>
        {loading && (
          <Spin size="medium">
            <div className="content" />
          </Spin>
        )}
        {/* <Swiper
          modules={[Keyboard, Pagination, Scrollbar, Zoom]}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
            1440: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 4,
            },
            2560: {
              slidesPerView: 6,
            },
          }}
          // autoplay={true}
          // keyboard={true}
          // navigation={true}
          navigation={{
            prevEl: ".custom_prev_1",
            nextEl: ".custom_next_1",
          }}
          // loop={true}
          // spaceBetween={}
          slidesPerView={1}
          className="mySwiper"
        > */}
        <HStack variant="search_card_hstack">
          {get_category?.sub_categories?.map((item) => (
            // <SwiperSlide>
            <SubCategoryCard
              with={false}
              // height={fa}
              name={item.name}
              bg="#C5EAD9"
              id={item.id}
              icon={item.icon}
              has_sub={item?.sub_categories?.length > 0 ? true : false}
              category={get_category}
            />
            // </SwiperSlide>
          ))}
        </HStack>
        {/* </Swiper> */}
      </Box>
    );
  };
  return (
    <>
      <VStack>
        <CategoryHead />
        <CategoryBody />
      </VStack>
    </>
  );
};
