import { Button, Table, Rate, InputNumber, Space, Popconfirm } from "antd";
import { Box, HStack, VStack, Text, Pressable, Center } from "native-base";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  PlayCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import {
  get_all_customer_carts,
  delete_customer_cart,
  create_customer_order,
  dynamicRequest,
  dynamicClear,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showNotification } from "@helpers/notify";
import { retrieveItem } from "@helpers/storage";
// import CartBodyCheckout from "./cart_body_checkout";

const CartBody = () => {
  let temp = [];
  let order_array = [];
  let order_list = [];

  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //useState()
  const [data, setData] = useState([]);
  const [get_cart_load, setGetCartLoading] = useState(true);
  const [total_amount, setTotalAmount] = useState(0);
  const [checkout_loading, SetCheckoutLoading] = useState();
  const [active_id, setActiveId] = useState("");
  const [dataSource, setDataSource] = useState(data);

  //useDynamicSelector()
  const {
    items,
    status: get_all_status,
    error: get_all_error,
  } = useDynamicSelector("get_all_customer_carts");
  const { status: deleteStatus, error: deleteError } = useDynamicSelector(
    "delete_customer_cart"
  );
  const {
    id,
    status: created_status,
    error,
  } = useDynamicSelector("create_customer_order");

  useEffect(() => {
    let keys = [{ key: "get_all_customer_carts" }];
    dispatch(dynamicRequest(keys, get_all_customer_carts, {}));
  }, [get_all_customer_carts]);

  useEffect(() => {
    setData(temp);
    setTimeout(() => {
      setGetCartLoading(false);
    }, 3000);
  }, [temp < 1, items]);

  //total amount in checkout
  useEffect(() => {
    let temp_total = 0;
    for (let i = 0; i < data.length; i++) {
      temp_total += data[i].SubTotal;
    }
    setTotalAmount(temp_total);
  }, [temp]);

  useEffect(() => {
    if (created_status == "success") {
      SetCheckoutLoading(false);
      showNotification({
        type: "success",
        message: `Order placed successfully`,
      });
      dispatch(dynamicClear("create_customer_order"));
      setTimeout(() => {
        handleOrders();
      }, 1000);
    } else if (created_status == "failure") {
      showNotification({
        type: "failure",
        message: `Order not placed`,
      });
    } else if (get_all_error == "Not authorized") {
      showNotification({
        type: "error",
        message: get_all_error,
      });
      dispatch(dynamicClear("get_all_customer_carts"));
      history.push(ROUTES.LOGIN);
    }
  }, [created_status, get_all_error]);

  //api data converted to Table data
  if (items) {
    temp = items.map((row, index) => ({
      key: index,
      ImageURL: row?.vendor_product_variant_customer_cart?.icon,
      products: {
        name: row?.vendor_product_variant_customer_cart?.name,
        star: row?.vendor_product_variant_customer_cart?.average_rating,
        vendor_id:
          row?.vendor_product_variant_customer_cart?.vendor_product?.vendor_id,
        vendor_product_variant_id: row?.vendor_product_variant_id,
      },
      Price:
        Math.round(
          (row?.vendor_product_variant_customer_cart?.selling_price +
            Number.EPSILON) *
            100
        ) / 100,
      id: row?.id,
      SubTotal: Math?.round((row.sub_total + Number.EPSILON) * 100) / 100,
      Quantity: row?.product_count,
      Stock: row?.vendor_product_variant_customer_cart?.stock_count,
    }));
  }

  const quantityXprice = (value, record) => {
    let newRecord = data.map((x) => x);
    newRecord[record.key].Quantity = value;
    newRecord[record.key].SubTotal =
      Math.round((newRecord[record.key].Price * value + Number.EPSILON) * 100) /
      100;
    setData(newRecord);
  };

  useEffect(() => {
    if (deleteStatus == "success") {
      showNotification({
        type: "success",
        message: `Cart item removed`,
      });
      dispatch(dynamicClear("delete_customer_cart"));
    }
  }, [deleteStatus]);

  //handles(redirects)
  const handleCategory = () => {
    history?.push({
      pathname: ROUTES.CATEGORY,
    });
  };

  const handleOrders = () => {
    history?.push({
      pathname: ROUTES.ORDERS,
    });
  };

  //delete button in table
  const handleDelete = (record) => {
    setGetCartLoading(true);
    let keys = [{ key: "delete_customer_cart" }];
    let variables = {
      id: record.id,
    };
    dispatch(dynamicRequest(keys, delete_customer_cart, variables, "M"));
    let key = record.key;
    const newData = data.filter((item) => item.key !== key);
    setTimeout(() => {
      setData(newData);
      setGetCartLoading(false);
    }, 2000);
  };

  const handleQuantityChange = (value, record) => {
    const updatedRecord = { ...record, Quantity: value };
    const updatedData = data.map((item) =>
      item.key === record.key ? updatedRecord : item
    );
    setData(updatedData);
    quantityXprice(value, updatedRecord);
  };

  const pushStatetoCheckout = () => {
    for (let i = 0; i < data.length; i++) {
      let order_list_temp = {
        ImageURL: data[i].ImageURL,
        SubTotal: data[i].SubTotal,
        Quantity: data[i].Quantity,
        Name: data[i].products.Name,
        Star: data[i].products.Star,
      };
      let order_object_temp = {
        vendor_id: data[i].products.vendor_id,
        vendor_product_variant_id: data[i].products.vendor_product_variant_id,
        quantity: data[i].Quantity,
        amount: data[i].Price,
      };
      order_list.push(order_list_temp);
      order_array.push(order_object_temp);
    }
    history.push({
      pathname: ROUTES.CHECKOUT,
      state: {
        data: data,
        order_array: order_array,
        order_list: order_list,
      },
    });
  };
  //checkout section
  const createOrder = () => {
    SetCheckoutLoading(true);
    for (let i = 0; i < data.length; i++) {
      let order_object_temp = {
        vendor_id: data[i].products.vendor_id,
        vendor_product_variant_id: data[i].products.vendor_product_variant_id,
        quantity: data[i].Quantity,
        amount: data[i].Price,
      };
      order_array.push(order_object_temp);
    }
    let keys = [{ key: "create_customer_order", loading: true }];
    let variables = {
      data: {
        address_id: "64dc0973-e2bf-47f8-82c4-72992d568d4e",
        customer_order_line_items: order_array,
      },
    };
    dispatch(dynamicRequest(keys, create_customer_order, variables, "M"));
  };

  const QuantityInput = ({ value, onChange, stock_count }) => {
    const handleMinusClick = () => {
      onChange(value - 1);
    };
    const handlePlusClick = () => {
      onChange(value + 1);
    };
    const handleValueChange = (newValue) => {
      onChange(newValue);
    };
    return (
      <InputNumber
        min={1}
        max={stock_count}
        value={value}
        controls={false}
        onChange={handleValueChange}
        formatter={(value) => `${value}`}
        parser={(value) => value.replace(/[^\d]/g, "")}
        addonBefore={
          <Button
            onClick={handleMinusClick}
            disabled={value == 1}
            style={{ border: "none" }}
          >
            -
          </Button>
        }
        addonAfter={
          <>
            {" "}
            <Button
              onClick={handlePlusClick}
              disabled={value >= stock_count}
              style={{ border: "none" }}
            >
              +
            </Button>
          </>
        }
      />
    );
  };

  const columns = [
    {
      title: "Products",
      dataIndex: ["ImageURL"],
      render: (_, record) => (
        <HStack>
          <Box variant="cart_page_image">
            <img
              alt={record.ImageURL}
              src={record.ImageURL}
              width="100px"
              height="120px"
            />
          </Box>
          <Center>
            <Box width={"160px"}>
              <Text
                style={{ color: "#3bb77e", fontSize: 17, fontWeight: 400 }}
                variant="product_name"
              >
                {record.products.name}
              </Text>
              <HStack>
                <Rate disabled defaultValue={record.products.star} />
              </HStack>
            </Box>
          </Center>
        </HStack>
      ),
    },

    {
      title: "Unit Price",
      dataIndex: "Price",
      key: "Price",
      width: "10vw",
      render: (price) => (
        <Text variant={"sub_total"}>
          &#8377;
          {price.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Text>
      ),
    },

    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "Quantity",
      width: "10vw",
      render: (_, record) => (
        <>
          <Box variant="add_to_cart_input">
            <QuantityInput
              value={record.Quantity}
              stock_count={record.Stock}
              onChange={(value) => {
                handleQuantityChange(value, record);
              }}
            />
          </Box>
        </>
      ),
    },
    {
      title: "Sub Total",
      key: "SubTotal",
      dataIndex: "SubTotal",
      width: "10vw",
      render: (sub_total) => (
        <>
          <Text variant="sub_total">
            &#8377;
            {sub_total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </>
      ),
    },
    {
      title: "Remove",
      key: "remove",
      width: "10vw",
      render: (_, record) => (
        <Pressable>
          <Space size="middle">
            <Popconfirm
              title="Item will be removed from your cart"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        </Pressable>
      ),
    },
  ];

  return (
    <>
      <HStack variant="cart_main_box">
        <VStack>
          <Box width={"70vw"}>
            <Table
              style={{ borderRadius: "20px" }}
              loading={get_cart_load}
              columns={columns}
              dataSource={data}
            />
            <HStack variant="continue_shopping">
              <Button
                style={{
                  marginTop: "10px",
                  background: "green",
                  fontSize: "20px",
                  height: "50px",
                  color: "white",
                  borderRadius: "5px",
                }}
                icon={<PlayCircleOutlined />}
                onClick={handleCategory}
              >
                Continue Shopping
              </Button>
            </HStack>
          </Box>
        </VStack>
        <Box width={"100vw"}>
          <Box
            marginLeft={"20px"}
            padding={5}
            width={"20%"}
            borderWidth={1}
            borderColor={"gray.200"}
            borderRadius={"5px"}
          >
            <Box>
              <VStack>
                <Box borderWidth={1} borderColor={"gray.200"}>
                  <HStack variant="shipping_details">
                    <Text variant="checkout_text">Shipping</Text>
                    <Text variant="checkout_text">Free</Text>
                  </HStack>
                </Box>

                <Box borderWidth={1} borderColor={"gray.200"}>
                  <HStack variant="shipping_details">
                    <Text variant="checkout_text">Total</Text>
                    <Text variant="cart_total_amount">
                      &#8377;
                      {total_amount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                  </HStack>
                </Box>
              </VStack>
            </Box>
            <Center>
              <Button
                style={{
                  marginTop: "10px",
                  background: "gold",
                  fontSize: "18px",
                  height: "50px",
                  color: "black",
                  fontFamily: "QucikSands",
                  borderRadius: "5px",
                }}
                loading={checkout_loading}
                icon={<LogoutOutlined />}
                onClick={pushStatetoCheckout}
              >
                Proceed To Checkout
              </Button>
            </Center>
          </Box>
        </Box>
      </HStack>
    </>
  );
};

export default CartBody;
