import { gql } from "@apollo/client";

export const get_product_rating_query = gql`
  query getProductRating($id: String!) {
    get_product_rating: getProductRating(id: $id) {
        id
        rating
        error{
            status_code
            message
        }
        review
        vendor_product_variant_id
        vendor_product_variant{
            id
            name
            description
        }
    }
}`;