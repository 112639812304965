import {
  Card,
  Dropdown,
  message,
  Button,
  Space,
  Table,
  Checkbox,
  Radio,
  Typography,
  Tooltip,
  Spin,
  Collapse,
  List,
} from "antd";
import { Box, HStack, Text, VStack, Center, Hidden } from "native-base";
import React, { useEffect, useState } from "react";
import {
  IdcardFilled,
  PlusSquareFilled,
  HeartTwoTone,
  FileAddTwoTone,
} from "@ant-design/icons";
import {
  get_all_address_query,
  dynamicRequest,
  dynamicClear,
  useDynamicSelector,
  create_customer_order,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MyAddressDetails from "../profile/Addresses/my_address_details";
import AntdModal from "@views/components/ui/antd_modal/antd_modal";
import { showNotification } from "@helpers/notify";
import {
  borderWidth,
  fontStyle,
  fontWeight,
  justifyContent,
  style,
} from "styled-system";
import { IoAddCircleOutline } from "react-icons/io5";

const CheckoutList = () => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectId, setSelectId] = useState([]);
  const [isSticky, setIsSticky] = useState(false);

  const { Panel } = Collapse;
  const { state } = useLocation();
  const { data, order_array, order_list } = state;
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  let address_list = [];

  const { items: addresses, loading: address_loading } =
    useDynamicSelector("get_all_address");

  const {
    id,
    status: created_status,
    error,
  } = useDynamicSelector("create_customer_order");

  useEffect(() => {
    let keys = [{ key: "get_all_address", loading: true }];
    dispatch(dynamicRequest(keys, get_all_address_query, {}));
  }, []);

  useEffect(() => {
    if (created_status == "success") {
      showNotification({
        type: "success",
        message: `Order placed successfully`,
      });
      history.push(ROUTES.USER_HOME);
    } else if (created_status == "failure") {
      showNotification({
        type: "failure",
        message: `Order not placed`,
      });
    }
  }, [created_status]);

  if (addresses) {
    for (var i = 0; i < Object.keys(addresses).length; i++) {
      var address = "";
      if (addresses[i]["title"]) {
        address += addresses[i]["title"];
      }
      if (addresses[i]["contact_person"]) {
        address += ", " + addresses[i]["contact_person"];
      }
      if (addresses[i]["door_no"]) {
        address += ", NO: " + addresses[i]["door_no"];
      }
      if (addresses[i]["address_line1"]) {
        address += ", " + addresses[i]["address_line1"];
      }
      if (addresses[i]["address_line2"]) {
        address += ", " + addresses[i]["address_line2"];
      }
      if (addresses[i]["city"]) {
        address += ", " + addresses[i]["city"];
      }
      if (addresses[i]["district.name"]) {
        address += ", " + addresses[i]["district.name"];
      }
      if (addresses[i]["state.name"]) {
        address += ", " + addresses[i]["state.name"];
      }
      if (addresses[i]["other_district"]) {
        address += ", " + addresses[i]["other_district"];
      }
      if (addresses[i]["other_state"]) {
        address += ", " + addresses[i]["other_state"];
      }
      if (addresses[i]["zipcode"]) {
        address += ", " + addresses[i]["zipcode"];
      }
      if (addresses[i]["mobile_no"]) {
        address += ". Phone number: " + addresses[i]["mobile_no"];
      }
      address += ", India";

      address_list.push({ value: addresses[i]["id"], label: address });
    }
  }

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleAddAddress = () => {
    setOpen(true);
  };

  const createOrder = () => {
    if (selectedValue) {
      for (let i = 0; i < data.length; i++) {
        let order_object_temp = {
          vendor_id: data[i].products.vendor_id,
          vendor_product_variant_id: data[i].products.vendor_product_variant_id,
          quantity: data[i].Quantity,
          amount: data[i].Price,
        };
        order_array.push(order_object_temp);
      }
      let keys = [{ key: "create_customer_order", loading: true }];

      let variables = {
        data: {
          address_id: selectId,
          customer_order_line_items: order_array,
          customer_invoice: {
            invoice_url: "link",
          },
        },
      };
      dispatch(dynamicRequest(keys, create_customer_order, variables, "M"));
    } else {
      showNotification({
        type: "failure",
        message: `Please select an address`,
      });
    }
  };

  const handleChange = (e, item) => {
    setSelectedValue(e.target.value);
    setSelectId(item.id);
  };

  const formatted_address_list = address_list.map((option) => ({
    ...option,
    label: option.label.replace(/\b\w+/, (match) => match.toUpperCase()),
  }));

  // const formatted_address_list = address_list.map(option => ({
  //   ...option,
  //   label: option.label.replace(/\b\w+/, match => `<span style="color: red;">${match}</span>`)
  // }));

  //   const formatted_address_div = document.getElementById("formatted-address");
  // formatted_address_div.innerHTML = formatted_address_list?.[0]?.label;

  const formatted_address_list_with_span = formatted_address_list.map(
    (address) => {
      const firstWord = address.label.split(" ")[0];
      const restOfAddress = address.label.substring(firstWord.length);

      const formattedAddress = `<span class="first-word">${firstWord}</span>${restOfAddress}`;
      return {
        ...address,
        label: formattedAddress,
      };
    }
  );

  return (
    <Box variant="checkout_top_box">
      <Text bold variant="checkout_text">
        {t("checkout")}
      </Text>
      <br />
      <Hidden from="xs" till="sm">
        <Collapse defaultActiveKey={["1"]} ghost className="checkout-panel">
          <Panel header={t("select_delivery_address")} key="1">
            <HStack justifyContent={"center"} alignItems={"center"}>
              <Card
                title={t("your_addresses")}
                extra={
                  <HStack variant="checkout_add_address">
                    <Typography.Link onClick={handleAddAddress}>
                      <Tooltip
                        color="#5DC56A"
                        title={t("add_address")}
                        placement="bottom"
                      >
                        {/* <FileAddTwoTone
                        className="checkout-add-address"
                        twoToneColor="#5DC56A"
                      /> */}
                        <IoAddCircleOutline size={"30px"} />
                      </Tooltip>
                    </Typography.Link>
                  </HStack>
                }
              >
                <HStack space={"6px"}>
                  {address_loading ? (
                    <Center>
                      <img
                        src="https://v-agriosp-assets.blr1.vultrobjects.com/d839dfd1-0975-492a-b775-1d139002af32_loading.png"
                        alt="Loading..."
                        id="loading-ac"
                      />
                    </Center>
                  ) : (
                    <>
                      <Center>
                        <List
                          size="large"
                          bordered
                          style={{
                            width: "500px",
                          }}
                          dataSource={addresses}
                          renderItem={(item, index) => (
                            <List.Item>
                              <Radio.Group
                                id="formatted-address"
                                className="my-radio-group"
                                onChange={(e) => {
                                  handleChange(e, item);
                                }}
                                value={selectedValue}
                              >
                                <Radio value={index}>
                                  <VStack>
                                    <Text>
                                      <Box
                                        backgroundColor={"gray.200"}
                                        alignItems={"center"}
                                        padding={"4px"}
                                      >
                                        {item?.title}
                                      </Box>
                                    </Text>
                                    <HStack space="6px">
                                      <Text style={{ fontWeight: "bold" }}>
                                        {item?.contact_person?.toUpperCase()}
                                      </Text>

                                      <Text style={{ fontWeight: "bold" }}>
                                        {item?.mobile_no}
                                      </Text>
                                    </HStack>
                                    <Text>
                                      {item?.door_no + ", "}
                                      {item?.address_line1 + ", "}
                                      {item?.address_line2 + ", "}
                                      {item?.city + ", "}
                                    </Text>
                                    <Text>
                                      {item?.district?.name + ", "}
                                      {item?.state?.name + " "}
                                      {item?.zipcode + "."}
                                    </Text>
                                  </VStack>
                                </Radio>
                              </Radio.Group>
                            </List.Item>
                          )}
                        />
                      </Center>
                      {/* {addresses?.map((x, index) => {
                  return (
                    <VStack
                      justifyContent={"center"}
                      style={{ borderBottomWidth: "0.2px" }}
                    >
                      <HStack space={"4px"}>
                        <Radio.Group
                          onChange={handleChange}
                          value={x}
                          size="middle"
                          buttonStyle="outline"
                        >
                          <Radio key={index} style={{ color: "green" }}></Radio>
                        </Radio.Group>
                        <Text style={{ fontWeight: "bold" }}>
                          {x.contact_person?.toUpperCase()}
                        </Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {x.mobile_no}
                        </Text>
                      </HStack>
                      <Text>
                        {x?.title + ", "}
                        {x?.door_no + ", "}
                        {x?.address_line1 + ", "}
                        {x?.address_line2 + ", "}
                        {x?.city + ", "}
                      </Text>
                      <Text>
                        {x?.district?.name + ", "}
                        {x?.state?.name + " "}
                        {x?.zipcode + "."}
                      </Text>
                    </VStack>
                  );
                })} */}
                    </>
                  )}
                  {/* <Space wrap> */}
                  <Button
                    onClick={createOrder}
                    type="default"
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      position: "sticky",
                      top: "200px",
                    }}
                  >
                    {t("place_order")}
                  </Button>
                  {/* </Space> */}
                </HStack>
              </Card>
            </HStack>
          </Panel>
        </Collapse>
      </Hidden>
      <Hidden from="md" till="3xl">
        <Collapse defaultActiveKey={["1"]} ghost className="checkout-panel">
          <Panel header={t("select_delivery_address")} key="1">
            <HStack justifyContent={"center"} alignItems={"center"}>
              <Card
                title={t("your_addresses")}
                // width={{ xs: "100%" }}
                extra={
                  <HStack variant="checkout_add_address">
                    <Typography.Link onClick={handleAddAddress}>
                      <Tooltip
                        color="#5DC56A"
                        title={t("add_address")}
                        placement="bottom"
                      >
                        {/* <FileAddTwoTone
                        className="checkout-add-address"
                        twoToneColor="#5DC56A"
                      /> */}
                        <IoAddCircleOutline size={"30px"} />
                      </Tooltip>
                    </Typography.Link>
                  </HStack>
                }
              >
                {address_loading ? (
                  <Center>
                    <img
                      src="https://v-agriosp-assets.blr1.vultrobjects.com/d839dfd1-0975-492a-b775-1d139002af32_loading.png"
                      alt="Loading..."
                      id="loading-ac"
                    />
                  </Center>
                ) : (
                  <>
                    <Center>
                      <List
                        size="large"
                        bordered
                        dataSource={addresses}
                        renderItem={(item, index) => (
                          <List.Item>
                            <Radio.Group
                              id="formatted-address"
                              className="my-radio-group"
                              onChange={(e) => {
                                handleChange(e, item);
                              }}
                              value={selectedValue}
                            >
                              <Radio value={index}>
                                <VStack>
                                  <Text fontSize={"xs"}>
                                    <Box
                                      backgroundColor={"gray.200"}
                                      alignItems={"center"}
                                      padding={"4px"}
                                    >
                                      {item?.title}
                                    </Box>
                                  </Text>
                                  <HStack space="6px">
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                      fontSize={"xs"}
                                    >
                                      {item?.contact_person?.toUpperCase()}
                                    </Text>

                                    <Text
                                      style={{ fontWeight: "bold" }}
                                      fontSize={"xs"}
                                    >
                                      {item?.mobile_no}
                                    </Text>
                                  </HStack>
                                  <Text fontSize={"xs"}>
                                    {item?.door_no + ", "}
                                    {item?.address_line1 + ", "}
                                    {item?.address_line2 + ", "}
                                    {item?.city + ", "}
                                  </Text>
                                  <Text fontSize={"xs"}>
                                    {item?.district?.name + ", "}
                                    {item?.state?.name + " "}
                                    {item?.zipcode + "."}
                                  </Text>
                                </VStack>
                              </Radio>
                            </Radio.Group>
                          </List.Item>
                        )}
                      />
                    </Center>
                    {/* {addresses?.map((x, index) => {
                  return (
                    <VStack
                      justifyContent={"center"}
                      style={{ borderBottomWidth: "0.2px" }}
                    >
                      <HStack space={"4px"}>
                        <Radio.Group
                          onChange={handleChange}
                          value={x}
                          size="middle"
                          buttonStyle="outline"
                        >
                          <Radio key={index} style={{ color: "green" }}></Radio>
                        </Radio.Group>
                        <Text style={{ fontWeight: "bold" }}>
                          {x.contact_person?.toUpperCase()}
                        </Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {x.mobile_no}
                        </Text>
                      </HStack>
                      <Text>
                        {x?.title + ", "}
                        {x?.door_no + ", "}
                        {x?.address_line1 + ", "}
                        {x?.address_line2 + ", "}
                        {x?.city + ", "}
                      </Text>
                      <Text>
                        {x?.district?.name + ", "}
                        {x?.state?.name + " "}
                        {x?.zipcode + "."}
                      </Text>
                    </VStack>
                  );
                })} */}
                  </>
                )}
                <Button
                  onClick={createOrder}
                  type="default"
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    position: "sticky",
                    top: "200px",
                  }}
                >
                  {t("place_order")}
                </Button>
              </Card>
            </HStack>
          </Panel>
        </Collapse>
      </Hidden>

      <br />

      <AntdModal
        header={"Add Address"}
        isOpen={open}
        onClose={handleModalClose}
        component={
          <MyAddressDetails
            isOpen={open}
            label={t("add")}
            close={handleModalClose}
          />
        }
      />
    </Box>
  );
};

export default CheckoutList;
