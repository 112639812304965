import { Button, Card, Empty, Tag } from "antd";
import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect } from "react";
import { Rate } from "antd";
import { BsCart3 } from "react-icons/bs";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  create_customer_cart_mutation,
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { showNotification } from "@helpers/notify";
import ProductsCard from "@views/components/home_page_product/product_card";

const VendorProductDetails = (props) => {
  const { product_list } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading: create_loading, status: cart_status } = useDynamicSelector(
    "create_customer_cart"
  );

  const handleClick = (product_id) => {
    let keys = [{ key: "create_customer_cart", loading: true }];
    let variables = {
      data: {
        customer_id: "e01b667c-02b0-4de8-806c-2b8028c915ec",
        product_count: 1,
        vendor_product_variant_id: product_id,
      },
    };
    dispatch(
      dynamicRequest(keys, create_customer_cart_mutation, variables, "M")
    );
  };

  const handleVendor = (id) => {
    history.push({ pathname: `${ROUTES.VENDOR_PRODUCT_LIST}/${id}` });
  };

  const handleDetails = (product_id, category_name) => {
    history.push({
      pathname: `${ROUTES.PRODUCT_DETAILS}/${product_id}`,
      state: { name: category_name },
    });
  };

  useEffect(() => {
    if (cart_status == "success") {
      showNotification({
        type: "success",
        message: `Product added to cart successfully`,
      });
      history.push(ROUTES.CART);
      dispatch(dynamicClear("create_customer_cart"));
    } else if (cart_status === "failure") {
      showNotification({
        type: "failure",
        message: `Failed to add an item to cart`,
      });
      dispatch(dynamicClear("create_customer_cart"));
    }
  }, [cart_status]);

  console.log("product_list", product_list);
  return product_list?.length > 0 ? (
    <HStack overflow={"hidden"}>
      <div className="vendorproduct-gird">
        {product_list?.map((products) => {
          return (
            <>
              {products.vendor_product_variants.map((variant) => {
                console.log("productsproductsproducts", products);
                return (
                  <>
                    <VStack space={20} flexWrap={"wrap"} overflow={"hidden"}>
                      <ProductsCard
                        id={variant?.id}
                        icon={variant?.icon}
                        stock_count={variant?.stock_count}
                        discount={variant?.discount}
                        category={products?.product?.category?.name}
                        product_name={products?.product?.name}
                        variant_name={variant?.name}
                        base_price={variant?.base_price}
                        selling_price={variant?.selling_price}
                        vendor_name={products?.vendor?.name}
                        vendor_id={products?.vendor?.id}
                        unit={variant?.unit}
                        stock={variant?.value}
                      />
                    </VStack>
                  </>
                );
              })}
            </>
          );
        })}
      </div>
    </HStack>
  ) : (
    <>
      <Center height={"300px"}>
        <Empty />
      </Center>
    </>
  );
};

export default VendorProductDetails;
