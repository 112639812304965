import { Box, HStack, Text } from "native-base";
import React from "react";

const WishlistHeader = () => {
  return (
    <Box marginBottom={10}>
      <Text fontSize={"40px"} fontWeight={"bold"}>
        Your Wishlist
      </Text>
      <HStack>
        <Text>There are</Text>
        <Text variant={"totalCountOfProducts"}> </Text>
        <Text>products in this list</Text>
      </HStack>
    </Box>
  );
};

export default WishlistHeader;
