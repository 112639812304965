import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useState } from "react";
//import image from "/Users/inukurthidileep/AgriOSP-User-Web-1/src/assets/icons/agri_image.jpeg";
import vertace_logo from "@assets/images/vertace_logo.png";

import {
  Button,
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";

import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import LoginForm from "./login_form";
import OtpForm from "./otp_form";
import RegisterForm from "./register_form";
import { useEffect } from "react";
import ForgotPassword from "./forgot_password_form";
import UserHeader from "@views/components/layout/user_header.jsx";
import ForgotPasswordForm from "./forgot_password_form";
import { useTranslation } from "react-i18next";
import HeaderContents from "../layout/header_contents";

const Login = (props) => {
  const { t } = useTranslation();
  const [_showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
  const [_showLoginForm, setShowLoginForm] = useState(true);

  const showForgotPasswordForm = (value1) => {
    setShowForgotPasswordForm(value1);
  };
  // const showRegisterForm = (value2) => {
  //   setShowRegisterForm(value2);
  // };
  const showLoginForm = (value3) => {
    setShowLoginForm(value3);
  };

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <Box
        _dark={{
          bgImage: `url(https://v-agriosp-assets.blr1.vultrobjects.com/fruits206d8de0-5fa8-4d55-be50-9aee696998fc.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // opacity: "0.1"
        }}
        _light={{
          bgImage: `url(https://v-agriosp-assets.blr1.vultrobjects.com/fruits206d8de0-5fa8-4d55-be50-9aee696998fc.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // opacity: "0.1"
        }}
        my="auto"
        // px={4}

        overflowY="hidden"
        // mt={"0"}
        height={"100%"}
      >
        <Box marginBottom="20px">
          <HeaderContents />
        </Box>
        <Center
          my="auto"
          _dark={{
            bg: "coolGray.900",
          }}
          _light={{
            opacity: "1",
          }}
          // marginTop="12%"
          // marginBottom="12%"
        >
          <Stack
            flexDirection={{
              base: "column",
              md: "row",
            }}
            w="95%"
            maxW={{
              md: "450ppx",
              // mt: "20"
            }}
            flex={{
              base: "1",
              md: "none",
            }}
          >
            <Box
              flex={1}
              px="6"
              py="2"
              // alignItems={"flex-start"}
              // flexDirection={"row"}
              // justifyContent={"center"}
              // display={"flex"}
              _light={{
                // bg: "box",
                opacity: "0.94",
              }}
              _dark={{
                bg: "coolGray.800",
                opacity: "1",
              }}
              space="3"
              justifyContent="center"
              borderTopRightRadius={{
                base: "xl",
                md: "xl",
              }}
              borderTopLeftRadius={{
                base: "xl",
                md: "xl",
              }}
              borderBottomRightRadius={{
                base: "xl",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "xl",
                md: "xl",
              }}
            >
              {_showLoginForm && !_showForgotPasswordForm && (
                <LoginForm
                  props={props}
                  showForgotPasswordForm={showForgotPasswordForm}
                  showLoginForm={showLoginForm}
                />
              )}
              {_showForgotPasswordForm && !_showLoginForm && (
                <ForgotPasswordForm
                  showForgotPasswordForm={showForgotPasswordForm}
                  showLoginForm={showLoginForm}
                />
              )}
            </Box>
          </Stack>
        </Center>
        <HStack
          h={8}
          py={2}
          justifyContent={"space-between"}
          opacity={0.8}
          // bgImage={"linear-gradient(40deg, #5dc56a 50%, #128807 80%)"}
          // bgImage={"linear-gradient(to left, #9dffb3 ,  #1aa37a )"}
          bgColor={"black"}
        >
          <HStack alignItems={"center"} pl={"20px"} pr={"20px"}>
            <Text color="#fff" fontSize={12}>
              Powered By{" "}
            </Text>
            <a href="https://vertace.com/" target="blank">
              <img
                height="20"
                alt="vertace"
                src={
                  "https://v-agriosp-assets.blr1.vultrobjects.com/ce5c5065-575d-4786-9cf8-f1c24bc2ad5c.png"
                }
              />
            </a>
          </HStack>
          <Box position={"absolute"} right={0}>
            <Text color={"#ffff"}>
              © GOVERNMENT OF TAMIL NADU AGRICULTURAL DEPARTMENT{" "}
            </Text>
          </Box>
        </HStack>
      </Box>
    </ScrollView>
  );
};

export default Login;
