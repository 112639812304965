import { gql } from "@apollo/client";


export const create_customer_wishlist_mutation = gql`
  mutation createCustomerWishlist(
    $data: customer_wishlist_input
  ) {
    create_customer_wishlist: createCustomerWishlist(data: $data) {
      id
      status
      error {
        status_code
        message
    }
}
}
`;