import React from "react";
import { Box, ScrollView } from "native-base";
import GlobalSearch from "@views/components/user_search/global_search";
const Search = () => {
  return (
    <>
      <GlobalSearch />
    </>
  );
};
export default Search;
