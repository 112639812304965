import { Box, Text } from "native-base";
import React from "react";

const ProductDescriptionDetails = ({ description }) => {
  let details = [
    "No cholesterol with PUFA and vitamin A,D,E",
    "Light, healthy and easy to digest",
    "Country of Origin: India",
  ];
  return (
    <div>
      <Text bold fontSize={"18px"}>
        About this item
      </Text>
      <Box mt={"10px"}>
        {description?.map((item) => {
          return (
            <ul>
              <li className="product_description_details">{item}</li>
            </ul>
          );
        })}
      </Box>
    </div>
  );
};

export default ProductDescriptionDetails;
