import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React from "react";
import { CategoryBox } from "@views/components/home_page_category/category_box";
import PosterBanner from "@views/components/Poster_banner/poster_banner";
import { SubCategoryBox } from "@views/components/home_page_category/sub_category_box";

const SubCategory = () => {
  return (
    <VStack variant={"user_home_vstack"} minH={"500px"}>
      <Box padding={"30px"}>
        <VStack variant="user_home_category_box_vstack">
          <SubCategoryBox />
        </VStack>
      </Box>
    </VStack>
  );
};
export default SubCategory;
