import React, { useEffect, useState } from "react";
import {
  dynamicRequest,
  get_all_vendor_list,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { Box, HStack, Text, VStack, Center } from "native-base";
import { Card, Button, Skeleton, Spin } from "antd";
import { IoLocationSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { FiPhoneCall } from "react-icons/fi";

const VendorCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: VendorLists, loading } = useDynamicSelector("get_all_vendors");
  const total_vendors = VendorLists?.length;

  const history = useHistory();
  const [current, setCurrent] = useState(1);
  const getAllVendorList = () => {
    let keys = [{ key: "get_all_vendors", loading: true }];
    let variables = {
      page_number: current,
      page_limit: 10000000000,
    };
    dispatch(dynamicRequest(keys, get_all_vendor_list, variables));
  };
  useEffect(() => {
    getAllVendorList();
  }, []);
  const handleProductList = (id) => {
    history.push(`${ROUTES.VENDOR_PRODUCT_LIST}/${id}`);
  };
  if (loading)
    return (
      <Center h="500px">
        <Box height="500px" size="45px">
          <Spin size="default"></Spin>
        </Box>
      </Center>
    );

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_vendors", loading: true }];
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, get_all_vendor_list, variable));
    setCurrent(page_number);
  };
  return (
    <VStack mt={"20px"}>
      <HStack variant={"vendor_code_tag_hstack"}>
        <Text variant={"vendor_code_tag_hstack_text"}>
          We have
          <span
            style={{
              color: "green",
              fontWeight: "bold",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            {total_vendors}
          </span>
          FPO's now
        </Text>
        <Box flex={1}></Box>
      </HStack>
      <div class="grid-container">
        {VendorLists?.map((item) => {
          return (
            <Card
              style={{
                width: "100%",
                borderRadius: "20px",
                marginRight: "15px",
              }}
              hoverable
              onClick={() => handleProductList(item.id)}
            >
              <HStack>
                <VStack variant={"vendor_card_container"}>
                  {item.logo ? (
                    <img
                      src={item.logo}
                      alt={item.name}
                      class="vendor-card-img"
                    />
                  ) : (
                    <Skeleton.Image className="Skeleton-img" />
                  )}
                </VStack>
                <Box variant={"vendor_card_address_box"}>
                  <Text fontSize={"25px"} bold>
                    {item.name}
                  </Text>
                  <Text>
                    <IoLocationSharp color="red" /> <b>Address : </b>
                  </Text>
                  <div class="address-tag">
                    {item.vendor_address?.[0]?.address && (
                      <p>{item.vendor_address?.[0]?.address}.</p>
                    )}
                    {/* <HStack variant={"address_tag_space"}>
                      {item.vendor_address?.[0]?.door_no && (
                        <p>{item.vendor_address?.[0]?.door_no},</p>
                      )}
                      {item.vendor_address?.[0]?.address_line1 && (
                        <p>{item.vendor_address?.[0]?.address_line1},</p>
                      )}
                    </HStack>
                    <HStack variant={"address_tag_space"}>
                      {item.vendor_address?.[0]?.city && (
                        <p>{item.vendor_address?.[0]?.city},</p>
                      )}
                      {item.vendor_address?.[0]?.zipcode && (
                        <p>{item.vendor_address?.[0]?.zipcode},</p>
                      )}
                    </HStack>
                    <HStack variant={"address_tag_space"}>
                      {item.vendor_address?.[0]?.district?.name && (
                        <p>{item.vendor_address?.[0]?.district?.name},</p>
                      )}
                      {item.vendor_address?.[0]?.state?.name && (
                        <p>{item.vendor_address?.[0]?.state?.name},</p>
                      )}
                    </HStack>
                    {item.vendor_address?.[0]?.country?.name && (
                      <p>{item.vendor_address?.[0]?.country?.name},</p>
                    )} */}
                  </div>
                  {/* </>
                    );
                  })} */}
                  <Text>
                    <FiPhoneCall color="green" /> <b>{t("call_us")}</b>
                  </Text>
                  <div className="address-tag">
                    <HStack space={2}>
                      {item.contact_person_name && (
                        <p>{item.contact_person_name}:</p>
                      )}
                      {item?.owner?.mobile && (
                        <p>
                          {" "}
                          <a
                            href={`tel:${item?.owner?.mobile}`}
                            className="a-link-tag"
                          >
                            {item?.owner?.mobile}
                          </a>
                        </p>
                      )}
                    </HStack>
                  </div>
                </Box>
              </HStack>
              <HStack justifyContent="space-between">
                <Text flex={1}></Text>
                <Button
                  width={"auto"}
                  style={{
                    color: "white",
                    borderRadius: "10px",
                    borderColor: "green",
                    backgroundColor: "green",
                  }}
                  className="vendor-card-btn"
                  onClick={() => {
                    handleProductList(item.id);
                  }}
                >
                  {t("visit_products")}
                </Button>
              </HStack>
            </Card>
          );
        })}
      </div>
    </VStack>
  );
};
export default VendorCard;
