import { Box, HStack, VStack } from "native-base";
import React from "react";
import { FilterBox } from "@views/components/home_page_category/filter_box";
import { CategoryBox } from "@views/components/home_page_category/category_box";
import PosterBanner from "@views/components/Poster_banner/poster_banner";
import { ProductBox } from "@views/components/home_page_product/product_box";

const UserHome = () => {
  return (
    <VStack variant={"user_home_vstack"} overflow={"hidden"}>
      <Box overflow={"hidden"}>
        <PosterBanner />
      </Box>
      <Box padding={{ base: "10px", lg: "30px" }} overflow={"hidden"}>
        <VStack variant="user_home_category_box_vstack">
          <CategoryBox />
          <ProductBox />
        </VStack>
      </Box>
    </VStack>
  );
};
export default UserHome;
