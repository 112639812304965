import { ROUTES } from "@views/routes/my_routes";
import { Box, HStack, Pressable, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineHome } from "react-icons/ai";
import { RxSlash } from "react-icons/rx";
import { useHistory } from "react-router-dom";
const ContactUs = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleHome = () => {
    history.push({ pathname: ROUTES.USER_HOME });
  };
  return (
    <HStack padding={"40px"}>
      <Box flex={1} padding={"40px"} style={{ gap: "10px" }}>
        <Box mb={"20px"}>
          <HStack space={3} alignItems="center">
            <Pressable onPress={handleHome}>
              <Text variant={"tag_text_home"}>
                <AiOutlineHome />
                {t("Home")}
              </Text>
            </Pressable>
            <RxSlash size={10} />
            <Pressable disabled>
              <Text variant={"tag_text_hide"}>Contact us</Text>
            </Pressable>
          </HStack>
        </Box>
        <HStack space={"90px"} width={"70%"}>
          <Text bold fontSize={"20px"}>
            {"Address"}
          </Text>
          <Text fontSize={"20px"}>
            {": "}116, Inner Ring Rd, Guindy Industrial Estate, SIDCO Industrial
            Estate, Guindy, Chennai, Tamilnadu 600032.
          </Text>
        </HStack>
        <HStack space={2}>
          <Text bold fontSize={"20px"}>
            Contact number
          </Text>
          <Text fontSize={"20px"}>{": "}(044) 22253884</Text>
        </HStack>
        <HStack space={"105px"}>
          <Text bold fontSize={"20px"}>
            Email
          </Text>
          <Text fontSize={"20px"}> {": "}agrimarkbusiness@gmail.com</Text>
        </HStack>
      </Box>
      <Box flex={1}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.349097333485!2d80.20373251480794!3d13.01342719082849!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52673f1743f7c1%3A0x87e47b962948870a!2sDepartment%20of%20Agricultural%20Marketing%20Board!5e0!3m2!1sen!2sus!4v1676825793433!5m2!1sen!2sus"
          height={"500px"}
        ></iframe>
      </Box>
    </HStack>
  );
};
export default ContactUs;
